import { environment } from './environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class SharedService {
    getGlobalVar():string{
      return environment.apiURL;
    }

    getWebSocket():string{
      return environment.websocketURL;
    }
  }

  export const API_URL =  environment.apiURL;
  export const VOICE_URL = environment.voiceURL;

