import { Component, OnInit , HostListener,ElementRef} from '@angular/core';
import { UsermanagementService } from "../../usermanagement.service";
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-credit-plan',
  templateUrl: './credit-plan.component.html',
  styleUrls: ['./credit-plan.component.css']
})
export class CreditPlanComponent implements OnInit {
  companyList: string | undefined;
  companies: any;
  DidList: any;
  didnumbers: any;
  planName: any;
  disableSelect = true;
  credit: any;
  ids: any;
  showDropdown: boolean = false;
  filteredCompanyList: string[] = [];


  constructor(
    private usermanagementservice: UsermanagementService,
    private route: ActivatedRoute,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.ids = params['id'];
      if (this.ids == 'new') {
        this.loadCompany()
      }
      else {
        this.loadDID()
      }
    });

  }
  loadCompany() {
    this.usermanagementservice.CustomerComapnyList().subscribe(
      (data: any) => {
        this.companies = data
      }
    )
  }
  loadDID() {
    var datas = {
      'didnumber': this.ids
    }
    this.usermanagementservice.didlist(datas).subscribe(
      (data: any) => {
        this.companyList = data['company']
        this.didnumbers = data['didNumber']
        this.planName = data['plan']
        if (this.planName !== '1') {
          this.disableSelect = false;
          this.credit = data['credit']
        }

      }
    )
  }
  getcompanycredit() {
    this.usermanagementservice.getExistingDidNumber(this.companyList).subscribe(
      (data: any) => {
        this.DidList = data.data

      }
    )
  }
  getplanName() {
    if (this.planName == 1) {
      this.disableSelect = true;
      this.credit=0;
    }
    else {
      this.disableSelect = false;
    }
  }

  onFocusedCompany() {

    this.showDropdown = true; // Show dropdown on input focus

    this.filteredCompanyList = this.companies; // Initially show all companies

  }

  filterCompanyList() {
    if (this.companyList.trim() === '') {
      this.filteredCompanyList = this.companies; // Reset to full list on empty input
    } else {
      this.filteredCompanyList = this.companies.filter(company =>
        company.toLowerCase().includes(this.companyList.toLowerCase())
      );
    }
  }

  selectCompany(company: string) {
    this.companyList = company; // Set the selected company
    this.showDropdown = false; // Hide the dropdown
    this.getCompanyCredit(); // Call the existing method
  }

  getCompanyCredit() {
    console.log('Selected company:', this.companyList);
    // Add your logic here to fetch or process company credit
  }


  clearDatas() {

    this.companyList = '';

    this.filteredCompanyList = [];

    this.showDropdown = false;

  }
  AddPlan() {
    if (this.companyList == undefined || this.didnumbers == undefined || this.planName == undefined) {
      Swal.fire('Oops..!', 'please fill details', 'warning')
    }
    else {
      var data = {
        'didnumber': this.didnumbers,
        'company': this.companyList,
        'plan': this.planName,
        'credit': this.credit
      }
      this.usermanagementservice.addPlanDid(data).subscribe(
        (data: any) => {
          if (data['status'] == 1) {
            Swal.fire('Created', 'Created Plan', 'success')
            this.didnumbers = '';
            this.companyList = '';
            this.planName = '';
            this.credit = '';
          }
          else {
            Swal.fire('Oops..!', data['message'], 'warning')
          }

        }
      )
    }

  }
  UpdatePlan(){
    if (this.companyList == undefined || this.didnumbers == undefined || this.planName == undefined) {
      Swal.fire('Oops..!', 'please fill details', 'warning')
    }
    else {
      var data = {
        'didnumber': this.didnumbers,
        'company': this.companyList,
        'plan': this.planName,
        'credit': this.credit
      }
      this.usermanagementservice.updatePlanDid(data).subscribe(
        (data: any) => {
          if (data['status'] == 1) {
            Swal.fire('Updated', 'Plan Updated', 'success')
          }
          else {
            Swal.fire('Oops..!', data['message'], 'warning')
          }

        }
      )
    }

  }
  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.autocomplete-container')) {
      this.showDropdown = false;
    }
  }

}
