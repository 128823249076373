import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoginService } from '../login.service';
import { BreakTimeReportService } from './break-time-report.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";

@Component({
  selector: 'app-break-time-report',
  templateUrl: './break-time-report.component.html',
  styleUrls: ['./break-time-report.component.css'],
  providers: [DatePipe]
})
export class BreakTimeReportComponent implements OnInit {

  reportForm: FormGroup;
  userData: any;
  userType: string;
  customerInitial: string;
  userProfileId: string | null= null;
  userId: string | null = null;
  agentId: string | null = null;
  agentList: any[] = [];
  reportData: any[] = [];
  detailData: any[] = [];
  showModal: boolean = false;
  dateTimeValid: boolean = true;
  pagination = { current_page: 1, total_page: 0 };

  defaultDateFrom: Date;
  defaultDateTo: Date;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private datePipe: DatePipe,
    private breakTimeReportService: BreakTimeReportService,
    private spinnerService: Ng4LoadingSpinnerService

  ) {
    this.reportForm = this.fb.group({
      startDate: [null],
      endDate: [null],
      agent: [""]
    })
   }

  ngOnInit() {
    const defaultDate = new Date();
    this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);

    this.userData = this.loginService.getUserdata(false);
    this.intializeUserSettings();
    this.getAgentList();
    
    this.reportForm.get('startDate').valueChanges.subscribe((value) => {
      this.onFromDateChange(value);
    });

    this.reportForm.get('endDate').valueChanges.subscribe((value) => {
      this.onToDateChange(value);
    });
    
  }


  onFromDateChange(value: any): void {
    if (value && value !== "") {
      const formattedDate = this.datePipe.transform(value, "yyyy-MM-dd HH:mm:ss");
      this.defaultDateFrom = new Date(formattedDate);
      console.log('From Date Changed:', formattedDate);
    } else {
      const defaultDate = new Date();
      this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
      console.log('From Date Reset to Start of Day:', this.defaultDateFrom);
    }
  }

  onToDateChange(value: any): void {
    if (value && value !== "") {
      const formattedDate = this.datePipe.transform(value, "yyyy-MM-dd HH:mm:ss");
      this.defaultDateTo = new Date(formattedDate);
      console.log('To Date Changed:', formattedDate);
    } else {
      const defaultDate = new Date();
      this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);
      console.log('To Date Reset to End of Day:', this.defaultDateTo);
    }
  }

  intializeUserSettings() {

    if (this.userData.usergroup == "superadmin") {
      this.userType = "superadmin";
    } else if (this.userData.usertype == "admin" && this.userData.usergroup != "superadmin") {      
      this.userProfileId = this.userData.id;
      this.userId = this.userData.id;
      this.userType = "admin";
    } else if (this.userData.usertype == "agentUser") {
      this.userProfileId = this.userData.admin;
      this.userId = this.userData.id;
      this.userType = "agentUser";
    } else if (this.userData.usertype == "virtualUser") {
      this.userProfileId = this.userData.admin;
      this.userId = this.userData.id
      this.userType = "virtualUser";
    }
  }


  customerEvent(id) {
    if (id != 0) {
      this.userProfileId = id;
      this.userId = id;
      this.getAgentList();
    } else {
      this.reportForm.reset();
      this.reportForm.patchValue({ agent: "" })
      this.userProfileId = null;
      this.userId = null;
      this.reportData = [];
    }
    

  }

  getAgentList() {    
    
    if (!["agentUser", "virtualUser"].includes(this.userType) && (this.userType == "admin" || this.userProfileId)) {
      const data = {
        admin_id: this.userProfileId,
        user_type_list: ["agentUser", "virtualUser"]
      }
      this.breakTimeReportService.getAgentUsersList(data).subscribe((res: any) => {
        if (res.status === "1") {
          this.agentList = res.data;
        }
      })
    } else if (this.userType == "agentUser") {
      const data = {
        action: "fetch",
        id: this.userId
      }
      this.breakTimeReportService.getAgentUser(data).subscribe((res: any) => {
        if (res.status === "1") {
          this.agentList.push(res.data);
        }
      })
    } else if (this.userType == "virtualUser") {
      const data = {
        admin_id: this.userProfileId,
        user_type_list: ["virtualUser"]
      }
      this.breakTimeReportService.getAgentUsersList(data).subscribe((res: any) => {
        if (res.status === "1") {
          this.agentList = res.data.filter(item => String(item.id) === String(this.userData.id));
        }
      })
    }
  }

  onSubmit() { 
    if (!this.reportForm.value.startDate && this.reportForm.value.endDate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.reportForm.value.startDate && this.reportForm.value.endDate) {
      const fromDate = new Date(this.reportForm.value.startDate);
      const toDate = new Date(this.reportForm.value.endDate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }
    if (!this.dateTimeValid) {
      return;
    }
    const data = {
      scope: "summary",
      user_id: this.userId,
      agent_id: this.reportForm.value.agent,
      user_type: this.userType,
      // start_date: this.reportForm.value.startDate,
      // end_date: this.reportForm.value.endDate
      start_date: this.datePipe.transform(this.reportForm.value.startDate, 'yyyy-MM-dd HH:mm:ss'),
      end_date: this.datePipe.transform(this.reportForm.value.endDate, 'yyyy-MM-dd HH:mm:ss'),
    }   
    this.breakTimeReportService.getBreakTimeReport(data).subscribe((res: any) => {
      if (res.status === "1") {
        this.reportData = res.data;
      }
    })
    
  }

  onGetDetail(agentId: string) {
    if (!this.reportForm.value.startDate && this.reportForm.value.endDate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.reportForm.value.startDate && this.reportForm.value.endDate) {
      const fromDate = new Date(this.reportForm.value.startDate);
      const toDate = new Date(this.reportForm.value.endDate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }
    this.agentId = agentId;

    const data = {
      scope: "detail",
      user_id: agentId,
      start_date: this.datePipe.transform(this.reportForm.value.startDate, 'yyyy-MM-dd HH:mm:ss'),
      end_date: this.datePipe.transform(this.reportForm.value.endDate, 'yyyy-MM-dd HH:mm:ss'),
    }

    this.breakTimeReportService.getBreakTimeDetail(data, this.pagination.current_page).subscribe((res: any) => {
      if (res.status === "1") {
        this.detailData = res.results;
        this.pagination.total_page = res.total_pages;
        this.showModal = true;
      }
    })
    
  }

  closeModal() {
    this.showModal = false;
    this.detailData = [];
  }

  paginate(event) {
    if (event == "first") {
      this.pagination.current_page = 1;
      this.onGetDetail(this.agentId);
    }

    if (event == "last") {
      this.pagination.current_page = this.pagination.total_page;
      this.onGetDetail(this.agentId);
    }

    if (
      event == "next" &&
      this.pagination.current_page < this.pagination.total_page
    ) {
      this.pagination.current_page += 1;
      this.onGetDetail(this.agentId);
    }

    if (event == "prev" && this.pagination.current_page != 1) {
      this.pagination.current_page -= 1;
      this.onGetDetail(this.agentId);
    }
  }

  fetchByPageNumber(num) {
    if (parseInt(num) === parseInt(num, 10) && num > 0 && num <= this.pagination.total_page) {
      this.pagination.current_page = parseInt(num);
      this.onGetDetail(this.agentId);
    }
    else {
      Swal.fire('Oops..!', 'Enter Valid Number', 'warning');
    }

  }


}
