import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { UsermanagementService } from "../../app/usermanagement.service";
import { NotificationService } from '../notification/notification.service';

@Component({
  selector: 'app-livecallreport',
  templateUrl: './livecallreport.component.html',
  styleUrls: ['./livecallreport.component.css']
})
export class AgentLivecallreportComponent implements OnInit {

  userData: any;
  live_call_datas: any;
  userType: any;
  permissions: any;
  cadmin_l_liveCallListen = false
  cadmin_l_liveCallWhisper = false
  cadmin_l_liveCallBarge = false



  constructor(
    private loginservice: LoginService,
    private usermanagementservice: UsermanagementService,
    private notifyservice: NotificationService
  ) { }

  ngOnInit() {
    this.userType = localStorage.getItem("userType")
    this.userData = this.loginservice.getUserdata(true);
    this.permissions = this.userData.permissions;
    for (let element of this.permissions) {
      if (element.codename == "cadmin_l_liveCallListen") {
        this.cadmin_l_liveCallListen = true;
      }
      if (element.codename == "cadmin_l_liveCallWhisper") {
        this.cadmin_l_liveCallWhisper = true;
      }
      if (element.codename == "cadmin_l_liveCallBarge") {
        this.cadmin_l_liveCallBarge = true
      }
    }
    this.fetchData();
  }


  fetchData() {
    this.usermanagementservice.load_live_call_details(this.userData.id, this.userType).subscribe(
      (res: any) => {
        this.live_call_datas = res.data
      }
    )
  }

  callMonitor(call_id: any, listen_mode: any) {
    const callRecord = this.live_call_datas.find(call => call.id === call_id);
    const secondaryAdminNumber = localStorage.getItem("secondary_number")

    const CallData = {
      dial_one: secondaryAdminNumber || this.userData.phone,
      outbound_caller_id: callRecord.outbound_caller_id,
      listen_call_id: callRecord.record_file,
      listen_mode: listen_mode,
      channel_id: callRecord.channel_id,
      user_id: this.userData.id,
    };

    if (callRecord.app_name === 'QUEUE' || callRecord.app_name === 'IVR' || callRecord.app_name === 'CALLBACK' || callRecord.app_name === 'VSIP_FORWARD') {
      CallData['call_type'] = 'INBOUND';
    }

    this.usermanagementservice.live_call_monitor(CallData).subscribe(
      (res: any) => {
        if (res.Success) {
          this.notifyservice.sendNotification("Success", "Call initiated successfully", "is-info");
        }
        else if (res.Failed) {
          this.notifyservice.sendNotification("Error", "Call initiation failed", "is-danger");
        }
      }
    )
  }

}

