import { Component, OnInit, Input,Output,EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsermanagementService } from '../usermanagement.service';
import { LoginService } from '../../login.service';
import { FileuploadComponent } from 'src/app/fileupload/fileupload.component';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NotificationService } from 'src/app/notification/notification.service';
import { CombinedUsersComponent } from '../combined-users/combined-users.component';
import { filter } from 'jszip';


@Component({
  selector: 'app-user-permission',
  templateUrl: './userpermissions.component.html',
  styleUrls: ['./userpermissions.component.css']
})
export class UserPermissionComponent implements OnInit {
  customerList: any = new Array();
  UserPermissionForm: FormGroup;
  UserProfileId: number;
  userData: any;
  isAdmin: string = 'false';
  userGroup: string = "";
  isEdit: boolean = true;
  isUserSelected: boolean = false;
  isSubmitted: boolean = false;
  permissions: any[]= [];
  @Output() customerEmitter: any = new EventEmitter();
  @Output() userEmitter: any = new EventEmitter();
  @ViewChild('UserList') UserList: ElementRef

  constructor(
    private loginservice: LoginService,
    private usermanagementservice: UsermanagementService,
    private route: Router,
    private activeroute: ActivatedRoute,
    private notifyservice: NotificationService
  ) { }

  ngOnInit() {    
    this.userData = this.loginservice.getUserdata(true);
    this.userGroup = this.userData.group[0]
    this.isAdmin = localStorage.getItem('isAdmin')    
    this.getPermissionSet(this.userGroup)
    
    this.UserPermissionForm = new FormGroup({
      cadmin_a_permission_dashboard: new FormControl(false),
      cadmin_b_permission_usermanagement: new FormControl(false),
      cadmin_b_blockedNumbers: new FormControl(false),
      cadmin_c_permission_queue: new FormControl(false),
      cadmin_q_addQueue: new FormControl(false),
      cadmin_q_QueueList: new FormControl(false),
      cadmin_q_QueueTransfer: new FormControl(false),
      cadmin_d_permission_inboundroute: new FormControl(false),
      cadmin_q_inboundReportList: new FormControl(false),
      cadmin_e_permission_timecondition: new FormControl(false),
      cadmin_f_matchpattern: new FormControl(false),
      cadmin_e_permission_caller_registration: new FormControl(false),
      cadmin_g_permission_ivr: new FormControl(false),
      cadmin_h_permission_extension: new FormControl(false),
      cadmin_i_voicerecording: new FormControl(false),
      cadmin_j_voicebroadcast: new FormControl(false),
      cadmin_m_dialer: new FormControl(false),
      cadmin_u_dialerList: new FormControl(false),
      cadmin_v_dialerexcel: new FormControl(false),
      cadmin_n_reports: new FormControl(false),
      cadmin_q_dialerReport: new FormControl(false),
      cadmin_o_inboundreport: new FormControl(false),
      cadmin_p_missedcallreport: new FormControl(false),
      cadmin_c_callbackreport: new FormControl(false),
      cadmin_r_sms_configuration: new FormControl(false),
      cadmin_s_creditsystem: new FormControl(false),
      cadmin_u_didmanagement: new FormControl(false),
      cadmin_t_recharge: new FormControl(false),
      cadmin_k_singleschedule: new FormControl(false),
      cadmin_l_multipleschedule: new FormControl(false),
      cadmin_q_smsreport: new FormControl(false),
      cadmin_s_addSms: new FormControl(false),
      cadmin_s_smsList: new FormControl(false),
      cadmin_s_templates: new FormControl(false),
      cadmin_s_viewReport: new FormControl(false),
      cadmin_s_smsCount: new FormControl(false),
      cadmin_s_creditList: new FormControl(false),
      cadmin_s_addCredit: new FormControl(false),
      cadmin_t_inboundFeedback: new FormControl(false),
      cadmin_t_InboundFeedbackReport: new FormControl(false),
      cadmin_a_addUsersLog: new FormControl(false),
      cadmin_a_listUsers: new FormControl(false),
      cadmin_t_addQuestions: new FormControl(false),
      cadmin_a_agentUsers: new FormControl(false),
      cadmin_o_callerreport: new FormControl(false),
      cadmin_o_dialpadreport: new FormControl(false),
      cadmin_q_virtualSip: new FormControl(false),
      cadmin_o_autocallreport: new FormControl(false),
      cadmin_o_agentreport: new FormControl(false),
      cadmin_q_contactcentre: new FormControl(false),
      cadmin_v_voicemail: new FormControl(false),
      cadmin_w_callTransfer: new FormControl(false),
      cadmin_x_integrations: new FormControl(false),      
      cadmin_x_zoho_integration: new FormControl(false),
      cadmin_x_leadsuared: new FormControl(false),
      cadmin_x_bitrix: new FormControl(false),
      cadmin_x_kylas: new FormControl(false),
      cadmin_x_meritto: new FormControl(false),
      cadmin_x_integration_config: new FormControl(false),
      cadmin_x_whatsapp: new FormControl(false),
      cadmin_a_addressbook: new FormControl(false),
      cadmin_o_livecallreport: new FormControl(false),
      cadmin_o_elasticreport: new FormControl(false),
      cadmin_o_callcountreport: new FormControl(false),
      cadmin_c_audioreport: new FormControl(false),
      cadmin_l_liveCallListen: new FormControl(false),
      cadmin_l_liveCallWhisper: new FormControl(false),
      cadmin_l_liveCallBarge: new FormControl(false),
      cadmin_u_agent_break_report: new FormControl(false),
      cadmin_v_2FA_visibility_control: new FormControl(false),
      cadmin_r_api_template: new FormControl(false),
      cadmin_p_add_pushnotification: new FormControl(false),
      cadmin_r_voiceboat_template: new FormControl(false)
    });
    
    if (this.userGroup === "customer") {
      this.UserProfileId = this.userData.id
    }
    
  }

  customerEvent(data) {   
    this.isUserSelected = data == 0 ? false : true; 
    this.UserProfileId = data
    this.UserPermissionForm.reset()
    this.usermanagementservice.getUserPermission(this.UserProfileId).subscribe(
      (res: any) => {
        console.log(res);
        Object.keys(res).forEach((key) => {
          const formControl = this.UserPermissionForm.get(key);
  
          if (formControl) {
            this.UserPermissionForm.controls[key].setValue(true);            
          }
  
        })
      }
    )

  }

  userEvent(userId) {
    this.isUserSelected = userId == 0 ? false : true;
    this.UserProfileId = userId
    this.UserPermissionForm.reset()
    this.usermanagementservice.getUserPermission(userId, "auth_user").subscribe(
      (res: any) => {
        console.log(res);
        Object.keys(res).forEach((key) => {
          const formControl = this.UserPermissionForm.get(key);

          if (formControl) {
            this.UserPermissionForm.controls[key].setValue(true);
          }

        })
      }
    )
  }


  chooseCustomer(customer_id) {
    console.log(customer_id)
  }



  onSubmit() {
    this.isSubmitted = true
    if (this.UserPermissionForm.invalid || this.isUserSelected == false) {
      this.UserList.nativeElement.focus();
      this.UserList.nativeElement.scrollIntoView({ behavior: 'smooth' });
      return;

    } else {
      console.log(this.UserProfileId, this.UserPermissionForm.value)
      let httpRequest
      if (this.userGroup === "customer") {
        httpRequest = this.usermanagementservice.addUserPermission(this.UserProfileId, this.UserPermissionForm.value, "auth_user")
      } else {
        httpRequest = this.usermanagementservice.addUserPermission(this.UserProfileId, this.UserPermissionForm.value)
      }
      httpRequest.subscribe(
        (res: any) => {
          if (res && res.status == 1) {
            Swal.fire('Created', 'User Permission Successfully Created', 'success')
            this.UserPermissionForm.setValue({
              user: this.userData.id,
            });
           
          }
          else {
            Swal.fire('Failed', res.error, 'warning')
            this.UserPermissionForm.setValue({
              user: this.userData.id,
            });
          }
        }
      )
    }
  }
  checks = false;
  bulk(e1) {
    if (e1.target.checked == true) {
      this.checks = true;
    }
    else {
      this.checks = false;
    }
  }
  
  
  getPermissionSet(userGroup: string) {
    
    if (userGroup === 'superadmin' || userGroup === 'customer') {
      const commonPermissions = [
        { label: 'Dashboard', perm: 'cadmin_a_permission_dashboard', sub_perm: [] },
        { label: 'Address Book', perm: 'cadmin_a_addressbook', sub_perm: [] },
        {
          label: 'Reports', perm: 'cadmin_n_reports', sub_perm: [
            { label: 'Inbound Report', perm: 'cadmin_o_inboundreport' },
            { label: 'Dialer Report', perm: 'cadmin_q_dialerReport' },
            { label: 'Missed Call Report', perm: 'cadmin_p_missedcallreport' },
            { label: 'Callback Report', perm: 'cadmin_c_callbackreport' },
            { label: 'SMS Report', perm:'cadmin_s_viewReport' },
            { label: 'Inbound Feedback', perm: 'cadmin_t_InboundFeedbackReport' },
            { label: 'Caller Report', perm: 'cadmin_o_callerreport' },
            { label: 'Dialpad Report', perm: 'cadmin_o_dialpadreport' },
            { label: 'Autocall Report', perm: 'cadmin_o_autocallreport' },
            { label: 'Agent Report', perm: 'cadmin_o_agentreport' },
            { label: 'Agent Break Report', perm: 'cadmin_u_agent_break_report' },
            { label: 'LiveCall Report', perm: 'cadmin_o_livecallreport' },
            { label: 'AllCall Report', perm: 'cadmin_o_elasticreport'},
            { label: 'CallCount Report', perm: 'cadmin_o_callcountreport' },
            { label: 'Audio Report', perm: 'cadmin_c_audioreport'},
            { label: 'Call Transfer', perm: 'cadmin_w_callTransfer' },

          ]
        },
        { label: 'Two Factor Authentication', perm: 'cadmin_v_2FA_visibility_control', sub_perm: [] },

      ]

      this.permissions = this.permissions.concat(commonPermissions);     
      
    }
    
    if (userGroup === 'superadmin') {

      const superAdminPermissions = [
 
        { label: 'User Management', perm: 'cadmin_b_permission_usermanagement', sub_perm: [] },
        { label: 'Push Notification', perm: 'cadmin_p_add_pushnotification', sub_perm: [] },
        {
          label: 'Department', perm: 'cadmin_c_permission_queue', sub_perm: [
            { label: 'Add Department', perm: 'cadmin_q_addQueue' },
            { label: 'Department List', perm: 'cadmin_q_QueueList' },
            { label: 'Department Transfer', perm: 'cadmin_q_QueueTransfer' },
            { label: 'Add Department Agents', perm: 'cadmin_a_agentUsers' }
          ]
        },
        {
          label: 'Inbound Route',perm: 'cadmin_d_permission_inboundroute', sub_perm: [
            { label: 'Route List', perm: 'cadmin_q_inboundReportList' },
            { label: 'Caller Registration', perm: 'cadmin_e_permission_caller_registration' },
            { label: 'Time Condition', perm: 'cadmin_e_permission_timecondition' },
            { label: 'Feedback', perm: 'cadmin_t_inboundFeedback' },
            { label: 'API Template', perm: 'cadmin_r_api_template' },
            { label: 'Voicebot Template', perm: 'cadmin_r_voiceboat_template'}
          ]
        },
        { label: 'IVR', perm: 'cadmin_g_permission_ivr', sub_perm: [] },
        { label: 'Virtual SIP', perm: 'cadmin_q_virtualSip', sub_perm: [] },
        { label: 'Voice Recording', perm: 'cadmin_i_voicerecording', sub_perm: [] },
        { label: 'Extention', perm: 'cadmin_h_permission_extension', sub_perm: [] },
        {
          label: 'Dialer', perm: 'cadmin_m_dialer', sub_perm: [
            { label: 'Dialer List', perm: 'cadmin_u_dialerList' },
            { label: 'Customer Upload', perm: 'cadmin_v_dialerexcel' }
          ]
        },
        { label: 'Voice Broadcast', perm: 'cadmin_j_voicebroadcast', sub_perm: [] },
        {
          label: 'SMS', perm: 'cadmin_r_sms_configuration', sub_perm: [
            { label: 'Add SMS', perm: 'cadmin_s_addSms' },
            { label: 'SMS List', perm: 'cadmin_s_smsList' },
            { label: 'Templates', perm: 'cadmin_s_templates' },
            { label: 'SMS Report', perm: 'cadmin_s_viewReport' },
            { label: 'SMS Count', perm: 'cadmin_s_smsCount' }
          ]
        },
        { label: 'Voice Mail',perm: 'cadmin_v_voicemail', sub_perm: [] },
        { label: 'Call Transfer', perm: 'cadmin_w_callTransfer', sub_perm: [] },
        { label: 'Contact Centre', perm: 'cadmin_q_contactcentre', sub_perm: []},
        {
          label: 'Integrations', perm: 'cadmin_x_integrations', sub_perm: [
            { label: 'Zoho Phonebridge', perm: 'cadmin_x_zoho_integration' },
            { label: 'Leadsquared CRM', perm: 'cadmin_x_leadsuared' },
            { label: 'Kylas CRM', perm: 'cadmin_x_kylas' },
            { label: 'Meritto CRM', perm: 'cadmin_x_meritto' },
            { label: 'Bitrix CRM', perm: 'cadmin_x_bitrix' },
            { label: 'Integration Config', perm: 'cadmin_x_integration_config'},
            
        ] },
        { label: 'Live Call Listen', perm: 'cadmin_l_liveCallListen', sub_perm: [] },
        { label: 'Live Call Whisper', perm: 'cadmin_l_liveCallWhisper', sub_perm: [] },
        { label: 'Live Call Barge', perm: 'cadmin_l_liveCallBarge', sub_perm: [] },
        { label: 'Whatsapp', perm: 'cadmin_x_whatsapp', sub_perm: [] },
        { label: 'Blocked Numbers', perm: 'cadmin_b_blockedNumbers', sub_perm: [] }



      ]

      this.permissions = this.permissions.concat(superAdminPermissions);
      
    }

    const user_permissions = this.userData.permissions.map(perm => perm.codename);
    this.permissions = this.permissions
      .filter(perm => user_permissions.includes(perm.perm) ||
        perm.sub_perm.some(subPerm => user_permissions.includes(subPerm.perm)))
      .map(perm => {
        return {
          ...perm,
          sub_perm: perm.sub_perm.filter(subPerm => user_permissions.includes(subPerm.perm))
        };
      });
  }

  onSelectAll(event) {
    if (event.target.checked) {
      this.permissions.forEach(perm => {
        this.UserPermissionForm.get(perm.perm).setValue(true);
        const sub_perm = perm.sub_perm
        if (sub_perm.length > 0) {
          sub_perm.forEach(perm => {
            this.UserPermissionForm.get(perm.perm).setValue(true);
          })
        }
 
      })

    } else {
      this.permissions.forEach(perm => {
        this.UserPermissionForm.get(perm.perm).setValue(false);
        const sub_perm = perm.sub_perm
        if (sub_perm.length > 0) {
          sub_perm.forEach(perm => {
            this.UserPermissionForm.get(perm.perm).setValue(false);
          })

        }
      })

    }
  }

  onPermSelect(event, permission) {
    const sub_perm = this.permissions.find(perm => perm.perm == permission).sub_perm
    if (!event.target.checked) {
      sub_perm.forEach(perm => {
        this.UserPermissionForm.get(perm.perm).setValue(false);
      })
    
    }
  }

}
