import { Component, OnInit } from '@angular/core';
import { InboundRouteService } from '../inbound-route.service';
import { LoginService } from 'src/app/login.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';
import { UsermanagementService } from 'src/app/usermanagement/usermanagement.service';

@Component({
  selector: '[app-inbound-route-list]',
  templateUrl: './inbound-route-list.component.html',
  styleUrls: ['./inbound-route-list.component.css']
})
export class InboundRouteListComponent implements OnInit {
  public userData: any;
  public inbounRouteData: any;
  permissions: any;
  permis = false;
  searchText: string = "";
  searchCustomer: string = "";
  current_page: number = 1;
  itemname: string = "";
  last: number;
  total_page: any;
  isAdmin: string = "false";
  CustomerList: any;
  dropdownList: { id: number, itemName: string }[] = [];
  dropdownSettings = {};
  selectedItems = [];
  selectedItem: any;
  public allCompanies: any = [];
  userType: any;
  isAdminUser: boolean = false;
  isInputDisabled = false;


  constructor(
    private loginservice: LoginService,
    private router: Router,
    private inboundrouteservice: InboundRouteService,
    private notifyservice: NotificationService,
    private usermanagementservice: UsermanagementService,

  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.permissions = this.userData.permissions
    this.selectedItem = ''
    this.usermanagementservice.getCompanies().subscribe((data: any) => {
      this.allCompanies = data;
      this.dropdownList = this.allCompanies.map((item, index) => ({
        id: index + 1,
        itemName: item.name
        // itemName: item.name.toLowerCase()
      }));
    });
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: true,
      text: "Select Customer",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "text-lg",
    };

    for (let element of this.permissions) {
      if (element.codename == "cadmin_q_inboundReportList") {
        this.permis = true;
      }
    }
    if (this.userData.group == 'superadmin') {
      this.isAdminUser = true
      this.inboundrouteservice.getInboundRoutesList(this.searchText, '', '', '1').subscribe(
        (data: any) => {
          this.inbounRouteData = data["results"];
          this.total_page = data["total_pages"];
          this.current_page = data["current_page"];
          this.last = data["total_pages"];
        }
      )
    } else {
      this.inboundrouteservice.getInboundRoutesList(this.searchText, '', this.userData.id, '1').subscribe(
        (data: any) => {
          this.inbounRouteData = data["results"];
          this.total_page = data["total_pages"];
          this.current_page = data["current_page"];
          this.last = data["total_pages"];
        }
      )
    }

  }

  AddInboundRoute() {
    localStorage.setItem("customer", null);
    this.router.navigateByUrl('/route/inboundroute/0');
  }

  editInboundRoute(id) {

    this.inboundrouteservice.getInboundRoute(id).subscribe(
      (data: any) => {

        this.loginservice.getCustomer(data.user).subscribe(
          (res: any) => {
            this.loginservice.setCustomer(res)
            this.router.navigateByUrl('/route/inboundroute/' + id);
          }
        )
      }
    )

  }


  ShowErrorMsg(msg) {
    this.notifyservice.sendNotification('Queue Error', msg, 'is-danger')
  }


  deleteInboundRoute(id) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'The Inbound Route Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.inboundrouteservice.deleteInboundRoute(id).subscribe(
          (res: any) => {
            if (res["status"] == '0'){
              Swal.fire("Error", "This DID is in use for VirtualSIP callback. To delete this Inbound Route, kindly disable the callback feature for VitualSIP users with this Outbound CallerID", "warning");
            }
            else{
              this.notifyservice.sendNotification('Deleted', 'Inbound Route deleted successfully', 'is-success')
              if (this.userData.group == 'superadmin') {
                this.inboundrouteservice.getInboundRoutesList(this.searchText, '', '', '1').subscribe(
                  (data: any) => {
                    this.inbounRouteData = data.results;
                  }
                )
              } else {
                this.inboundrouteservice.getInboundRoutesList(this.searchText, '', this.userData.id, '1').subscribe(
                  (data: any) => {
                    this.inbounRouteData = data.results;
                  }
                )
              }
            }
          }
        )
      } else {
        this.notifyservice.sendNotification('Cancelled', 'Inbound Route deletion has been cancelled', 'is-danger')
      }
    })
  }
  onItemSelect(item: any) {
    this.selectedItem = item
  }
  onDeSelect(item: any) {
    this.selectedItem = { itemName: "" };
  }
  onSelectAll(items: any) {
  }
  // o
  onDeSelectAll(items: any) {
    this.isInputDisabled = false;
    this.selectedItem = { itemName: "" }; // Resetting selectedItem object with an empty itemName
  }

  OnItemDeSelect(item: any) {
    this.isInputDisabled = false;
    this.selectedItem = { itemName: "" };
  }




  searchBox() {
    this.FetchDidReportData(1);
  }

  FetchDidReportData(pageNo) {
    if (this.userData.group == 'superadmin') {
      this.isAdminUser = true
      this.selectedItem.itemName
      this.selectedItem.itemName
      if (this.selectedItem) {
        this.itemname = this.selectedItem.itemName;
      }

      this.inboundrouteservice.getInboundRoutesList(this.searchText, this.itemname, '', pageNo).subscribe(
        (data: any) => {
          this.inbounRouteData = data["results"];
          this.total_page = data["total_pages"];
          this.current_page = data["current_page"];
          this.last = data["total_pages"];
        }
      )
    } else {
      this.inboundrouteservice.getInboundRoutesList(this.searchText, '', this.userData.id, pageNo).subscribe(
        (data: any) => {
          this.inbounRouteData = data["results"];
          this.total_page = data["total_pages"];
          this.current_page = data["current_page"];
          this.last = data["total_pages"];
        }
      )
    }
  }

  fetchNumber(i) {
    if (i == "prev") {
      if (this.current_page > 1) {
        this.FetchDidReportData(this.current_page - 1);
      }
    } else if (i == "next") {
      if (this.current_page < this.last) {
        this.FetchDidReportData(this.current_page + 1);
      }
    } else if (i == "first") {
      this.FetchDidReportData(1);
    } else if (i == "last") {
      this.FetchDidReportData(this.last);
    } else {
      if (parseInt(i) === parseInt(i, 10) && i > 0 && i <= this.last) {
        this.FetchDidReportData(i);
      } else {
        Swal.fire("Oops..!", "Enter Valid Number", "warning");
      }
    }
  }

}
