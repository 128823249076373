import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { template } from '@angular/core/src/render3';
import { SmsService } from '../sms.service';
import { LoginService } from 'src/app/login.service';
import { element, setHostBindings } from '@angular/core/src/render3/instructions';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-custom-template',
  templateUrl: './custom-template.component.html',
  styleUrls: ['./custom-template.component.css'],
})
export class CustomTemplateComponent implements OnInit {
  @Input() public customsmsopen: Boolean;
  @Input() public id: string;
  @Input() public data: any = [];
  @Input() public customer_number: string;
  @Input() public did: string;@Input() public 
  @Input() public agent_number: string;
  @Input() public call_time: string;
  @Input() public agent_name: string = "";
  @Input() public callStatus: string;
  @Input() public customer_name: string;
  @Input() public report: string = "";

  @Output() public CloseComment = new EventEmitter<void>();



  public userData: any;
  template: string = "";
  templates: any = [];
  tep_message: any = [];
  inputData: any = {};
  promotional_sms: string;
  selectedInput: string;
  templateSegments: Array<{ type: string; value: string; placeholder?: string }> = []
  showTemplate: boolean = false;
  sampleData: any = {};
  draggedData: string | null = null;
  admin_id: any;

  calltime: any;

  constructor(
    private smsService: SmsService,
    private loginService: LoginService,
  ) { }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(true);
    this.processTemplate();
    this.getTemplate();
    // this.getOptions();
    this.admin_id = this.userData.admin
  }

  objectKeys() {
    return Object.keys(this.sampleData);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.userData = this.loginService.getUserdata(true);
    if (changes.customsmsopen) {
      this.getTemplate();
    }
  }

  getTemplate() {
    const data = {
      "user_id": this.userData.id,
      "user_type": this.userData.usertype,
      "admin": this.admin_id
    }
    this.smsService.getCustomTemplateList(data).subscribe((data: any) => {
      this.templates = data;
    })
  }
  // getOptions() {
  //   this.smsService.getOptions(this.userData.id, this.report).subscribe((data: any) => {
  //     this.sampleData = data[0].options
  //   })
  // }

  TemplateChange(id: any): void {

    try {
      const date = new Date(this.call_time); 

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); 
      const day = date.getDate().toString().padStart(2, "0");

      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      const amPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12; 
      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${amPm}`;
      this.calltime = formattedDateTime

    } catch (error) {
      this.calltime = this.call_time
    }


    this.sampleData = {
      "CUSTOMER": this.customer_number,
      "AGENT": this.agent_name,
      "STATUS": this.callStatus,
      "AGENT NUMBER": this.agent_number,
      "CUSTOMER NAME": this.customer_name,
      "DID": this.did,
      "TIME":this.calltime
    }
    console.log("----------------------",this.data,"----------------------")
    const selectedTemplate = this.templates.find(item => item.id.toString() === id)
    if (selectedTemplate) {
      this.template = selectedTemplate.description
      this.promotional_sms = selectedTemplate.promational_template
      this.processTemplate();
      this.showTemplate = true;
    }
    console.log("Caller Id   :    ", this.customer_number)
    // this.sampleData=this.data
  }

  sendMessage() {
    this.template = this.templateSegments.map(item => item.value).join('');
    console.log("Repoprt -------------->    :  ",this.report)
    const data = {
      "id": this.id,
      "did": this.did,
      "recipiant": this.customer_number.slice(-10),
      "user_id": this.userData.id,
      "user_type": this.userData.usertype,
      "message": this.template,
      "promotional_sms": this.promotional_sms,
      "callStatus": this.callStatus,
      "report": this.report,
      "admin_id":this.admin_id,
    }

    if (this.template != "") {
      const response = this.smsService.sendSms(data).subscribe((data: any) => {
        if (data.status == 0) {
          Swal.fire('', "Failed!", 'warning')
        } else {
          Swal.fire('', data.message, 'success')
        }
        this.close()
      })
    } else {
      Swal.fire('', "Please choose a template", 'warning')
    }
  }


  close() {
    this.CloseComment.emit();
    this.templateSegments = [];
    this.templates = []
    this.showTemplate = false;
  }

  processTemplate() {
    const regex = /{(\w+)}/g; // Regular expression to match placeholders like {n1}, {t1}, {t2}, etc.
    let lastIndex = 0;

    this.templateSegments = []; // Initialize templateSegments array

    let match;
    let index = 0;
    while ((match = regex.exec(this.template)) !== null) {
      console.log("Template   :   ", this.template)
      console.log("Caller Id   :    ", this.customer_number)
      // Add text before the placeholder
      if (match.index > lastIndex) {
        this.templateSegments.push({
          type: 'text',
          value: this.template.slice(lastIndex, match.index),
        });
      }

      // Add the placeholder segment (replace it with dashes)
      this.templateSegments.push({
        type: 'placeholder',
        value: '-----------', // Replace placeholder with dashes (adjust length as needed)
        placeholder: `${match[1]}-${index}`, // Save the placeholder name (e.g., 'n1', 't1')
      });

      lastIndex = regex.lastIndex;
      index++; // Increment the counter for the next placeholder
    }

    // Add remaining text after the last placeholder
    if (lastIndex < this.template.length) {
      this.templateSegments.push({
        type: 'text',
        value: this.template.slice(lastIndex),
      });
    }

    console.log(this.templateSegments); // Check the final result
  }

  onInputChange(event: Event, segment: { placeholder: string }) {
    const inputElement = event.target as HTMLInputElement;
    console.log(segment.placeholder, " : ", inputElement)
    this.inputData[segment.placeholder] = inputElement.value
    const message = this.templateSegments.find(item => item.type === 'placeholder' && item.placeholder === segment.placeholder);
    if (message) {
      message.value = inputElement.value
    }
  }

  // Triggered when dragging starts
  onDragStart(event: DragEvent, data: string): void {
    this.draggedData = data; // Save the dragged data
    event.dataTransfer.setData('text/plain', data); // Set the data to transfer
    event.dataTransfer.effectAllowed = 'move';
  }

  // Allow dropping by preventing default behavior
  allowDrop(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent, index: number): void {
    event.preventDefault();

    // Get the dragged data
    const data = event.dataTransfer.getData('text/plain');
    if (!data || this.templateSegments[index].type !== 'placeholder') {
      return; // Exit if no data or not a placeholder
    }

    // Get the input element
    const inputElement = event.target as HTMLInputElement;

    // Ensure the input element exists
    if (!inputElement) {
      return;
    }

    // Get the cursor position
    const cursorPosition = inputElement.selectionStart !== null ? inputElement.selectionStart : inputElement.value.length;

    // Insert the dragged text at the cursor position
    const currentValue = inputElement.value;
    const newValue =
      currentValue.slice(0, cursorPosition) + // Text before the cursor
      data +                                // The dragged text
      currentValue.slice(cursorPosition);   // Text after the cursor

    // Update the input field's value
    inputElement.value = newValue;

    // Update the corresponding segment value
    this.templateSegments[index].value = newValue;

    // Optional: Move cursor to the end of the newly inserted text
    const newCursorPosition = cursorPosition + data.length;
    inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
  }


}
