import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { UsermanagementService } from "../../app/usermanagement.service";
import { DatePipe } from '@angular/common';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ReportService } from '../../app/inbound-report/report.service';
import { NotificationService } from 'src/app/notification/notification.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { ExcelService } from '../inbound-report/excel.service';
import * as jsPDF from "jspdf";
import { ReportSettingsService } from '../report-settings/report-settings.service';
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";

@Component({
  selector: 'app-autocall-report',
  templateUrl: './autocall-report.component.html',
  styleUrls: ['./autocall-report.component.css'],
  providers: [DatePipe]

})


export class AutocallReportComponent implements OnInit {

  datas: any;
  voiceRecordData: any = new Array();
  userData: any;
  searchData: string = '';
  callstatus: string = '';
  date1: string;
  goPageNumber: string;
  current_page: any;
  last_page: any;
  fromdate: any;
  todate: any;
  today = new Date();
  AutoReportData: any;
  myDate = new Date();
  tomorrow = new Date(this.today.setDate(this.today.getDate() + 1));
  tdy = new Date(this.myDate.setDate(this.myDate.getDate()));
  userType: any;
  ExcelPdfReportData: any = new Array();
  wordarray: any = new Array();


  // Settingsforcomment
  commentopen: boolean = false;
  customsmsopen: boolean = false;
  comment: any
  call_id: any;


  tagList: any = new Array()
  showTagDiv: boolean = false
  tag_filter: any = ""
  defaultDateFrom: Date;
  defaultDateTo: Date;

  constructor(
    private reportservice: ReportService,
    private loginservice: LoginService,
    private usermanagementservice: UsermanagementService,
    private datePipe: DatePipe,
    private notifyService: NotificationService,
    private excelService: ExcelService,
    private reportsettingservice: ReportSettingsService,
    private spinnerService: Ng4LoadingSpinnerService

  ) {

  }


  ngOnInit() {
    const defaultDate = new Date();
    this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);

    this.fetchData(1, false)
    this.userType = localStorage.getItem('userType')
    this.getCallTags()
  }

  onFromDateChange(newValue: any) {
    if (newValue && newValue !== "") {
      this.defaultDateFrom = new Date(this.datePipe.transform(newValue, "yyyy-MM-dd HH:mm:ss"));
    } else {
      const defaultDate = new Date();
      this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    }
  }
  onToDateChange(newValue: any) {
    if (newValue && newValue !== "") {
      this.defaultDateTo = new Date(this.datePipe.transform(newValue, "yyyy-MM-dd HH:mm:ss"));
    } else {
      const defaultDate = new Date();
      this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);
    }
  }



  fetchData(pageNumberData, validate = true) {
    if (validate) {
      if (!this.fromdate && this.todate) {
        this.spinnerService.hide();
        alert("From date is required.");
        return;
      }

      if (this.fromdate && this.todate) {
        const fromDate = new Date(this.fromdate);
        const toDate = new Date(this.todate);

        // Validate that `fromdate` is not greater than `todate`
        if (fromDate > toDate) {
          this.spinnerService.hide();
          alert("From date cannot be greater than To date.");
          return;
        }
      }
    }
    this.userData = this.loginservice.getUserdata(true);
    var from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to = this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');

    var body = { "userId": this.userData.id, "userType": this.userType, "from": from, "to": to, "search": this.searchData, "callstatus": this.callstatus, "tag_filter": this.tag_filter }
    this.usermanagementservice.load_auto_call_report_by_page(body, pageNumberData).subscribe(
      (res: any) => {
        this.current_page = res.current_page;
        this.datas = res.results;
        this.last_page = res.total_pages;
        console.log(res);
      }
    )
  }

  fetchByPageNumber(pagenumber) {
    if (parseInt(pagenumber) === parseInt(pagenumber, 10) && pagenumber > 0 && pagenumber <= this.last_page) {
      this.current_page = parseInt(pagenumber);
      this.fetchData(pagenumber, true);
    }
    else {
      Swal.fire('Oops..!', 'Enter Valid Number', 'warning');
    }
    // this.fetchData(pagenumber)
  }

  fetchPreviousPage() {
    this.fetchData(this.current_page - 1, true)

  }

  fetchFirstPage() {
    this.fetchData(1, true)

  }

  fetchNextPage() {
    this.fetchData(this.current_page + 1, true)

  }

  getLastPage() {
    this.fetchData(this.last_page, true)
  }


  SearchReport() {
    this.fetchData(1, true)
  }


  searchBox() {
    this.fetchData(1, true)
  }




  getcallrecord(vffile, date, autocall_id) {
    if (!this.fromdate && this.todate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.fromdate && this.todate) {
      const fromDate = new Date(this.fromdate);
      const toDate = new Date(this.todate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }

    this.reportservice.getcallrecord(vffile, date, autocall_id, "autocall").subscribe(
      (res: any) => {
        this.AutoReportData = res["data"]

        var from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
        var to = this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');
        var body = { "userId": this.userData.id, "userType": this.userType, "from": from, "to": to, "search": this.searchData, "callstatus": this.callstatus, "tag_filter": this.tag_filter }
        this.usermanagementservice.load_auto_call_report_by_page(body, this.current_page).subscribe(
          (res: any) => {
            this.current_page = res.current_page;
            this.datas = res.results;
            this.last_page = res.total_pages;
            this.notifyService.sendNotification('Downloaded', 'Download Voice successfully', 'is-success')
          }
        )

      }
    )

  }


  CloseComment() {
    console.log("=============TRYING TO CLOSE 2====")
    this.commentopen = false;
    this.customsmsopen = false;
    this.call_id = ""
    this.comment = ""
  }


  exportAsXLSX(): void {
    if (!this.fromdate && this.todate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.fromdate && this.todate) {
      const fromDate = new Date(this.fromdate);
      const toDate = new Date(this.todate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }
    let text;
    let slNo = 0;
    let from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    let to = this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');
    let body = { "userId": this.userData.id, "userType": this.userType, "from": from, "to": to, "search": this.searchData, "callstatus": this.callstatus, "download": "yes", "tag_filter": this.tag_filter }
    this.usermanagementservice
      .load_auto_call_report(body)
      .subscribe((res: any) => {

        this.ExcelPdfReportData = res["results"];
        for (let element of this.ExcelPdfReportData) {

          var objz = {
            "Sl No": slNo += 1,
            "Event ID": element["eventID"],
            "Customer": element["legbDestinationNumber"],
            "Date & Time": this.getFormattedDateTime(
              element["callStartTime"]
            ),
            "Agent No": element["agent"],
            "Agent Name": element["AgentName"],
            "Department Name": element["queue_name"],
            "Duration": element["duration"],
            "Connected Duration": element["connected_duration"],
            "Agent Status": element["legaCallStatus"],
            "Customer Status": element["legbCallStatus"],
            'Comment': element['comment'],
            'Tag': element['tag']
          };

          this.wordarray.push(objz);
        }

        text = "Autocall Report from: " + from + " To: " + to + "";

        this.excelService.exportAsExcelFile(this.wordarray, text);
        this.wordarray = [];
      });

  }

  downloads() {
    if (!this.fromdate && this.todate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.fromdate && this.todate) {
      const fromDate = new Date(this.fromdate);
      const toDate = new Date(this.todate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }
    let slNo = 0;
    let newarray = [];
    let head = [
      [
        "Sl No",
        "Event ID",
        "Customer",
        "Date & Time",
        "Agent No",
        "Agent Name",
        "Department Name",
        "Duration",
        "Connected Duration",
        "Agent Status",
        "Customer Status  ",
        "Comment",
        "Tag"
      ],
    ];
    let from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    let to = this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');
    let body = { "userId": this.userData.id, "userType": this.userType, "from": from, "to": to, "search": this.searchData, "callstatus": this.callstatus, "download": "yes", "tag_filter": this.tag_filter }
    this.usermanagementservice
      .load_auto_call_report(body)
      .subscribe((res: any) => {
        this.ExcelPdfReportData = res["results"];
        for (let element of this.ExcelPdfReportData) {
          newarray.push([
            slNo += 1,
            element["eventID"],
            element["legbDestinationNumber"],
            this.getFormattedDateTime(element["callStartTime"]),
            element["agent"],
            element["AgentName"],
            element["queue_name"],
            element["duration"],
            element["connected_duration"],
            element["legaCallStatus"],
            element["legbCallStatus"],
            element["comment"],
            element['tag']
          ]);
        }
        var pdf = new jsPDF();
        pdf.text("Autocall Report", 11, 8);
        pdf.setFontSize(3);
        pdf.setTextColor(100);
        (pdf as any).autoTable({
          head: head,
          body: newarray,
          theme: "grid",
          styles: { fontSize: 4 },
          tableWidth: "auto",
          columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 10 },
        });
        pdf.output("dataurlnewwindow");
        pdf.save("Autocall Report" + from + "_to_" + to + ".pdf");
      });
  }


  getFormattedDateTime(datetime): string {
    const formattedDateTime = new Date(datetime).toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const [date, time] = formattedDateTime.split(", ");
    const formattedDate = date.replace(/\//g, "-");
    return `${formattedDate}, ${time}`;
  }

  updateReportPage() {
    this.fetchData(this.current_page, true);
  }

  addCallRecordTag(call_record_id: any, index: any) {
    this.reportsettingservice.getReportTagChild(call_record_id, "auto_call_record").subscribe((res: any) => {
      const updatedTags = res.tag.split(',').map(tag => tag.trim());
      this.datas[index].tag = updatedTags.join(', ');
    })
  }

  deleteCallRecordTag(call_record_id: any, tag: any, index: any) {
    const body = {
      tag: tag
    }

    this.reportsettingservice.deleteReportTagChild(call_record_id, body, "auto_call_record").subscribe((res: any) => {
      this.datas[index].tag = res.tags.tag
    })
  }

  getCallTags() {
    let user_id: any
    if (this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser') {
      user_id = this.userData.admin;
    } else {
      user_id = this.userData.id;
    }

    this.reportsettingservice.getReportTagMain(user_id).subscribe((res: any) => {
      this.tagList = res.report_tags
    })
  }

}


