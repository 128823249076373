import { Component, OnInit } from '@angular/core';
import { InboundRouteService } from '../inbound-route.service';
import swal from 'sweetalert2'
import { LoginService } from 'src/app/login.service';
import { NotificationService } from 'src/app/notification/notification.service';
import { Router } from '@angular/router';
@Component({
  selector: '[app-timecondition]',
  templateUrl: './timecondition.component.html',
  styleUrls: ['./timecondition.component.css']
})
export class TimeconditionComponent implements OnInit {

  public timedata: any;
  userData:any;
  permissions:any;
  permis = false;

  constructor(
    private inboundrouteservice:InboundRouteService,
    private loginservice: LoginService,
    private notifyservice: NotificationService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      console.log(element.codename)
      if(element.codename == "cadmin_q_timeCondition" || element.codename == "cadmin_e_permission_timecondition"){
        this.permis = true;
    }
  }
    if(this.userData.group == 'superadmin'){
      this.inboundrouteservice.getTimeConditionSetList(0).subscribe(
        (data:any) =>{
          this.timedata = data
        }
      )
    }else{
      this.inboundrouteservice.getTimeConditionSetList(this.userData.id).subscribe(
        (data:any) =>{
          this.timedata = data
        }
      )
    }
  }

  deleteTimeConditionSet(id) {
    swal.fire({
      title: 'Are you sure ?',
      text: 'The Time Condition Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.inboundrouteservice.deleteTimeConditionSet(id).subscribe(
          (res:any) => {
            console.log('data', res)
            this.notifyservice.sendNotification('Deleted', 'Time condition delete successfully', 'is-success')
            if(this.userData.group == 'superadmin'){
              this.inboundrouteservice.getTimeConditionSetList(0).subscribe(
                (data:any) =>{
                  this.timedata = data
                }
              )
            }else{
              this.inboundrouteservice.getTimeConditionSetList(this.userData.id).subscribe(
                (data:any) =>{
                  this.timedata = data
                }
              )
            }
          }
        )
      }else{
        this.notifyservice.sendNotification('Cancelled', 'Time condition deleting has been cancelled', 'is-danger')
      }
    })
  }
  
}
