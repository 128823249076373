import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { SharedService } from 'src/global';
@Injectable({
	providedIn: 'root'
})
export class DIDMgmtService {

	constructor(
		private http: HttpClient,
		public sharedService: SharedService

	) { }

	API_URL = this.sharedService.getGlobalVar()

	EditDid(id, body) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this.http.put(this.API_URL + 'DidEdit/' + id + "/", body, { headers: headers });
	}

	DIDManagementList(page: number, pageSize: number, requestBody: any) {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		let params = new HttpParams()
			.set('page', page.toString())
			.set('page_size', pageSize.toString());
		return this.http.post(this.API_URL + 'DidList' + '/', requestBody, { headers: headers, params: params });
	}

	getLocationAndProviderList() {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this.http.get(this.API_URL + 'StaticData', { headers: headers })
	}

	createDid(requestBody: any) {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.post(this.API_URL + 'CreateDid' + '/', requestBody, { headers: headers });
	}

	ManageDidCompany(requestBody: any) {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.post(this.API_URL + 'ManageDidCompany' + '/', requestBody, { headers: headers });
	}

	getDidCallHistory(requestBody: any) {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.post(this.API_URL + 'DidCallHistory' + '/', requestBody, { headers: headers });
	}


	deleteDid(id: number) {
		const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this.http.delete(this.API_URL + 'DeleteDid/' + id + "/", { headers: headers });
	}

}
