import { Component, OnInit } from '@angular/core';
import { ExtensionService } from 'src/app/extension/extension.service';
import { InboundRouteService } from 'src/app/inbound-route/inbound-route.service';
import { LoginService } from "../../login.service";
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SmsService } from 'src/app/sms/sms.service';
import Swal from 'sweetalert2';
//===================== SMS-DROPDOWN======================
import { IDropdownSettings } from 'ng-multiselect-dropdown';
//===================== END OF SMS-DROPDOWN======================


@Component({
  selector: 'app-add-sms',
  templateUrl: './add-sms.component.html',
  styleUrls: ['./add-sms.component.css']
})
export class AddSmsComponent implements OnInit {
  selected: any;
  dropdownList = [];
  selectedItems = [];
  selectedItems1 = [];
  dropdownSettings: IDropdownSettings
  didList: any;
  DIDNumber: number = 0;
  DIDNumberInitial: string = '';
  UserProfileId: number = 0;
  isAdmin: string = 'false';
  templates: any;
  selectedTemplate: any;
  public userData: any;
  Data: any;
  public SmsForm: FormGroup;
  Action = "";
  Recipient = "";
  CustomNumber: any;
  heading: any;
  datas: any;
  TempId: number;
  permissions: any;
  permis = false;
  id1 = this.activeroute.snapshot.paramMap.get('id');
  selectedMethod: "";
  list: any[];
  arrayText = []
  addText: any;
  index: number;
  medium1: any;
  CustomEmail: any;
  temp_DID_var: any
  isIVRTemplate: boolean = false;
  IVRTemplate: string = "";
  IVRTemplateList: any[] = [];



  ActionList: any = [
    {
      Value: 'ANSWERED',
      name: 'ANSWERED'
    },
    {
      Value: 'NOANSWER',
      name: 'NOANSWER'
    },
    {
      Value: 'BUSY',
      name: 'BUSY'
    },
    {
      Value: 'UNAVAILABLE',
      name: 'UNAVAILABLE'
    },
    {
      Value: 'NOINPUT',
      name: 'NOINPUT'
    }
  ]
  TemplateActionList: any;


  RecipientList: any = [
    {
      Value: 'CUSTOMER',
      name: 'CUSTOMER'
    },
    {
      Value: 'ANSWERD AGENT',
      name: 'ANSWERD AGENT'
    },
    {
      Value: 'FIRST MEMBER IN QUEUE',
      name: 'FIRST MEMBER IN QUEUE'
    },
    {
      Value: 'LAST DIALLED QUEUE AGENT',
      name: 'LAST DIALLED QUEUE AGENT'
    },
    {
      Value: 'CLIENT',
      name: 'CLIENT'
    },
    {
      Value: 'CUSTOM NUMBER',
      name: 'CUSTOM NUMBER'
    },
    {
      Value: 'CUSTOM E-MAIL',
      name: 'CUSTOM E-MAIL'
    },
    {
      Value: 'TEMPLATE RESPONDENT-1',
      name: 'TEMPLATE RESPONDENT-1'
    },
    {
      Value: 'TEMPLATE RESPONDENT-2',
      name: 'TEMPLATE RESPONDENT-2'
    },
    {
      Value: 'TEMPLATE RESPONDENT-3',
      name: 'TEMPLATE RESPONDENT-3'
    },
    {
      Value: 'TEMPLATE RESPONDENT-4',
      name: 'TEMPLATE RESPONDENT-4'
    },
  ]


  constructor(
    private extensionService: ExtensionService,
    private router: Router,
    private inboundRouteService: InboundRouteService,
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private activeroute: ActivatedRoute,
    private smsService: SmsService,
  ) {
  }

  ngOnInit() {
    this.dropdownList = [
      { item_id: 1, item_text: 'TEXT MESSAGE' },
      // { item_id: 2, item_text: 'WHATSAPP' },
      { item_id: 3, item_text: 'TELEGRAM' },
      { item_id: 4, item_text: 'PROMOTIONAL-TEMPLATES' },
      { item_id: 5, item_text: 'IVR' },
      { item_id: 6, item_text: 'E-MAIL' },
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: true
    };

    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      if (element.codename == "cadmin_s_addSms") {
        this.permis = true;
      }
    }
    this.TempId = +this.activeroute.snapshot.paramMap.get('id');
    this.isAdmin = localStorage.getItem('isAdmin')
    if (this.isAdmin == 'false') {
      this.UserProfileId = this.userData.id
    }
    if (this.TempId > 0) {
      this.getNameList(this.userData.id);
    }
    this.smsService.TemplateList(this.userData.id).subscribe(
      (data: any) => {
        this.templates = this.getFilteredTemplates(data)
      }
    )
  }

  getFilteredTemplates(data) {
    return data.filter((item) => item.custom_template === false)
  }
  keyword = 'name';
  //====================== SMS-DROPDOWN=========================

  onItemSelect(item: any) {
    const selection = item['item_text']
    this.arrayText.push(selection);
    if (selection == "IVR") {
      this.isIVRTemplate = true;
      this.getIVRTemplates();
    }
  }

  onSelectAll(items: any) {
    this.arrayText = []
    for (let i in items) {
      this.arrayText.push(items[i]['item_text']);
    }
  }

  onDeSelectAll(items: any) {
    for (let i in items) {
      this.arrayText = []
    }
  }

  onItemDeSelect(item: any) {
    this.arrayText.forEach((element, index) => {
      if (element == item['item_text']) { this.arrayText.splice(index, 1) };
    });

    if (item["item_text"] == "IVR") {
      this.isIVRTemplate = false;
    }

  }
  // ===========================END OF SMS DROPDOWN=============

  selectEvent(item) {
    this.DIDNumber = item.id;
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  onChangeSearch(val: string) {
    this.inboundRouteService.searchDID(val, this.UserProfileId).subscribe(
      (data: any) => {
        console.log("auto", data);
        this.didList = data
      }
    )
  }

  SearchDID(val: any) {
    // if(val != 0){
    //   this.smsService.ActionList(val.id).subscribe(
    //     (data:any) =>{
    //       console.log("tmep listss",data);
    //       this.TemplateActionList = data;
    //       if(this.TemplateActionList.length>0){
    //         for(let i=0;i<this.TemplateActionList.length;i++){
    //           for(let j=0;j<this.ActionList.length;j++){
    //             if(this.TemplateActionList[i].action == this.ActionList[j].Value){
    //               this.ActionList.splice(j,1)
    //             }
    //           }
    //         }
    //       }
    //     }
    //   )
    // }
  }

  onFocused(val) {
    this.inboundRouteService.searchDID(val, this.UserProfileId).subscribe(
      (data: any) => {
        console.log("auto", data);
        this.didList = data
      }
    )
  }

  getTempList(id) {
    this.selectedTemplate = this.smsService.TemplateList(1);
    console.log("uuuuuuu", this.selectedTemplate);
  }

  AddSms = function () {
    this.submitted = true;
    this.id = +this.activeroute.snapshot.paramMap.get('id');

    if (this.DIDNumber.id) {
      this.temp_DID_var = this.DIDNumber.id
    }
    else {
      this.temp_DID_var = this.datas.DID.id
    }

    if (this.action == "" || this.temp_DID_var == undefined || this.selectedTemplate == undefined) {
      Swal.fire('Oops..!', 'Please Enter All the Mandatory Fields', 'warning')
    }
    this.userData = this.loginService.getUserdata(false);
    this.Data = {
      'ID': this.id,
      'DID': this.temp_DID_var,
      'action': this.Action,
      'user': this.UserProfileId,
      'template': this.selectedTemplate,
      'recipient': this.Recipient,
      'customNumber': this.CustomNumber,
      'customEmail': this.CustomEmail,
      'medium': this.arrayText.toString(),
      'ivr_template': this.IVRTemplate
    }
    console.log("Final Add SMS Data => ", this.Data)

    this.smsService.AddSms(this.Data).subscribe(
      (res: any) => {
        if ((this.Data.ID) != 0) {
          this.router.navigate(['/sms/sms-list']);
          Swal.fire('Updated', 'Updated SMS', 'success')
        }
        else {
          Swal.fire('Created', 'Added SMS', 'success')
          this.reloadCurrentRoute()
        }
        console.log("response is ", res)
      }
    )
  }

  getNameList(id) {
    this.smsService.OneditSms(this.TempId).subscribe(
      (data: any) => {
        this.datas = data;
        this.DIDNumber = this.datas.DID.name;
        this.Action = this.datas.action;
        this.selectedTemplate = this.datas.template.id;
        this.Recipient = this.datas.recipient;
        this.CustomNumber = this.datas.customNumber;
        this.CustomEmail = this.datas.CustomEmail;

        console.log("SMS template data => ", this.datas)

        this.medium1 = (this.datas.medium).split(",")
        this.arrayText = this.medium1

        for (let i = 0; i < this.medium1.length; i++) {
          var dic = {};
          dic["id"] = i
          if (this.medium1[+i] != "") {
            dic["item_text"] = (this.medium1[+i])
            this.selectedItems.push(dic)
            console.log("slctd===========" + JSON.stringify(this.selectedItems))
          }
        }
        const resultArray = Object.keys(this.selectedItems).map(index => {
          let person = this.selectedItems[index];
          return person;
        });
        console.log("resultArray" + JSON.stringify(resultArray))
        this.selectedItems = resultArray

        this.selectedItems.forEach(item => {
          if (item.item_text === "IVR") {
            this.getIVRTemplates();
            this.isIVRTemplate = true;
            this.IVRTemplate = this.datas.ivr_template ? this.datas.ivr_template : ""
          }
        })


      }
    )
  }

  getIVRTemplates() {
    const qParams = [
      { key: "user_id", value: this.userData.id }
    ]
    this.smsService.getIVRTemplateList(qParams).subscribe((res: any) => {
      if (res.status === "1") {
        this.IVRTemplateList = res.data;
      }
    })
  }
}
