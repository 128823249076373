import { Injectable } from '@angular/core';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { SharedService } from './../../global';
import swal from 'sweetalert2';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InboundRouteService {
  
  constructor(
    private http: HttpClient,
    public sharedService:SharedService,

    ) { }

    API_URL = this.sharedService.getGlobalVar()
  // Time Condition API

  getTimeConditionSet(id:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'timeConditionSetDetails/'+id+'/', {
      headers: headers
    });
  }

  getTimeConditionSetList(userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'timeConditionSetList/'+userProfileId+'/', {
      headers: headers
    });
  }

  addTimeConditionSet(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
  //  body = {"name": "q", "description": "qq", "user": 2}
    console.log(body);
    return this.http.post(this.API_URL+'timeConditionSetList/0/', body, {headers: headers})
  }

  updateTimeConditionSet(id, body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(this.API_URL+'timeConditionSetDetails/'+id+'/', body, {headers: headers})
  }

  deleteTimeConditionSet(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.delete(this.API_URL+'timeConditionSetDetails/'+id+'/', {headers: headers})
  }

  searchTimeConditionSet(val:string, userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'timeConditionSetList/'+userProfileId+'/?search='+val, {
      headers: headers
    });
  }

  searchTimeConditions(val:string, userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'timeConditionList/'+userProfileId+'/?search='+val, {
      headers: headers
    });
  }

  addTimeCondition(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL+'timeConditionList/0/', body, {headers: headers})
  }

  getTimeConditionsList(timeConditionSetId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'timeConditionList/'+timeConditionSetId+'/', {
      headers: headers
    });
  }

  getTimeCondition(id: number) {
    const headers = new HttpHeaders();
    return this.http.get(this.API_URL+'timeConditionDetails/'+id+'/', {headers: headers})
  }

  updateTimeCondition(id, body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(this.API_URL+'timeConditionDetails/'+id+'/', body, {headers: headers})
  }


  deleteTimeCondition(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.delete(this.API_URL+'timeConditionDetails/'+id+'/', {headers: headers})
  }

  
  // Match Pattern API

  getMatchPatterns(userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    console.log(userProfileId);
    return this.http.get(this.API_URL+'matchPatternList/'+userProfileId+'/', {
      headers: headers
    });
  }

  addMatchPattern(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL+'matchPatternList/0/', body, {headers: headers})
  }

  getMatchPattern(id: number) {
    const headers = new HttpHeaders();
    return this.http.get(this.API_URL+'matchPatternDetails/'+id+'/', {headers: headers})
  }

  updateMatchPattern(id, body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(this.API_URL+'matchPatternDetails/'+id+'/', body, {headers: headers})
  }

  deleteMatchPattern(id:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.delete(this.API_URL+'matchPatternDetails/'+id+'/', {headers: headers})
  }

  // upload match pattern

  uploadMatchPattern(fileToUpload: File) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const formData: FormData = new FormData();
    formData.append('datas', fileToUpload, fileToUpload.name);
    formData.append('userId', '1')
    return this.http.post(this.API_URL+'matchPatternUpload/', formData, {headers: headers})
  }

  // update file match pattern
  updateFileMatchPattern(fileToUpload: File) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const formData: FormData = new FormData();
    formData.append('datas', fileToUpload, fileToUpload.name);
    formData.append('userId', '1')
    return this.http.put(this.API_URL+'matchPatternUpload/', formData, {headers: headers})
  }

  // Get DID NAME AND IDS


  getDIDList(userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DIDList/'+userProfileId+'/', {headers: headers})
  }


  searchDID(val:string,userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DIDList/'+userProfileId+'/?search='+val, {headers: headers})
  }


  getDID(id:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DIDdetails/'+id+'/', {headers: headers})
  }

  // Inbound Route API

  getInboundRoutesList(search, searchCustomer, userid, current_page) {
    const headers = new HttpHeaders();
    const body = { "search": search, "searchCustomer": searchCustomer, 'userid': userid }
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + 'routeList/?page=' + current_page, body, { headers: headers })
  }

  addInboundRoute(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    console.log('bodyyyyyyyy',body);
    return this.http.post(this.API_URL+'RouteCreate/', body , {headers: headers})
  }

  getInboundRoute(id:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'routeDetails/'+id+'/', {headers: headers})
  }

  updateInboundRoute(id, body) { 
    console.log("resssss",body);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(this.API_URL+'routeDetails/'+id+'/', body , {headers: headers})
  }

  deleteInboundRoute(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.delete(this.API_URL+'routeDetails/'+id+'/', {headers: headers})
  }

  // Route Timecondition API

  addRouteTimeCondtion(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL+'routeTimeConditionsList/0/', body , {headers: headers})
  }

  getRouteTimeConditionList(routeId) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'routeTimeConditionsList/'+routeId+'/', {headers: headers})
  }

  updateRouteTimeCondtion(id, body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(this.API_URL+'routeTimeConditionsDetails/'+id+'/', body , {headers: headers})
  }

  deleteRouteTimeCondition(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.delete(this.API_URL+'routeTimeConditionsDetails/'+id+'/', {headers: headers})
  }

  // Route Match Pattern API
  
  addRouteMatchPattern(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL+'routeMatchPatternsList/0/', body , {headers: headers})
  }

  getRouteMatchPatterns(routeId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'routeMatchPatternsList/'+routeId+'/', {headers: headers})
  }

  deleteRouteMatchPattern(id:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.delete(this.API_URL+'routeMatchPatternsDetails/'+id+'/', {headers: headers})
  }

  getInboundDIDList(userProfileId:number,destination,type) {
    console.log(userProfileId)
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'inboundDIDList/'+userProfileId+'/'+destination+'/'+type+'/', {headers: headers})
  }

  searchInboundDIDList(val:string,userProfileId:number,destination) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'inboundDIDList/'+userProfileId+'/'+destination+'/?search='+val, {headers: headers})
  }


getCallerIdList(did_id){
  const headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  return this.http.get(this.API_URL+'get_caller_id/'+did_id+'/', {headers: headers})
}


  getDidName(id: string) {
    const headers = new HttpHeaders();
    return this.http.get(this.API_URL+'getDidName/'+id+'/', {headers: headers})
  }


  searchDIDbById(val:string,userProfileId:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DIDListById/'+userProfileId+'/?search='+val, {headers: headers})
  }

  getDIDListById(userProfileId:number,channel:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'DIDListById/'+userProfileId+'/'+channel+'/', {headers: headers})
  
  }

  fetchOneSipDetail(id:number) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'fetchOneSipDetail/'+id+'/', {headers: headers})
  }

  addBlackList(route:number, user_id: number, blackListName: string, blackListNumber: string){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');    
    const body = {
      "route": route,
      "user": user_id,
      "name": blackListName,
      "number": blackListNumber
    }
    
    return this.http.post(this.API_URL + 'router/blacklist/',body , {headers: headers})

  }

  removeBlackList(id: number){
    const headers = new HttpHeaders
    headers.append('Content-Type', 'application/json');
    return this.http.delete(this.API_URL + 'router/blacklist/' + id + '/')
  }

  addCallerTemplate(data: any) {
    const headers = new HttpHeaders
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + "router/caller-template/", data, {headers: headers})
  }

  listCallerTemplate(id: string) {
    const headers = new HttpHeaders
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("user_id", id)
    return this.http.get(`${this.API_URL}router/caller-template/`, { headers: headers, params: params });
  }

  updateCallerTemplate(data: any) {
    const headers = new HttpHeaders
    headers.append('Content-Type', 'application/json');
    return this.http.put(`${this.API_URL}router/caller-template/${data.id}/`, data, { headers: headers});
  }

  destroyCallerTemplate(id: string) {
    const headers = new HttpHeaders
    headers.append('Content-Type', 'application/json');
    return this.http.delete(`${this.API_URL}router/caller-template/${id}/`, { headers: headers});
  }

  callerFileUpload(form,id) {
    let formData = new FormData();
    formData.append('file', form);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(`${this.API_URL}router/caller-registration/${id}/caller_upload/`, formData, { headers: headers })
  }

  getCallerList(id: string) {
    const headers = new HttpHeaders
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("template_id", id)
    return this.http.get(`${this.API_URL}router/caller-registration/`, { headers: headers, params: params});
  }

  updateCallerRegistration(data:any) {
    const headers = new HttpHeaders
    headers.append('Content-Type', 'application/json');
    return this.http.patch(`${this.API_URL}router/caller-registration/${data.id}/`,data, { headers: headers});
  }

  destroyCallerRegistration(id: string) {
    const headers = new HttpHeaders
    headers.append('Content-Type', 'application/json');
    return this.http.delete(`${this.API_URL}router/caller-registration/${id}/`, { headers: headers});
  } 

  getcallerRegistrationFormat(): Observable<HttpResponse<Blob>>{
        const headers = new HttpHeaders().set("Content-Type", "application/json");
        return this.http.get(this.API_URL + "router/caller-registration/get-caller-registration-format", {responseType: 'blob',observe: 'response'});
  }
  
}


