import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SharedService } from "../global";
import * as generate_password from "generate-password"


@Injectable({
  providedIn: "root",
})
export class UsermanagementService {
  constructor(private http: HttpClient, public sharedService: SharedService) {}

  API_URL = this.sharedService.getGlobalVar();

  getCompanyList() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    console.log("hiii");
    return this.http.get(this.API_URL + "usermanagement/getCompanyList/", {
      headers: headers,
    });
  }

  getChannelIds() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "channel_list/", { headers: headers });
  }

  getUserbyCompanyName(name) {
    const headers = new HttpHeaders();
    const body = { name: name };
    headers.append("Content-Type", "application/json");
    console.log("hiii");
    return this.http.post(
      this.API_URL + "usermanagement/getUserbyCompanyName/",
      body,
      { headers: headers }
    );
  }

  AddCreditData(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    console.log("hiii");
    return this.http.post(
      this.API_URL + "usermanagement/AddCreditData/",
      body,
      { headers: headers }
    );
  }

  DemoAutoCall(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "usermanagement/demoAutocall/", body, {
      headers: headers,
    });
  }

  getDIDs(id) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "usermanagement/getDid/" + id + "/", {
      headers: headers,
    });
  }

  getCreditDataList() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    console.log("hiii");
    return this.http.get(this.API_URL + "usermanagement/getCreditDataList/", {
      headers: headers,
    });
  }

  getExistingCompanyCredit(body) {
    console.log(body);
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/getExistingCompanyCredit/",
      body,
      { headers: headers }
    );
  }

  CreditDelete(id) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    console.log(id);
    return this.http.delete(
      this.API_URL + "usermanagement/CreditDelete/" + id + "/",
      { headers: headers }
    );
  }

  AddChannels(val) {
    const headers = new HttpHeaders();
    const body = { val: val };
    headers.append("Content-Type", "application/json");
    console.log("hiii");
    return this.http.post(this.API_URL + "usermanagement/AddChannels/", body, {
      headers: headers,
    });
  }

  fetchData(userProfileId: number, fromDate, toDate, search, pageNumber) {
    const headers = new HttpHeaders();
    const body = {
      userProfileId: userProfileId,
      toDate: toDate,
      fromDate: fromDate,
      search: search,
    };
    headers.append("Content-Type", "application/json");
    console.log("hiii");
    return this.http.post(
      this.API_URL + "usermanagement/fetchChannelHistory/?page=" + pageNumber,
      body,
      { headers: headers }
    );
  }

  lastChannel() {
    const headers = new HttpHeaders();
    // const body = {"val":val}
    headers.append("Content-Type", "application/json");
    console.log("hiii");
    return this.http.post(this.API_URL + "usermanagement/lastChannel/", {
      headers: headers,
    });
  }

  timeDurationCalc(body) {
    console.log(body);
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/timeDurationCalc/",
      body,
      { headers: headers }
    );
  }

  autoCallSubmit(body) {
    const headers = new HttpHeaders();

    console.log(body);
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "autoDialManagement/autoCallSubmit/",
      body,
      { headers: headers }
    );
  }

  getExistingDidNumber(data) {
    var body = {
      userName: data,
    };
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/getUserDidNumbers/",
      body,
      { headers: headers }
    );
  }
  addPlanDid(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/add_new_credit_plan/",
      body,
      { headers: headers }
    );
  }
  updatePlanDid(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/updateCreditPlan/",
      body,
      { headers: headers }
    );
  }
  ///////////////////// SMS Credit Send //***********************
  addSmsPlanDid(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/add_new_sms_credit_plan/",
      body,
      { headers: headers }
    );
  }

  smsplanDetails(search_text) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL +
        "usermanagement/sms_plan_list_credit/?search=" +
        search_text,
      { headers: headers }
    );
  }

  updateSmsPlanDid(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/smsupdateCreditPlan/",
      body,
      { headers: headers }
    );
  }

  smsdeletePlan(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/delete_sms_credit_plan/",
      body,
      { headers: headers }
    );
  }

  smsdidlist(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/smsgetDidPlan/",
      body,
      { headers: headers }
    );
  }

  smscredit_history(search_text) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL +
        "usermanagement/SMSUpdatedCreditReport/?search=" +
        search_text,
      { headers: headers }
    );
  }

  //////////////////////End SMS Credit //*****************************

  // ---------------------------  UserCredits -------------------------//
  addUserCredit(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/add_new_user_credit/",
      body,
      { headers: headers }
    );
  }

  updateUserCredit(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/update_user_credit/",
      body,
      { headers: headers }
    );
  }

  userCreditList(search_text) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "usermanagement/user_credit_list/?search=" + search_text,
      { headers: headers }
    );
  }

  userCreditDid(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/edit_user_credit/",
      body,
      { headers: headers }
    );
  }

  userCreditDeletePlan(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/delete_user_credit_plan/",
      body,
      { headers: headers }
    );
  }

  usercredit_history(search_text) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL +
        "usermanagement/UserUpdatedCreditReport/?search=" +
        search_text,
      { headers: headers }
    );
  }

  //---------------------------- End UserCredits ----------------------//

  // #######################   Alert   ################### //
  addAlertDid(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/add_new_credit_alert/",
      body,
      { headers: headers }
    );
  }
  updateAlertDid(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/updateCreditAlert/",
      body,
      { headers: headers }
    );
  }
  alertTypeDetails(search_text) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL +
        "usermanagement/alert_type_list_credit/?search=" +
        search_text,
      { headers: headers }
    );
  }
  listAlertType(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/list_alert_type/",
      body,
      { headers: headers }
    );
  }
  updateAlertType(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/update_alert_type/",
      body,
      { headers: headers }
    );
  }
  deleteAlertType(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/delete_alert_type/",
      body,
      { headers: headers }
    );
  }
  alert_type_history(search_text) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "usermanagement/alert_type_history/?search=" + search_text,
      { headers: headers }
    );
  }

  creidit_alert_report(search_text) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL +
        "usermanagement/credit_alert_report/?search=" +
        search_text,
      { headers: headers }
    );
  }
  // ####################### End  Alert  ####################### //

  planDetails(search_text,search_credit) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "usermanagement/plan_list_credit/?search=" + search_text + "&search_credit=" + search_credit,
      { headers: headers }
    );
  }

  deletePlan(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/delete_credit_plan/",
      body,
      { headers: headers }
    );
  }
  didlist(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "usermanagement/getDidPlan/", body, {
      headers: headers,
    });
  }
  CustomerComapnyList() {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "usermanagement/customercompanyname/", {
      headers: headers,
    });
  }
  load_date_agent_report(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/CallAgentResponseDetail/",
      body,
      { headers: headers }
    );
  }
  load_date_agent_report_by_page(body, pageNumber) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL +
        "usermanagement/CallAgentResponseDetail/?page=" +
        pageNumber,
      body,
      { headers: headers }
    );
  }

  load_date_caller_report(body, pageNumber) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "usermanagement/CalleridReport/?page=" + pageNumber,
      body,
      { headers: headers }
    );
  }

  credit_history(pageNumberData, fromDate, toDate, search,) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL +
        "usermanagement/UpdatedCreditReport/?page=" +
        pageNumberData + '&from='+fromDate + '&to='+toDate + '&search='+search ,
      { headers: headers }
    );
  }

  load_auto_call_report(body) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "api/AutoCallReport/", body, {
      headers: headers,
    });
  }

  load_auto_call_report_by_page(body, pageNumber) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    // return this.http.post(this.API_URL+'api/AutoCallReport/?page='+pageNumber,body, {headers:headers});
    return this.http.post(
      this.API_URL + "api/AutoCallReport/?page=" + pageNumber,
      body,
      { headers: headers }
    );
  }

  load_live_call_details(id: number, userType) {
    const headers = new HttpHeaders();
    const body = { usertype: userType };
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "LiveCallList/" + id + "/", body, {
      headers: headers,
    });
    // return this.http.post('https://backend.pbx.bonvoice.com/'+'LiveCallL+ist/'+id+'/',body, {headers:headers})
  }

  generate_password(
    length: number,
    inclNumbers: boolean,
    incSymbols: boolean,
    inclUpperCase: boolean,
    inclLowerCase: boolean,
    exclString: string,
    strict: boolean
  ) {

    const specialChars = [
      '@',
      '%',
      '^',
      '*',
      '(',
      ')',
      '_',
      '+',
      '=',
      '{',
      '}',
      '[',
      ']',
      '|',
      '\\',
      ':',
      ';',
      '"',
      '\'',
      '<',
      '>',
      ',',
      '.',
      '?',
      '/',
      '~',
      '`',
      '-'
    ];

    const allowedChars = ["!", "#", "$", "&"]

    const deniedRegex = new RegExp(`[${specialChars.join('\\')}]`, 'g');
    const allowedRegex = new RegExp(`[${allowedChars.join('\\')}]`);

    let password = generate_password.generate({
      length: length,
      numbers: inclNumbers,
      symbols: incSymbols,
      uppercase: inclUpperCase,
      lowercase: inclLowerCase,
      exclude: exclString,
      strict: strict
    })

    password = password.replace(deniedRegex, '');

    if (!allowedRegex.test(password)) {
      const randomIndex = Math.floor(Math.random() * allowedChars.length);
      const randomChar = allowedChars[randomIndex];
      return password + randomChar;
    } else {
      return password
    }
    
  }

  set_sidebar_toggle_setting(user_id:any, user_type:any, sidebar_state:any){
    const headers = new HttpHeaders();
    const body = { user_id: user_id, user_type: user_type, sidebar_state: sidebar_state };
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "usermanagement/toggle-sidebar/", body, {headers: headers});
  }

  get_sidebar_toggle_setting(user_id:any, user_type:any){
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(this.API_URL + "usermanagement/toggle-sidebar/", {headers: headers, params: {user_id: user_id, user_type: user_type}});
  }


  live_call_monitor(body: any){
    const headers = new HttpHeaders({'Content-Type': 'application/json'})
		return this.http.post(this.API_URL+'live-call-monitoring/', body, {headers:headers})
  }

  add_secondary_mobile(user_id:any, secondary_mobile:any){
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    const body = { user_id: user_id, secondary_mobile: secondary_mobile };
    return this.http.post(this.API_URL + "usermanagement/add-secondary-number/", body, {headers: headers});
  }

  LogoutAllDevices(user_id: any, usertype:any){
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    const body = { id: user_id, usertype: usertype };
    return this.http.post(this.API_URL + "api/logout-all-devices/", body, {headers: headers});
  }

  updateAgentBreakTime(data: any) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "usermanagement/agent_break_status/", data, {headers: headers})
  }
}
