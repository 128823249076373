import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from "../../usermanagement.service";
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-credit-list',
  templateUrl: './credit-list.component.html',
  styleUrls: ['./credit-list.component.css']
})

export class CreditListComponent implements OnInit {
  datas: any;
  public searchText: string;
  public searchCredit: number;
  permissions:any;
  permis = false;
  userData:any; 

  constructor(
    private usermanagementservice:UsermanagementService,
    private loginService: LoginService,
  ) {


  }

  ngOnInit() {
    this.searchText = ''
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions
    for (let element of this.permissions) {
      console.log(element.codename)
      if(element.codename == "cadmin_s_creditList"){
        this.permis = true;
    }
  }
    // this.usermanagementservice.getCreditDataList().subscribe(
    //   (data:any) =>{
    //     console.log("tmep listss",data);
    //     this.datas = data
    //   }
    //  )

  this.loadDid();
  }


  searchBox(){
    console.log(this.searchText)
    this.loadDid();
  }

loadDid(){
  this.usermanagementservice.planDetails(this.searchText,this.searchCredit).subscribe(
    (data:any) =>{
      this.datas = data
    }
   )
}

  // Ondeletecredit(id) {
  //   this.usermanagementservice.CreditDelete(id).subscribe(
  //     (res:any) => {
  //         Swal.fire('Deleted', 'User Credit Successfully Deleted', 'success')
  //         this.usermanagementservice.getCreditDataList().subscribe(
  //           (data:any) => {
  //             this.datas = data;
  //             console.log(data);
  //           }
  //         )
  //       }
  //     )
  //   }

  deletePlan(did,company){
    if(confirm("Are you sure to delete "+name)) {
      console.log("Implement delete functionality here");
      var bodyData={
        'didnumber':did,
        'company':company
      }
      this.usermanagementservice.deletePlan(bodyData).subscribe(
        (data:any) => {
          this.datas = data;
          console.log(data,"mmmmmmm");
          this.loadDid();
        }
      )
    }

  }


}
