import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { UsermanagementService } from '../usermanagement.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/notification/notification.service';

declare var $:any;

@Component({
  selector: '[app-inbound-route-list]',
  templateUrl: './usermanagement-list.component.html',
  styleUrls: ['./usermanagement-list.component.css']
})
export class UserprofileListComponent implements OnInit, AfterViewInit {
  public userData: any;
  public userprofileData:any;
  showClientDetails: boolean = false;

  grp: any;
  dataTable: any;

  constructor(
    private loginservice: LoginService,
    private usermanagementservice:UsermanagementService,
    private router: Router,
    private notifyservice: NotificationService
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.grp = this.userData.group
    
  }

  ngAfterViewInit(): void {
    this.initializeDatatable();
    this.updateDataTable();

  }

  AddUserProfile(){
    localStorage.setItem("customer", null);
    this.router.navigateByUrl('/userdetail/0');
  }

  AddPermissions(){
    localStorage.setItem("customer", null);
    this.router.navigateByUrl('/userperm');
  }

  editUserProfile(id){
    // this.usermanagementservice.getUserProfile(id).subscribe(
    //   (data:any) => {

            this.router.navigateByUrl('/userdetail/'+id);
         
      // }
    // )
  }

  deleteUserProfile(id) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'The UserProfile Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.usermanagementservice.deleteUserProfileData(id).subscribe(
          (res:any) => {
            console.log('data', res)
            Swal.fire('Deleted', 'UserProfile delete successfully', 'success')
            // this.notifyservice.sendNotification('Deleted', 'UserProfile delete successfully', 'is-success')
            if(this.userData.group == 'superadmin'){
              this.usermanagementservice.getUserList(0).subscribe(
                (data:any) => {
                  this.userprofileData = data;
                }
              )
            }else{
              this.usermanagementservice.getUserList(this.userData.id).subscribe(
                (data:any) => {
                  this.userprofileData = data;
                }
              )
            }
          }
        )
      }else{
        this.notifyservice.sendNotification('Cancelled', 'UserProfile deleting has been cancelled', 'is-danger')
      }
    })
  }

  initializeDatatable(){
    this.dataTable = $('#userTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: [],
      columns: [
        { 
          data: null, 
          title: 'Sl. No.', 
          render: (data, type, row, meta) => meta.row + 1  // Adds serial number
        },
        { data: 'name', title: 'Name' },        // { data: 'action', title: 'Action' }

        { data: 'username', title: 'User Name' },
        { data: 'address', title: 'Address' },
        { data: 'mobile', title: 'Phone Number' },
        { data: 'action', title: 'Action' },
        // Add more columns as needed
      ]
    });
  }

  updateDataTable() {

    if(this.userData.group == 'superadmin'){
      this.showClientDetails = true; 
      this.usermanagementservice.getUserList(0).subscribe(
        (data:any) => {    
          this.userprofileData = data.map(item=> ({
            ...item,
            action: `<a id="btn-edit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                     <a id="btn-delete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`
          }));     
          this.dataTable.clear();
          this.dataTable.rows.add(this.userprofileData);
          this.dataTable.draw();     
        }
      )
    }else{
      this.usermanagementservice.getUserList(this.userData.id).subscribe(
        (data:any) => {
          this.userprofileData = data.map(item=> ({
            ...item,
            action: `<a id="btn-edit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                    <a id="btn-delete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`
          })); 
          this.dataTable.clear();
          this.dataTable.rows.add(this.userprofileData);
          this.dataTable.draw();

        }
      )
    }    

    $('#userTable tbody').on('click', '#btn-delete', (event: any) => {
            
      const row = this.dataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.deleteUserProfile(row.id);
      }
    });

    $('#userTable tbody').on('click', '#btn-edit', (event: any) => {
            
      const row = this.dataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.editUserProfile(row.id);
      }
    });

  }
  

}
