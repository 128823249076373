import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ReportService } from "./report.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import * as moment from "moment";
import { API_URL, VOICE_URL } from "src/global";
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ExcelService } from "./excel.service";
import { DatePipe } from "@angular/common";
import { LoginService } from "src/app/login.service";
import { NotificationService } from "src/app/notification/notification.service";
import Swal from "sweetalert2";
import "jspdf-autotable";
import { HttpEventType, HttpEvent } from "@angular/common/http";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import * as saveAs from "file-saver";
import { ReportSettingsService } from "../report-settings/report-settings.service";

@Component({
  selector: "app-inbound-report",
  templateUrl: "./inbound-report.component.html",
  styleUrls: ["./inbound-report.component.css"],
  providers: [DatePipe],
})
export class InboundReportComponent implements OnInit {
  @ViewChild('tableContainer') tableContainer!: ElementRef;

  private _value: number = 0;
  public cadmin_q_QueueTransfer = false;
  public cadmin_w_callTransfer = false
  get value(): number {
    return this._value;
  }

  set value(value: number) {
    if (!isNaN(value) && value <= 100) {
      this._value = value;
    }
  }

  countryIcons = {
    '91': 'fa-flag-in', 
    '1': 'fa-flag-us',
  };



  InbountReportData: any = new Array();
  wordarray: any = new Array();
  current_page: number = 1;
  total_page: any;
  last: number;
  fromdate: string = "";
  todate: string = "";
  goPage: number;
  searchText: string = "";
  is_pbx: boolean = false;
  userData: any;
  callStatus: string = "";
  myDate = new Date();
  today = new Date(this.myDate.setDate(this.myDate.getDate()));
  today_informat = this.datePipe.transform(this.today, "yyyy-MM-dd");
  ExcelPdfReportData: any = new Array();
  api=API_URL;
  voice_url = VOICE_URL;
  permissions: any;
  permis = false;
  isShownprogressbar = false;
  progress: number = 0;
  public id: any = 0;
  userType: any;
  mobile_number: any;
  isExpanded: false
  templates: any;


  
  // SettingsForColumnVisibility
  isDropdownActive = false;
  reportName : string = ''
  userId: number;
  userProfile: any;
  hiddenColumns: number[] = [];
  savedColumnVisibilityId: number = 0;
  tempHiddenColumns: number[] = [];

  // Settingsforcomment
  commentopen: boolean = false;
  customsmsopen: boolean = false;
  comment: any;
  call_id: any;
  callRecors_id:any;
  tagList: any = new Array()
  tag_filter: string = ""
  showTagDiv: boolean = false
  customer_number: string;

  columns = [
    { id: 1, label: 'DID' },
    { id: 2, label: 'CUSTOMER' },
    { id: 3, label: 'COUNTRY CODE' },
    { id: 4, label: 'DATE' },
    { id: 5, label: 'AGENT' },
    { id: 6, label: 'DESTINATION NAME' },
    { id: 7, label: 'DTMF' },
    { id: 8, label: 'DURATION' },
    { id: 9, label: 'CONNECTED DURATION' },
    { id: 10, label: 'RATING'},
    { id: 11, label: 'COMMENT' },
    { id: 12, label: 'TAG' },
    { id: 13, label: 'STATUS' }
  ];
  selectedColumns: number[] = this.columns.map(column => column.id);

  
  defaultDateFrom: Date;
  defaultDateTo: Date;

  constructor(
    private reportservice: ReportService,
    private excelService: ExcelService,
    private datePipe: DatePipe,
    private loginservice: LoginService,
    private notifyService: NotificationService,
    private reportsettingservice: ReportSettingsService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {}

  ngOnInit() {

    this.fetchSavedVisibility()
    if (this.api == "//backend.pbx.bonvoice.com/") {
      this.is_pbx = true;
    }

    const defaultDate = new Date();
    this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);

    this.userData = this.loginservice.getUserdata(true);
    this.permissions = this.userData.permissions;
    this.userType = localStorage.getItem("userType");

    for (let element of this.permissions) {
      if (element.codename == "cadmin_o_inboundreport") {
        this.permis = true;
      }

      if (element.codename == "cadmin_q_QueueTransfer") {
        this.cadmin_q_QueueTransfer = true;
      }
      if (element.codename == "cadmin_w_callTransfer"){
        this.cadmin_w_callTransfer = true
      }
    }
    this.dropDownListVisibiltyControl();
    this.getCallTags()
    this.FetchInboundReportData(1, false);
    document.addEventListener('click', this.handleOutsideClick.bind(this));
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.handleOutsideClick.bind(this));
  }

  onFromDateChange(newValue: any) {
    if (newValue && newValue !== "") {
      this.defaultDateFrom = new Date(this.datePipe.transform(newValue, "yyyy-MM-dd HH:mm:ss"));
    } else {
      const defaultDate = new Date();
      this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    }
  }
  onToDateChange(newValue: any) {
    if (newValue && newValue !== "") {
      this.defaultDateTo = new Date(this.datePipe.transform(newValue, "yyyy-MM-dd HH:mm:ss"));
    } else {
      const defaultDate = new Date();
      this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);
    }
  }

  
  getRecordId(data: any): string {
    // Check if callRecVoiceFile is empty, null, or undefined
    if (!data.callRecordVF || data.callRecordVF.trim() === "") {
        // If empty, null, or undefined, use data.id
        return data.id;
    } else {
        return data.callRecordVF;
    }
}


  // FetchInboundReportData(pageNo) {
  //   var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss");
  //   var to = this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss");

  //   this.spinnerService.show();
  //   this.reportservice
  //     .getInboundReport(
  //       this.userData.id,
  //       from,
  //       to,
  //       this.searchText,
  //       this.callStatus,
  //       pageNo,
  //       "",
  //       this.userType,
  //       this.tag_filter
  //     )
  //     .subscribe((res: any) => {
  //       this.InbountReportData = res["results"];
  //       this.total_page = res["total_pages"];
  //       this.current_page = res["current_page"];
  //       this.last = res["total_pages"];
  //       this.spinnerService.hide();
  //     });
  // }

  FetchInboundReportData(pageNo, validate = true) {
    // Perform validation only when `validate` is true
    if (validate) {
      if (!this.fromdate && this.todate) {
        this.spinnerService.hide();
        alert("From date is required.");
        return;
      }
  
      if (this.fromdate && this.todate) {
        const fromDate = new Date(this.fromdate);
        const toDate = new Date(this.todate);
  
        // Validate that `fromdate` is not greater than `todate`
        if (fromDate > toDate) {
          this.spinnerService.hide();
          alert("From date cannot be greater than To date.");
          return;
        }
      }
    }
  
    // Transform string dates for the API call
    const from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss");
    const to = this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss");
  
    // Show spinner and fetch data
    this.spinnerService.show();
    this.reportservice
      .getInboundReport(
        this.userData.id,
        from,
        to,
        this.searchText,
        this.callStatus,
        pageNo,
        "",
        this.userType,
        this.tag_filter
      )
      .subscribe(
        (res: any) => {
          this.InbountReportData = res["results"];
          this.total_page = res["total_pages"];
          this.current_page = res["current_page"];
          this.last = res["total_pages"];
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
          console.error("Error fetching report data:", error);
        }
      );
  }
  

  searchBox() {
    this.FetchInboundReportData(1,true);
  }



  getcallrecord(vffile, date, call_id, file_type = "") {
 
    this.reportservice.getcallrecord(vffile, date,call_id, file_type).subscribe(
      (res: any) => {

          let voice_url = this.api + "uploads/downloadedWaveFile/" + vffile.replace(".gsm", ".wav");
          var html_con = '<audio id="audio1" preload controls style="width: 270px; height: 50px; max-width: none; margin: 10px;">' + "  <source src=" + voice_url + " </audio>";
          document.getElementById("audio_" + vffile).innerHTML = html_con;

        this.notifyService.sendNotification(
          "Downloaded",
          "Download Voice successfully",
          "is-success"
        )
      }
    )
  }

  exportAsXLSX(): void {
    if (!this.fromdate && this.todate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.fromdate && this.todate) {
      const fromDate = new Date(this.fromdate);
      const toDate = new Date(this.todate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }
    var text;
    var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss");
    var to = this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss");

    this.reportservice
      .getInboundReport(
        this.userData.id,
        from,
        to,
        this.searchText,
        this.callStatus,
        this.current_page,
        "yes",
        this.userType,
        this.tag_filter
      )
      .subscribe((res: any) => {
        this.ExcelPdfReportData = res["data"];
        for (let element of this.ExcelPdfReportData) {
          if (element["destinationType"] == "6") {
            var excel_destination_name = "AUDIO";
          } else {
            excel_destination_name = element["queueName"];
          }

          var objz = {
            DID: element["DID"],
            Customer: element["callerid"],
            "countrycode":element["country_code"],
            "Customer Name": element["customer"]["name"] || "",
            Date: element["legAstartTime"],
            "Agent Number": element["answeredAgent"],
            "Agent Name": element["agentName"],
            "Destination Name": excel_destination_name,
            DTMF: element["DTMS"],
            Duration: element["duration"],
            "Connected Duration": element["connectedDuration"],
            Comment: element["comment"],
            Tags: element["tag"],
            Status: element["callStatus"],
          };

          this.wordarray.push(objz);
        }
        if ((this.fromdate, this.todate)) {
          text = "Inbound Report from: " + from + " To: " + to + "";
        } else {
          text = "Inbound Report of " + this.today_informat;
        }
        this.excelService.exportAsExcelFile(this.wordarray, text);
        this.wordarray = [];
      });
  }

  downloads() {
    if (!this.fromdate && this.todate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.fromdate && this.todate) {
      const fromDate = new Date(this.fromdate);
      const toDate = new Date(this.todate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }
    var countSlNo = 1;
    var newarray = [];
    var head = [
      [
        "SlNo",
        "DID",
        "Customer",
        "Country code",
        "Customer Name",
        "Date",
        "Agent",
        "Agent Name",
        "Department Name",
        "Call Duration",
        "DTMF",
        "Connected Duration",
        "comment",
        "Tags",
        "Status",
      ],
    ];
    
    var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss");
    var to = this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss");
    this.reportservice
      .getInboundReport(
        this.userData.id,
        from,
        to,
        this.searchText,
        this.callStatus,
        this.current_page,
        "yes",
        this.userType,
        this.tag_filter
      )
      .subscribe((res: any) => {
        this.ExcelPdfReportData = res["data"];
        for (let element of this.ExcelPdfReportData) {
          newarray.push([
            countSlNo,
            element["DID"],
            element["callerid"],
            element["country_code"],
            element["customer"]["name"] || "",
            element["legAstartTime"],
            element["answeredAgent"],
            element["agentName"],
            element["queueName"],
            element["duration"],
            element["DTMS"],
            element["connectedDuration"],
            element["comment"],
            element["tag"],
            element["callStatus"],
          ]);
          countSlNo = countSlNo + 1;
        }
        var pdf = new jsPDF();
        pdf.text("Inbound Report", 11, 8);
        pdf.setFontSize(3);
        pdf.setTextColor(100);
        (pdf as any).autoTable({
          head: head,
          body: newarray,
          theme: "grid",
          styles: { fontSize: 4 },
          tableWidth: "auto",
          columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 10 },
        });
        pdf.output("dataurlnewwindow");
        pdf.save("Inboubdreport" + from + "_to_" + to + ".pdf");
      });
  }

  fetchNumber(i) {
    if (i == "prev") {
      if (this.current_page > 1) {
        this.FetchInboundReportData(this.current_page - 1, true);
      }
    } else if (i == "next") {
      if (this.current_page < this.last) {
        this.FetchInboundReportData(this.current_page + 1, true);
      }
    } else if (i == "first") {
      this.FetchInboundReportData(1, true);
    } else if (i == "last") {
      this.FetchInboundReportData(this.last, true);
    } else {
      if (parseInt(i) === parseInt(i, 10) && i > 0 && i <= this.last) {
        this.FetchInboundReportData(i, true);
      } else {
        Swal.fire("Oops..!", "Enter Valid Number", "warning");
      }
    }
  }

  DownloadallVoice() {
    if (!this.fromdate && this.todate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.fromdate && this.todate) {
      const fromDate = new Date(this.fromdate);
      const toDate = new Date(this.todate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }
    var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd HH:mm:ss");
    var to = this.datePipe.transform(this.todate, "yyyy-MM-dd HH:mm:ss");
    this.reportservice
      .getInboundReport(
        this.userData.id,
        from,
        to,
        this.searchText,
        this.callStatus,
        this.current_page,
        "voice_download_yes",
        this.userType,
        this.tag_filter
      )
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.isShownprogressbar = true;
            console.log("Request has been made!");
            break;
          case HttpEventType.ResponseHeader:
            console.log("Response header has been received!");
            break;
          case HttpEventType.DownloadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            console.log("User successfully created!", event.body);
            var a = document.createElement("a");
            var blob = new Blob([event.body], { type: "application/zip" });
            a.href = URL.createObjectURL(blob);
            if (from && to) {
              a.download = from + "_" + to + ".zip";
            } else {
              a.download = "INBOUND_" + this.today_informat + ".zip";
            }
            a.click();
            setTimeout(() => {
              this.progress = 0;
              this.isShownprogressbar = false;
            }, 1500);
        }
      });
  }

  onDownload(): void {
    this.id = 1;
    let filename = null;
    this.reportservice.download().subscribe((event) => {
      console.log(event["loaded"]);
      console.log(event["total"]);

      if (event["loaded"] && event["total"]) {
        this.value = Math.round((event["loaded"] / event["total"]) * 100);
        console.log(this.value);

        // if (this.value==100){
        //   // this.id=0;
        // }
      }

      if (event["body"]) {
        saveAs(event["body"], filename);
      }
    });
    // this.id=0;
  }


  CloseComment() {
    this.commentopen = false;
    this.customsmsopen = false;
    this.call_id = "";
    this.id = "";
    this.comment = "";
    this.callRecors_id = "";
  }

  audioFileErrors: boolean[] = []
  downloadEnabled: boolean[] = []

  handleAudioError(audioFileName: string) {
    this.audioFileErrors[audioFileName] = true
    this.downloadEnabled[audioFileName] = true
  }

  disableVoiceDownload(audioFileName: string){
    this.downloadEnabled[audioFileName] = false
  }

  addCallRecordTag(call_record_id: any, index:any){
    this.reportsettingservice.getReportTagChild(call_record_id).subscribe((res:any) => {
      const updatedTags = res.tag.split(',').map(tag => tag.trim());
      this.InbountReportData[index].tag = updatedTags.join(', ');  
    })
  }

  deleteCallRecordTag(call_record_id:any, tag: any, index:any){
    const body = {
      tag: tag
    }

    this.reportsettingservice.deleteReportTagChild(call_record_id, body).subscribe((res:any) => {
      this.InbountReportData[index].tag = res.tags.tag
    })
  }

  updateReportPage(){
    this.FetchInboundReportData(this.current_page, true)
  }

  getCallTags(){
    let user_id:any
    if(this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser') {
      user_id = this.userData.admin;
    } else {
      user_id = this.userData.id;
    }

    this.reportsettingservice.getReportTagMain(user_id).subscribe((res:any) => {
      this.tagList = res.report_tags
    })
  }
  //  Tabel scrolling
  scrollTable(direction: 'left' | 'right'): void {
    const scrollAmount = 100;
    const container = this.tableContainer.nativeElement;

    if (direction === 'left') {
      container.scrollLeft -= scrollAmount;
    } else if (direction === 'right') {
      container.scrollLeft += scrollAmount;
    }
  }

  // Dropdown Toggle
  toggleDropdown() {
    this.isDropdownActive = !this.isDropdownActive;

    if (this.isDropdownActive) {
      this.tempHiddenColumns = [...this.hiddenColumns];
    } else {
      this.tempHiddenColumns = [...this.hiddenColumns];
    } 
  }
  
  handleOutsideClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
  
    if (!target.closest('.columnVisibilitybtn') && !target.closest('.dropdown-menu')) {
      this.isDropdownActive = false;
    }
  }
  
  // Select All
  toggleSelectAll(event: Event): void {
    const checkbox = event.target as HTMLInputElement;

    if (checkbox.checked) {
      this.tempHiddenColumns = [];
      this.selectedColumns = this.columns.map(column => column.id);
    } else {
      this.tempHiddenColumns = this.columns.map(column => column.id);
      this.selectedColumns = [];
    }
  }

  areAllColumnsSelected(): boolean {
    return this.tempHiddenColumns.length === 0;  
  }

  onColumnSelectionChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    const value = parseInt(checkbox.value, 10);

    if (!checkbox.checked) {
      if (!this.tempHiddenColumns.includes(value)) {
        this.tempHiddenColumns.push(value);
      }
      this.selectedColumns = this.selectedColumns.filter((col) => col !== value);
    } else {
      this.tempHiddenColumns = this.tempHiddenColumns.filter((col) => col !== value);
    }
  }

  isColumnHidden(columnId: number): boolean {
    return this.hiddenColumns.includes(columnId);
  }

  onCreateColumnVisibility(columns: string): void {
      this.userProfile = JSON.parse(localStorage.getItem("userdata"))
      this.userData = this.loginservice.getUserdata(true);
      const loggedInUserId = this.userData.id;
      const userType = localStorage.getItem('userType')
      this.reportName = 'InboundReport'
      this.spinnerService.show();

      const columnVisibility_Data = {
            "id":this.savedColumnVisibilityId || 0,
            "user_id":loggedInUserId,
            "user_type":userType,
            "report":"InboundReport",
            "columns": this.tempHiddenColumns

      }

    this.reportservice.createColumnVisibility(columnVisibility_Data).subscribe((response: any) => {
          if(response.status === 1){
            this.spinnerService.hide();
            this.hiddenColumns = [...this.tempHiddenColumns];
            this.isDropdownActive = false;
            this.fetchSavedVisibility();
          }else{
            this.spinnerService.hide();
          }
        }
      );
  }

  fetchSavedVisibility(): void {
    this.userProfile = JSON.parse(localStorage.getItem("userdata"))
    this.userData = this.loginservice.getUserdata(true);
    const loggedInUserId = this.userData.id;
    this.userId = loggedInUserId
    this.reportName = "InboundReport"

    this.reportservice.getColumnVisibility(this.userId, this.reportName).subscribe(
        (response: any) => {
          if (response.status === 1) {
            this.hiddenColumns = response.data.columns || [];
            this.savedColumnVisibilityId = response.data.id || 0;
            this.tempHiddenColumns = [...this.hiddenColumns];
            this.selectedColumns = this.columns
            .map(column => column.id)
            .filter(id => !this.hiddenColumns.includes(id));
          }
        },
      );
  }

  dropDownListVisibiltyControl(){
    // Conditionally showing these columns in dropdown list
    if (this.cadmin_q_QueueTransfer){
      this.columns.push(
        { id: 13, label: 'TRANSFERRED DEPARTMENT' }        
      )
    }
    
    if (this.cadmin_q_QueueTransfer || this.cadmin_w_callTransfer) {
      this.columns.push(
        { id: 14, label: 'TRANSFER ANSWERED AGENT' },
        { id: 15, label: 'TRANSFER START TIME' },
        { id: 16, label: 'TRANSFERRED CALL STATUS' }
      );
    }

    this.columns.push(
      { id: 17, label: 'PLAY/PAUSE' },        
      { id: 18, label: 'CALL SUMMARY' }        
    );
  }

}
