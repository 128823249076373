import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SharedService } from "src/global";

@Injectable({
    providedIn: 'root'
})
export class TwoFactorAuthComponentService {
    private toggleState = new BehaviorSubject<boolean>(false);
    private purposeSubject = new BehaviorSubject<number | null>(null);
    public purpose$ = this.purposeSubject.asObservable();
    private otpObjectId: number;
    private isLoginFlow: boolean = true;
    constructor(private http: HttpClient, public sharedService: SharedService,
    ) { }

    setPurpose(purpose: number) {
        this.purposeSubject.next(purpose);
    }

    getPurpose() {
        return this.purposeSubject.getValue();
    }

    setOtpObjectId(id: number) {
        this.otpObjectId = id;
    }

    getOtpObjectId(): number {
        return this.otpObjectId;
    }

    setToggleState(state: boolean): void {
        this.toggleState.next(state);
    }

    getToggleState(): Observable<boolean> {
        return this.toggleState.asObservable();
    }


    API_URL = this.sharedService.getGlobalVar();

    sendOtp(body) {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.post(
            this.API_URL + "usermanagement/twofactor/send_otp/", body, { headers: headers }
        );
    }

    verifyOtp(body) {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.post(
            this.API_URL + "usermanagement/twofactor/verify_otp/", body, { headers: headers }
        );
    }

    resendOtp(body) {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.post(
            this.API_URL + "usermanagement/twofactor/resend_otp/", body, { headers: headers }
        );
    }

}