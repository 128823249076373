import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { TwoFactorAuthComponentService } from './two-factor-auth.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.css']
})
export class TwoFactorAuthComponent implements OnInit {

  countdown: number;
  showLinks: boolean = false;
  mainCountdown: number;
  userId: number;
  userType: any;
  userData: any;
  passWord: string = '';
  EnterOtp: string = '';
  UserPHoneNumber: string = '';
  twoFA_data: any;
  response_2FA_data: any;
  auth_user_id: string = '';
  maskedPhone: string = '';
  purpose: number | null = null;
  sendOtpPurpose: any;
  twoFaStatus: any;
  otpTrials: number;
  showMessage: boolean = false;
  showErrorMessage: boolean = false
  timerInterval: any;
  logout_data: any;
  backupResponse2FA: any;
  tempData: any;
  response2FA_data: any;
  isLoginFlow: boolean = true;
  maximumCountDown: number;
  errormessage: string;
  componentLoadTime: string;

  constructor(private router: Router, private loginservice: LoginService, private twoFactorAuthService: TwoFactorAuthComponentService) { }

  ngOnInit() {
    // component loading time
    const now = new Date();
    this.componentLoadTime = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;

    this.userData = this.loginservice.getUserdata(true);
    this.UserPHoneNumber = this.userData ? this.userData.phone : '';
    this.twoFA_data = this.userData ? this.userData.data_2FA : '';
    this.twoFactorAuthService.purpose$.subscribe((purpose) => {
      this.purpose = purpose;
    });
    this.startTimer();
    this.mainTimer();
    // masking phone number
    this.maskedPhone = this.maskPhoneNumber(this.UserPHoneNumber);
  }

  ngOnDestroy() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  maskPhoneNumber(phone: string): string {
    if (!phone || phone.length < 10) {
      return phone;
    }

    // Extract the last 10 digits
    const lastTenDigits = phone.slice(-10);

    // Keep the first two digits, mask the middle part, and show the last three digits
    return lastTenDigits.slice(0, 2) + '******' + lastTenDigits.slice(-3);
  }

  startTimer(): void {
    this.countdown = this.userData ? this.userData.data_2FA.resend_time_limit : '';
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    const timerInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(timerInterval);
        this.showLinks = true;
      }
    }, 1000);
  }

  // mainTimer(): void {
  //   this.maximumCountDown = this.userData ? this.userData.data_2FA.max_time_limit : '';
  //   this.mainCountdown = this.maximumCountDown * 60;

  //   const timerInterval = setInterval(() => {
  //     if (this.mainCountdown > 0) {
  //       this.mainCountdown--;
  //     } else {
  //       clearInterval(timerInterval);
  //       this.completeLogout();
  //     }
  //   }, 1000);
  // }

  mainTimer(): void {
    this.maximumCountDown = this.userData ? this.userData.data_2FA.max_time_limit : '';
    this.mainCountdown = this.maximumCountDown * 60;

    const startTime = Date.now();
    const endTime = startTime + this.mainCountdown * 1000;

    const timerInterval = setInterval(() => {
      const currentTime = Date.now();
      const timeLeft = Math.max(0, Math.floor((endTime - currentTime) / 1000));

      this.mainCountdown = timeLeft;

      // if (timeLeft <= 0) {
      //   clearInterval(timerInterval);
      //   this.completeLogout();
      // }

      if (timeLeft <= 0) {
        clearInterval(timerInterval);
        const userData = JSON.parse(localStorage.getItem('userdata') || '{}');
        const purpose = +localStorage.getItem('purpose');

        if (purpose === 2) {
          this.router.navigate(['/home2']);
          const is2FAEnabled = Boolean(userData.data_2FA.is_2FA_enabled);
          this.twoFactorAuthService.setToggleState(is2FAEnabled);
        } else {
          this.completeLogout();
        }
      }
    }, 1000);
  }

  get minutes(): string {
    return Math.floor(this.mainCountdown / 60).toString().padStart(2, '0');
  }

  get seconds(): string {
    return (this.mainCountdown % 60).toString().padStart(2, '0');
  }

  onSendOtp() {
    const userId = +localStorage.getItem('auth_user_id')

    const sendOtp_data = {
      "action": "send_otp",
      "user_id": userId,
      "purpose": 2
    };
    this.twoFactorAuthService.purpose$.subscribe((purpose) => {
      this.purpose = purpose;
    });
    this.purpose = sendOtp_data.purpose;
    this.twoFactorAuthService.sendOtp(sendOtp_data).subscribe((response: any) => {
      if (response.status === 1 && sendOtp_data.purpose === 2) {
      } else {
        console.error('Error:', response.message);
      }
    });
  }

  onVerifyOtp() {

    const purpose = +localStorage.getItem('purpose');

    if (!this.EnterOtp) {
      Swal.fire("Error", "Please enter the OTP.", "error");
      return;
    }
    if (purpose === 2 && !this.passWord) {
      Swal.fire("Error", "Please enter the password.", "error");
      return;
    }
    this.userData = this.loginservice.getUserdata(true);
    this.twoFA_data = this.userData ? this.userData.data_2FA : '';
    this.response_2FA_data = this.userData ? this.userData.data_2FA.response_2FA_data.data.otp_object_id : '';
    this.otpTrials = this.userData ? this.userData.data_2FA.max_attempts : '';

    const verifyOtp_data = {
      "action": "verify_otp",
      "id": this.userData.data_2FA.response_2FA_data.data.otp_object_id,
      "otp": this.EnterOtp,
      "timer_data": this.componentLoadTime,
      "password": this.passWord
    };

    this.twoFactorAuthService.verifyOtp(verifyOtp_data).subscribe((response: any) => {
      if (response.status === 1) {
        const userDataKey = 'userdata';
        const storedData = localStorage.getItem(userDataKey);

        if (storedData) {
          const userData = JSON.parse(storedData);
          if (userData.data_2FA && userData.data_2FA.response_2FA_data.data) {
            userData.data_2FA.response_2FA_data.data = null;

            if (purpose === 2) {
              // Toggle is_2FA_enabled based on current value
              const is2FAEnabled = userData.data_2FA.is_2FA_enabled;
              userData.data_2FA.is_2FA_enabled = !is2FAEnabled;
            }
            // Save the updated data back to local storage
            localStorage.setItem(userDataKey, JSON.stringify(userData));
          }
        }

        // Redirect based on purpose
        if (purpose === 1) {
          this.router.navigate(['/home2']); // Login flow
        } else if (purpose === 2) {
          Swal.fire("Success", response.message, "success");
        }
        localStorage.setItem("sidebar_visibility", "true");
        this.router.navigate(['/home2']);
      } else {
        if (response.status === 2 || response.status === 0) {
          this.showErrorMessage = true;
          this.errormessage = response.message
          const attemptsUsed = response.data && response.data.attempts ? response.data.attempts : 0;
          this.otpTrials = this.userData.data_2FA.max_attempts - attemptsUsed;
          this.showMessage = true;
          if (this.otpTrials === 0) {
            if (purpose === 2) {
              Swal.fire("Error", "Maximum OTP attempts exceeded. Redirecting to home.", "error").then(() => {
                this.router.navigate(['/home2']);
                const userData = JSON.parse(localStorage.getItem('userdata') || '{}');
                const is2FAEnabled = Boolean(userData.data_2FA.is_2FA_enabled);
                this.twoFactorAuthService.setToggleState(is2FAEnabled);
              });
            } else {
              Swal.fire("Error", "Maximum OTP attempts exceeded. Redirecting to login.", "error").then(() => {
                this.completeLogout();
              });
            }
          }

        }
      }
    });
  }

  completeLogout() {
    localStorage.removeItem('fcm_token');
    window.localStorage.clear();
    window.location.reload();

    this.logout_data = {
      username: this.userData.name,
      user_type: this.userType,
    };
    this.loginservice.doLogout(this.logout_data).subscribe((datas: any) => { this.router.navigate(['/']); });
  }

  onCloseTwoFA(): void {
    const purpose = +(localStorage.getItem('purpose') || 0);
    const userData = JSON.parse(localStorage.getItem('userdata') || '{}');

    if (purpose === 2) {
      const is2FAEnabled = userData.data_2FA.is_2FA_enabled;
      this.twoFactorAuthService.setToggleState(is2FAEnabled);
      this.router.navigate(['/home2']);
    } else {
      this.completeLogout();
    }
  }

  onResendOtp(media: number) {
    this.response_2FA_data = this.userData ? this.userData.data_2FA.response_2FA_data.data.otp_object_id : '';
    const resendOtp_data = {
      "action": "resend_otp",
      "id": this.userData.data_2FA.response_2FA_data.data.otp_object_id,
      "media": media
    };
    this.twoFactorAuthService.resendOtp(resendOtp_data).subscribe((response: any) => {
      if (response.status === 1) {
        this.EnterOtp = '';
        this.showMessage = false;
        this.showErrorMessage = false;
      } else {
        if (response.status === 2) {
          Swal.fire("Error", response.message, "error").then(() => {
            this.completeLogout();
          });
        }
        console.error('Error:', response.message);
      }
      this.showLinks = false;
      this.startTimer();
    });
  }

}
