import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SharedService } from './../../global';
import { forEach } from 'jszip';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(
    private http: HttpClient,
    public sharedService: SharedService,

  ) { }

  API_URL = this.sharedService.getGlobalVar()

  AddSms(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + 'smsrecords/addSms/', body, { headers: headers });
  }

  getSms(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    console.log(id);
    return this.http.get(this.API_URL + 'smsrecords/smsListorCreate/' + id + '/', { headers: headers });
  }


  TemplateList(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    console.log(id)
    return this.http.get(this.API_URL + 'smsrecords/templateListorCreate/' + id + '/', { headers: headers });
  }

  getCustomTemplateList(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + 'smsrecords/getCustomTemplates/',body, { headers: headers });
  }

  getOptions(id, report) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    console.log(id)
    return this.http.get(this.API_URL + 'smsrecords/getOptions/' + report + '/' + id + '/', { headers: headers });
  }

  AddTemplate(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    console.log("bodyyyy", body);
    return this.http.post(this.API_URL + 'smsrecords/templateListorCreate/0/', body, { headers: headers });
  }

  templateupdateDetail(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL + 'smsrecords/templateupdateDetails/' + id + '/', { headers: headers })
  }

  TemplateUpdate(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + 'smsrecords/TemplateUpdate/0/', body, { headers: headers })
  }

  deleteTemplateData(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    console.log("========", id, "++++++++++")
    return this.http.delete(this.API_URL + 'smsrecords/templateDetail/' + id + '/', { headers: headers })
  }

  OndeleteSms(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.delete(this.API_URL + 'smsrecords/smsDetail/' + id + '/', { headers: headers })
  }

  OneditSms(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    console.log("idddddd", id)
    return this.http.get(this.API_URL + 'smsrecords/smsDetail/' + id + '/', { headers: headers })
  }

  getReport(id) {
    console.log(id)
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL + 'smsrecords/reportList/' + id + '/', { headers: headers });
  }

  getCount(id) {
    console.log(id)
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL + 'smsrecords/getCount/' + id + '/', { headers: headers });
  }


  ActionList(id) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    console.log(id)
    return this.http.get(this.API_URL + 'smsrecords/ActionList/' + id + '/', { headers: headers });
  }


  load_sms_report_by_page(body, pageNumber) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + 'smsrecords/SmsReport/?page=' + pageNumber, body, { headers: headers });
  }

  load_sms_report_without_page(body, pageNumber) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + 'smsrecords/SmsReportWithoutPagination/?page=' + pageNumber, body, { headers: headers });
  }

  load_sms_count_by_page(body, pageNumber) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + 'smsrecords/SmsCount/?page=' + pageNumber, body, { headers: headers });
  }



  getSmsConfigOfUser(UserID) {
    const headers = new HttpHeaders();
    var body = { "userId": UserID }
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + 'smsrecords/smsTypes/', body, { headers: headers });

  }

  addIVRTemplate(data: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + 'smsrecords/ivr-templates/', data, { headers: headers });

  }

  getIVRTemplateList(qParams: any[]) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
    qParams.forEach(param => {
      params = params.set(param.key, param.value)
    })
    return this.http.get(this.API_URL + "smsrecords/ivr-templates/", { headers: headers, params: params })
  }

  updateIVRTemplate(data: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put(this.API_URL + `smsrecords/ivr-templates/${data.id}/`, data, { headers: headers })
  }

  deleteIVRTemplate(id: string) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.delete(this.API_URL + `smsrecords/ivr-templates/${id}/`, { headers: headers })
  }

  getIVRList(user_id: string) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL + `ivrList/${user_id}/`, { headers: headers })
  }

  sendSms(data: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + 'smsrecords/send_sms/', data, { headers: headers });
  }
}
