import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { tick } from '@angular/core/testing';

@Component({
  selector: 'app-message-details',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.css']
})
export class MessageDetailsComponent implements OnInit {
  @Input() public smsopen: Boolean;
  @Input() public Message: string;
  @Input() public recipient: string;
  @Input() public id: string;
  @Output() public CloseComment = new EventEmitter<void>();
  constructor() { }

  ngOnInit() {
    this.Message = this.Message
  }

  close() {
    this.CloseComment.emit();
    // this.Message = ""
    // this.recipient = ""
  }

}
