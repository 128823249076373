import { Injectable } from "@angular/core";
import { SharedService } from "src/global";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})

export class bitrixagents{

    constructor(
        private sharedService: SharedService,
        private http: HttpClient
    ) { }
    
    API_URL = this.sharedService.getGlobalVar();
    headers = new HttpHeaders().set("Content-Type", "application/json")
    

    addBitrixagents(data: any) {
        return this.http.post(this.API_URL + "router/bitrixagent/",data,{headers:this.headers})
    }


    deletegagent(id:number) {
        return this.http.delete(this.API_URL + `router/bitrixagent/${id}/`,{headers:this.headers})
    }

    fetchAgentorDelete(id: number, action) {
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.post(
            this.API_URL + "fetchAgentorDelete/",
            { id: id, action: action },
            { headers: headers }
        );
    }
    getAgentUsers(id) {
        const headers = new HttpHeaders();
        const body = { userId: id };
        headers.append("Content-Type", "application/json");
        return this.http.post(
            this.API_URL + "getBtxAgentUsers/",
            body,
            { headers: headers }
        );
    }
    getbitrixCRM(id) {
        const headers = new HttpHeaders();
        const body = { userId: id };
        headers.append("Content-Type", "application/json");
        return this.http.post(
            this.API_URL + "getBtxAccounts/",
            body,
            { headers: headers }
        );
    }



    ExistAgent(user, name, num) {
        const body = { name: name, num: num, user: user };
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.post(this.API_URL + "exisBitrixtagent/", body, {
            headers: headers,
        });
    }
    EditAgentUsers(
        name,
        num,
        id,
        email,
        bitrixUserId,
        enabled,
    ) {
        console.log("EditAgentUsers>>>>>>>>>>>>>")
        const headers = new HttpHeaders();
        const body = {
            name: name,
            num: num,
            id: id,
            email: email,
            bitrix_user_id: bitrixUserId,
            enabled: enabled,
        };
        console.log("body", body)

        headers.append("Content-Type", "application/json");
        return this.http.post(
            this.API_URL + "editBitrixAgentUsers/",
            body,
            { headers: headers }
        );
    }
}