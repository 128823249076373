import { Component, OnInit } from '@angular/core';
import { VoiceBoatService } from './voiceboat.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/login.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-voiceboat-template',
  templateUrl: './voiceboat-template.component.html',
  styleUrls: ['./voiceboat-template.component.css']
})
export class VoiceboatTemplateComponent implements OnInit {

  templateForm: FormGroup;
  providerList: any[] = [];
  templateId: string | null = null;
  customerValueInitial: string = "";
  userData: any;
  userListReadOnly: boolean = false;
  customerInitial: string = "";
  customerId: string | null = null
  showModel: boolean = false;
  modelHeader: string = "Create new template";
  dataTable: any;
  templateList: any[] = [];
  templateNameExists: boolean = false;

  constructor(
    private fb: FormBuilder,
    private vbSerivce: VoiceBoatService,
    private loginService: LoginService
  ) {
    this.templateForm = this.fb.group({
      template_name: ["", Validators.required],
      provider: ["", Validators.required],
      template_url: ["", [Validators.required, Validators.pattern(/^(https?|wss):\/\/([\w\d-]+\.)+[\w-]+(\/[\w\d-.\/?%&=]*)?$/i)
]]
    })
   }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(false);
    this.initializeDatatable();
    this.intializeUserSettings();    
    this.getProviders();
  }


  getProviders() {
    this.vbSerivce.getProviderList().subscribe((res: any) => {
      if (res.status === "1") {
        this.providerList = res.data;
      }
    })
  }

  onSubmit() {
    this.markFormGroupAsTouched(this.templateForm)

    if (this.checkTemplateName()) {
      return
    }

    if (this.templateForm.valid) {
      const data = {
        id: this.templateId,
        user_id: this.customerId,
        template_name: this.templateForm.value.template_name,
        provider: this.templateForm.value.provider,
        template_url: this.templateForm.value.template_url
      }

      if (this.templateId) {
        this.vbSerivce.updateTemplate(data).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Template updated successfully.", "success")
            this.templateForm.reset();
            this.patchTemplateForm();
            this.templateId = null;
            this.showModel = false;
            this.updateDataTable();
          } else {
            Swal.fire("Something went wrong!", "Failed to update template", "error")
          }
        })
      } else {
        this.vbSerivce.addTemplate(data).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Template added successfully.", "success")
            this.templateForm.reset();
            this.patchTemplateForm();
            this.templateId = null;
            this.showModel = false;
            this.updateDataTable();
          } else {
            Swal.fire("Something went wrong!", "Failed to add template", "error")
          }
        })
      }
    }
  }

  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);

      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  intializeUserSettings() {
    if (this.userData.usergroup != "superadmin") {
      this.userListReadOnly = true;
      this.customerInitial = this.userData.name;
      this.customerId = this.userData.id;
      this.updateDataTable();
    }
  }

  customerEvent(userId) {
    this.customerId = userId;
    this.updateDataTable();
  }

  initializeDatatable() {
    this.dataTable = $('#templatesTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: [],
      columns: [
        { data: 'slno', title: 'Sl No' },
        { data: 'username', title: 'User Name' },
        { data: 'template_name', title: 'Template Name' },
        { data: 'provider_name', title: 'Provider'},
        { data: 'template_url', title: 'Endpoint' },
        { data: 'action', title: "Action" }
      ]
    });
  }

  updateDataTable() {
    const qParams = [
      { key: "scope", value: "voicebot_template" },
      { key: "user_id", value: this.customerId }
    ]

    this.vbSerivce.getTemplateList(qParams).subscribe((res: any) => {
      if (res.status === "1") {
        this.templateList = res.data.map((item, index) => ({
          ...item,
          slno: index + 1,
          action: `<a id="btn-edit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                    <a id="btn-delete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`,
          status: `<input type="checkbox" ${item.active ? "checked" : ""} disabled/>`
        }))
        this.dataTable.clear();
        this.dataTable.rows.add(this.templateList);
        this.dataTable.draw();

        $('#templatesTable tbody').on('click', '#btn-delete', (event: any) => {

          const row = this.dataTable.row($(event.target).closest('tr')).data();
          if (row) {
            this.deleteSettings(row.id);
          }
        });

        $('#templatesTable tbody').on('click', '#btn-edit', (event: any) => {

          const row = this.dataTable.row($(event.target).closest('tr')).data();
          if (row) {
            this.editSettings(row);
          }
        });
      }
    })
  }

  editSettings(row) {
    this.templateId = row.id;
    this.templateForm.patchValue({
      template_name: row.template_name,
      provider: row.provider,
      template_url: row.template_url
    });
    this.showModel = true;
  }

  deleteSettings(id) {
    Swal.fire({
      title: "Are you sure ?",
      text: "The template will be deleted",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete.",
      cancelButtonText: "No, Don't delete.",
    }).then((res) => {
      if (!res.dismiss) {
        this.vbSerivce.deleteTemplate(id).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Settings deleted successfully.", "success");
            this.updateDataTable();
          } else if (res.status === "2") {
            Swal.fire("Unable to delete", res.message, "warning");
          } else {
            Swal.fire("Something went wrong", "Failed to delete settings", "error");
          }
        })
      }
    })
  }

  onModelClose() {
    this.showModel = false;
    this.templateForm.reset();
    this.patchTemplateForm();
  }

  patchTemplateForm() {
    this.templateForm.patchValue({
      provider: ""
    })
  }

  checkTemplateName(): boolean {
    const templateName:string = this.templateForm.value.template_name;   
    let templateNamesList:any[];

    if (this.templateId == null) {
      templateNamesList = this.templateList.filter(
        tmp => tmp.template_name == templateName.trimRight().trimLeft()
      );
  
    } else {
      templateNamesList = this.templateList.filter(
        tmp => tmp.id != this.templateId && tmp.template_name == templateName.trimRight().trimLeft()
      );
    }

    if (templateNamesList.length > 0) {
      this.templateNameExists = true;
      return true;
    } else {
      this.templateNameExists = false;
      return false
    } 
  }
}
