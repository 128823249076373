import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from "@angular/core";
import { LoginService } from "src/app/login.service";
import { UsermanagementService } from "../usermanagement.service";
import { DashboardService } from "./dashboard.service";
import { ReportService } from "../inbound-report/report.service";
// Chart //
import * as Chart from "chart.js";
import { API_URL } from "src/global";
import { DatePipe } from "@angular/common";
import { stringify } from "querystring";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  providers: [DatePipe],
})
export class DashboardComponent implements OnInit , OnDestroy{
  call_reports = { inbound_calls: "Incoming", outbound_calls: "Outgoing" };
  livecallfilterType: any = "All";
  recentfilterType: any = "All";
  date_filter = "today";
  selectedDate: string = '';
  did_id: any = "all";
  recent_calls_array: any = [];
  live_calls_array: any = [];
  inbound_call_count: Object = {};
  outbound_call_count: object = {};
  userData: any;
  UserProfileId: any;
  user_details: object = {};
  total_call_data: object = {};
  callcountType = "incoming";
  // To show recent call //
  showRecentCall: boolean = true;
  // To show live call //
  showLiveCall: boolean = false;
  showEntityFilter: boolean = true;
  countdata_obj: object = {};
  view: any = [];
  calllog_page: any = 1;
  calllognumper_page = 5;
  private intervalId: any;
  total_pages: any;

  call_count_obj_data: object = {};
  live_call_datas: any = [];

  // Call Report datas
  call_status_filter: any = "";
  destination_type_filter: any = "";
  call_type_filter: any = "";
  search_string: any = "";
  call_report_data: any = [];
  call_report_filterd_data: any = [];
  callStatusArray: any = [];
  DestinationArray: any = [];

  // Show PopUp //
  isPopupOpen: boolean = false;
  renewalDate: string;
  currentDate: Date;
  renewalProducts: any = [];

  // Bar Chart Data Type
  incoming_department_bar_chart_data: object = {
    entity: [],
    count: [],
    duration: [],
  };
  incoming_agent_bar_chart_data: object = {
    entity: [],
    count: [],
    duration: [],
  };
  outgoing_agent_bar_chart_data: object = {
    entity: [],
    count: [],
    duration: [],
  };
  outgoing_line_chart_data: object = {};
  incoming_line_chart_data: object = {};
  report_type_checked: any = "inbound_calls";
  enity_filter_type: any = "agent";
  call_filter_type: any = "callcount";
  call_type = { callcount: "Call Count", call_duration: "Call Duration" };

  // Line Chart datas
  inbound_line_chart_object: any = {};
  outbound_line_chart_object: any = {};
  line_call_type_checked: string = "inbound_calls";

  api: string = API_URL;
  didList: any[] = [];

  defaultDate: Date;

  // ============================================================================

  @ViewChild("myChart") canvasRef!: ElementRef;
  private ctx!: HTMLCanvasElement;

  constructor(
    private loginservice: LoginService,
    private usermanagementservice: UsermanagementService,
    private dashboardservices: DashboardService,
    private datePipe: DatePipe,
    private reportservice: ReportService
  ) {}

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(false);
    this.GetDIDList();

    this.loginservice
      .getRenewalData(this.userData.id, this.userData.usertype)
      .subscribe((res: any) => {
        const filteredProducts = res.data.filter((item) => item.status === 1);

        console.log("response --------> : ",res)

        filteredProducts.forEach((item) => {
          this.renewalProducts.push(item);
        });
        this.isPopupOpen = res.data.some((item) => item.status === 1);
      });

    this.loginservice
      .getUserDetails(this.userData.id, this.userData.usertype)
      .subscribe((res: any) => {
        this.user_details["mobile"] = res[0].mobile;
        this.user_details["address"] = res[0].address;
        this.user_details["email"] = res[0].email;
        this.user_details["balance"] = res[0].balance;
        this.user_details["company"] = res[0].company;
        this.user_details["incomingBalance"] = res[0].incomingBalance;
        this.user_details["availablesms"] = res[0].availablesms;
      });

    this.getCallLogData();
    this.getAllDashBordData();

    this.getAgentBarChartData();
    this.getLineChartData();
  }

  //===========================START================ To get Deatils of ALL DASHBAORD DETAILS

  getAllDashBordData(selectedDate?: string) {
    let body = {
      usertype: this.userData.usertype,
      call_type: this.report_type_checked,
      date: this.date_filter,
      // custom:selectedDate,
      custom:this.datePipe.transform(this.selectedDate, "yyyy-MM-dd"),
      did_id: this.did_id
    };

    this.dashboardservices
      .getDashboardData(body, this.userData.id)
      .subscribe((res: any) => {
        this.total_call_data["total_calls"] = res.totals_calls;
        this.total_call_data["total_duration"] = res.total_duration_seconds;
        this.inbound_call_count = res.incoming;
        this.outbound_call_count = res.outgoing;
        this.ChangeCalltype(this.callcountType);
      });

    this.LoadCallData();
  }

  ChangeCalltype(type: string) {
    this.callcountType = type;
    if (type == "incoming") {
      this.countdata_obj = this.inbound_call_count;
    }

    if (type == "outgoing") {
      this.countdata_obj = this.outbound_call_count;
    }

    this.LoadCallData();
  }

  DownloadVoice(vfile, date) {
    let voice_url =
      this.api + "uploads/downloadedWaveFile/" + vfile.replace(".gsm", ".wav");
    var html_con =
      '<audio class="is-6" controls="" controlslist="nodownload" style="height: 40px; width: 32rem;">' +
      "  <source src=" +
      voice_url +
      " </audio>";
    this.reportservice.getcallrecord(vfile, date).subscribe((res: any) => {
      document.getElementById("audio_" + vfile).innerHTML = html_con;
    });
  }

  DownloadVoiceMail(vfile, date) {
    let voice_url =
      this.api +
      "uploads/downloadedVoiceMailFile/" +
      vfile.replace(".gsm", ".wav");
    var html_con =
      '<audio class="is-6" controls="" controlslist="nodownload" style="height: 40px; width: 32rem;">' +
      "  <source src=" +
      voice_url +
      " </audio>";
    this.reportservice
      .getcallrecord(vfile, date, "voice_mail")
      .subscribe((res: any) => {
        document.getElementById("audio_" + vfile).innerHTML = html_con;
      });
  }

  LoadCallData(call_status = "all") {
    let body = {
      usertype: this.userData.usertype,
      call_type: this.callcountType,
      date: this.date_filter,
      call_status: call_status,
      // custom:this.selectedDate,
      custom:this.datePipe.transform(this.selectedDate, "yyyy-MM-dd"),
      did_id: this.did_id
    };

    this.dashboardservices
      .getCallCountReport(body, this.userData.id)
      .subscribe((res: any) => {
        this.call_count_obj_data = res.data.reverse();
      });
  }

  //===================END================ To get Deatils of ALL DASHBAORD DETAILS



  RefreshData() {
    if (this.date_filter === 'custom') {
        if (this.selectedDate) {
            var custom = this.datePipe.transform(this.selectedDate, "yyyy-MM-dd");
            console.log(custom)
            this.getAllDashBordData(custom);
            this.getCallLogData(custom);
            this.getAgentBarChartData(custom);
            this.getLineChartData(custom);
        } else {
            console.error('No selected date');
        }
    } else {
        this.defaultDate = new Date();
        this.selectedDate = null;
        this.getAllDashBordData();
        this.getCallLogData();
        this.getAgentBarChartData();
        this.getLineChartData();
    }
}


  // ---------   Live Calls ---------- //
  LiveCall(): void {
    this.showRecentCall = false;
    this.showLiveCall = true;

    if (this.showLiveCall) {
      this.LoadLiveCallDeatils();

      this.intervalId = setInterval(() => {
        if (this.showLiveCall) {
          this.LoadLiveCallDeatils();
        }
      }, 5000);
    } else {
      clearInterval(this.intervalId);
    }
  }

	// destroying the component to avoid the repeated api call #LiveCallList
  ngOnDestroy(): void {
    clearInterval(this.intervalId); 
  }

  LiveCallFilter(type) {
    this.livecallfilterType = type;
    this.LoadLiveCallDeatils();
  }

  // --------  Recent Calls ---------- //
  RecentCall(): void {
    this.showRecentCall = true;
    this.showLiveCall = false;
  }

  LoadLiveCallDeatils() {
    // ------------------ Live Call Request ------------------- //
    let filterType = this.livecallfilterType;
    var body = { usertype: this.userData.usertype };
    this.dashboardservices
      .getLiveCallDetails(body, this.userData.id)
      // this.userData.id
      .subscribe((res: any) => {
        // Live Call Response Data //

        let data = res.data;

        // this.live_call_datas = data;

        let total_calls = data.length;

        let queue_calls = data.filter((obj) => obj.app_name === "QUEUE");

        let ivr_calls = data.filter((obj) => obj.app_name === "IVR");

        let dialout_calls = data.filter((obj) => obj.app_name === "DIALOUT");

        let callback_calls = data.filter((obj) => obj.app_name === "CALLBACK");

        let talking_calls = data.filter((obj) => obj.answered_time != null);

        if (filterType == "queue") {
          data = queue_calls;
        } else if (filterType == "talking") {
          data = talking_calls;
        } else if (filterType == "callback") {
          data = callback_calls;
        } else if (filterType == "dialout") {
          data = dialout_calls;
        } else if (filterType == "ivr") {
          data = ivr_calls;
        } else {
          data = res.data;
        }

        // this.live_call_datas = data;

        // let total_calls = data.length;

        // let queue_calls = data.filter((obj) => obj.app_name === "QUEUE");

        // let ivr_calls = data.filter((obj) => obj.app_name === "IVR");

        // let dialout_calls = data.filter((obj) => obj.app_name === "DIALOUT");

        // let callback_calls = data.filter(
        //   (obj) => obj.app_name === "CALLBACK"
        // );

        // let talking_calls = data.filter((obj) => obj.answered_time === null);

        // if (filterType == "queue") {
        //   data = queue_calls;
        // } else if (filterType == "talking") {
        //   data = talking_calls;
        // } else if (filterType == "callback") {
        //   data = callback_calls;
        // } else if (filterType == "dialout") {
        //   data = dialout_calls;
        // } else if (filterType == "ivr") {
        //   data = ivr_calls;
        // } else {
        //   data = res.data;
        // }

        this.live_call_datas = {
          callback_calls: callback_calls,
          talking_calls: talking_calls,
          dialout_calls: dialout_calls,
          ivr_calls: ivr_calls,
          queue_calls: queue_calls,
          total_calls: total_calls,
          data: data,
        };

        console.log("=======LIVE CALL DATA===========", this.live_call_datas);
      });
  }

  getAgentBarChartData(selectedDate?: string) {
    let body = {
      usertype: this.userData.usertype,
      call_type: this.report_type_checked,
      date: this.date_filter,
      // custom:selectedDate,
      custom:this.datePipe.transform(this.selectedDate, "yyyy-MM-dd"),
      
      did_id: this.did_id
    };
    this.dashboardservices
      .getAgentBarChartData(body, this.userData.id)
      .subscribe((res: any) => {
        this.outgoing_agent_bar_chart_data = res["outgoing_agent_data"];
        this.incoming_agent_bar_chart_data = res["incoming_agent_data"];
        this.incoming_department_bar_chart_data =
          res["incoming_department_data"];
        this.LoadBarChart();
      });
  }

  ChangeChartCountOrDuration() {
    this.LoadBarChart();
  }

  LoadLineChartData() {

    let type = this.line_call_type_checked;
    let month = [];
    let callcount = [];
    let notanswerd = [];

    if (type == "outbound_calls") {
      month = this.outbound_line_chart_object.times || [];
      callcount = this.outbound_line_chart_object.answered || [];
      notanswerd = this.outbound_line_chart_object.notanswered || [];
    } else {
      month = this.inbound_line_chart_object.times || [];
      callcount = this.inbound_line_chart_object.answered || [];
      notanswerd = this.inbound_line_chart_object.notanswered || [];
    }
    this.DoubleLineChart(month, callcount, notanswerd);
  }

  getLineChartData(selectedDate?: string) {
    var body = {
      usertype: this.userData.usertype,
      call_type: this.line_call_type_checked,
      date: this.date_filter,
      // custom:selectedDate,
      custom:this.datePipe.transform(this.selectedDate, "yyyy-MM-dd"),
      did_id: this.did_id

    };
    this.dashboardservices
      .getCallCountLineChart(body, this.userData.id)
      .subscribe((res: any) => {
        this.inbound_line_chart_object = res["incoming_lineChartData"];
        this.outbound_line_chart_object = res["outgoing_lineChartData"];
        this.LoadLineChartData();
      });
  }

  LoadBarChart() {
    let type = this.report_type_checked;
    let entity_filter_type = this.enity_filter_type;
    let value_filter_type = this.call_filter_type;
    let datas: any;
    let label: any;
    let not_ans_data: any;

    if (type == "outbound_calls") {
      this.showEntityFilter = false;
      const truncatedLabels = this.outgoing_agent_bar_chart_data["agent"];
      if (value_filter_type == "call_duration") {
        datas = this.outgoing_agent_bar_chart_data["duration"];
        label = "Duration";
      } else {
        datas = this.outgoing_agent_bar_chart_data["ans_callcount"];
        not_ans_data = this.outgoing_agent_bar_chart_data["notans_callcount"];
        label = "Connected Calls";
      }


      this.BarChart(truncatedLabels, datas, label, not_ans_data);
    } 
    else {
      let datas: any;
      let label: any;
      this.showEntityFilter = true;
      let truncatedLabels: any;

      if (entity_filter_type == "agent") {
        if (value_filter_type == "call_duration") {
          datas = this.incoming_agent_bar_chart_data["duration"];
          label = "Duration";
        } else if (value_filter_type == "callcount") {
          datas = this.incoming_agent_bar_chart_data["ans_callcount"];
          not_ans_data = this.incoming_agent_bar_chart_data["notans_callcount"];
          label = "Connected Calls";
        }

        truncatedLabels = this.incoming_agent_bar_chart_data["agent"];
      } else {
        console.log("=========================================== inside");

        if (value_filter_type == "call_duration") {
          datas = this.incoming_department_bar_chart_data["dep_duration"];
          label = "Duration";
        } else if (value_filter_type == "callcount") {
          datas = this.incoming_department_bar_chart_data["dep_ans_count"];
          not_ans_data =
            this.incoming_department_bar_chart_data["dep_notans_count"];
          label = "Connected Calls";
        }
        truncatedLabels = this.incoming_department_bar_chart_data["dep_name"];
      }

      this.BarChart(truncatedLabels, datas, label, not_ans_data);
    }
  }

  BarChart(entities, datas, label = "Connected Calls", not_ans_data = null) {
    this.ctx = document.getElementById("barChartId") as HTMLCanvasElement;

    let existingChart;

    // Convert the chart instances object into an array and find the matching instance
    const chartInstances = Object.values(Chart.instances);
    existingChart = chartInstances.find(
      (instance: any) => instance.chart.canvas === this.ctx
    );

    // If an existing chart instance is found, destroy it
    if (existingChart) {
      existingChart.destroy();
    }

    let datasets = [
      {
        label: label,
        data: datas,
        borderWidth: 1,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 270);
          gradient.addColorStop(0, "#AFD462");
          gradient.addColorStop(0.7, "#6BC698");
          gradient.addColorStop(1, "#0FB1E2");
          return gradient;
        },

        barThickness: 48,
      },
    ];

    if (not_ans_data) {
      datasets.push({
        label: "Not connected Calls",
        data: not_ans_data,
        borderWidth: 1,
        backgroundColor: (context: any) => "#D5D8DC",
        barThickness: 48,
      });
    }

    const myChart = new Chart(this.ctx, {
      type: "bar",
      data: {
        labels: entities,
        datasets: datasets,
      },
      options: {
        plugins: {
          roundedBorders: {
            radius: 5,
          },
          legend: {
            display: true,
            position: "top",
            align: "end",
            labels: {
              usePointStyle: true,
              pointStyle: "rectRounded",
              color: "#565656",
              font: {
                size: 15,
                family: "karla",
              },

              padding: 20,
            },
          },
          tooltip: {
            backgroundColor: "white",
            titleColor: "black",
            bodyColor: "black",
            borderColor: "black",
            borderWidth: 2,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: "index",
          intersect: false,
        },

        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: "#565656",
                fontFamily: "karla",
                fontSize: 14,
                callback: function (value) {
                  let value_string = String(value);
                  if (value_string.length > 4) {
                    return value_string.substring(0, 4) + "...";
                  }
                  return value_string;
                },
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: "#565656",
                fontFamily: "karla",
                fontSize: 12,
                fontStyle: "500",
                maxTicksLimit: 5,
              },
            },
          ],
        },
      },
    });

    //   if (!not_ans_data){

    //   Chart.plugins.register({

    //     beforeDraw: function (chart) {

    //       const dataset = chart.data.datasets[0];
    //       const bars = chart.getDatasetMeta(0).data;

    //       const targetBarInde= parseInt(agents.length);
    //       const targetBarIndex = 11

    //       bars.forEach((bar, index) => {

    //         if (index === targetBarIndex) {
    //           bar._model.backgroundColor = '#D5D8DC'; // Set the desired background color for the target bar
    //         }
    //       });
    //     },
    //   });
    // };
  }

  DoubleLineChart(label, answerd, not_answerd) {
    const lineChart = document.getElementById(
      "callCountlineChart"
    ) as HTMLCanvasElement;

    let existingChart;

    // Convert the chart instances object into an array and find the matching instance
    const chartInstances = Object.values(Chart.instances);
    existingChart = chartInstances.find(
      (instance: any) => instance.chart.canvas === lineChart
    );

    // If an existing chart instance is found, destroy it
    if (existingChart) {
      existingChart.destroy();
    }

    const data = {
      labels: label,
      datasets: [
        {
          label: "Answerd",
          data: answerd,
          fill: false,
          backgroundColor: "#6BC698",
          borderColor: "#6BC698",
          lineTension: 0.4,
        },
        {
          label: "No Answer",
          data: not_answerd,
          fill: false,
          backgroundColor: "#E85434",
          borderColor: "#E85434",
          lineTension: 0.4,
        },
      ],
    };

    const optionss = {
      plugins: {
        legend: {
          display: true,
          position: "top",
          align: "end",
          labels: {
            usePointStyle: true,
            pointStyle: "rectRounded",
            color: "#9AA7B5",
            padding: 30,
          },
        },

        tooltip: {
          backgroundColor: "white",
          titleColor: "black",
          bodyColor: "black",
          borderColor: "black",
          borderWidth: "2",
        },
      },

      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            color: "#232323",
            font: {
              size: 15,
              family: "karla",
              weight: "600",
            },
          },
        },
        y: {
          display: false,
          grid: {
            display: false,
          },
          ticks: {},
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        mode: "index",
        intersect: false,
      },
    };

    const options = {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        yAxes: [
          {
            display: false,
          },
        ],
      },
      plugins: {
        legend: {
          display: true,
          position: "top",
          align: "start",
          labels: {
            usePointStyle: true,
            pointStyle: "rectRounded",
            color: "#565656",
            font: {
              size: 12,
              family: "karla",
            },
            padding: 10,
          },
        },
        tooltip: {
          backgroundColor: "white",
          titleColor: "black",
          bodyColor: "black",
          borderColor: "black",
          borderWidth: "2",
        },
      },
      maintainAspectRatio: false,
    };

    new Chart(lineChart, {
      type: "line",
      data: data,
      options: options,
    });
  }

  // ================================START CALL LOG REPORT=======================

  FilterResults() {
    console.log("=====SEARCH STRING======", this.search_string);

    let final_filter = this.call_report_data;
    if (this.call_type_filter) {
      console.log("=============CALL TYPE FILTER====", this.call_type_filter);
      final_filter = this.call_report_data.filter(
        (record) => record.call_type === this.call_type_filter.toLowerCase()
      );
    }
    if (this.call_status_filter) {
      console.log(
        "=============call_status_filter====",
        this.call_status_filter
      );

      final_filter = final_filter.filter(
        (record) => record.callStatus === this.call_status_filter
      );

      console.log("==========FINAL RSULT======", final_filter);
    }

    if (this.destination_type_filter) {
      final_filter = final_filter.filter(
        (record) => record.destination_type === this.destination_type_filter
      );
    }
    if (this.search_string) {
      console.log("=====SEARCH STRING======", this.search_string);

      final_filter = final_filter.filter(
        (record) =>
          record.agentName
            .toLowerCase()
            .includes(this.search_string.toLowerCase()) ||
          (record.agentNumber ? record.agentNumber.toLowerCase() : "").includes(
            this.search_string.toLowerCase()
          ) ||
          (record.customer && record.customer.number
            ? record.customer.number.toLowerCase()
            : ""
          ).includes(this.search_string.toLowerCase()) ||
          (record.customer && record.customer.name
            ? record.customer.name.toLowerCase()
            : ""
          ).includes(this.search_string.toLowerCase())
      );

      console.log("seracg filter");
    }

    this.call_report_filterd_data = final_filter;
    this.calllog_page = 1;
  }

  LoadPaginatedData(type) {
    if (type == "next") {
      this.calllog_page = this.call_report_filterd_data["current_page"] + 1;
    }
    if (type == "previous") {
      this.calllog_page = this.call_report_filterd_data["current_page"] - 1;
    }

    this.getCallLogData();
  }

  get paginatedItems(): any[] {
    const startIndex = (this.calllog_page - 1) * this.calllognumper_page;
    return this.call_report_filterd_data.slice(
      startIndex,
      startIndex + this.calllognumper_page
    );
  }

  setPage(pageNumber: number) {
    this.calllog_page = pageNumber;
  }

  getPageNumbers(): number[] {
    const totalPages = Math.ceil(
      this.call_report_filterd_data.length / this.calllognumper_page
    );
    this.total_pages = totalPages;
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }

  getCallLogData(selectedDate?: string) {
    let body = {
      usertype: this.userData.usertype,
      call_type: this.report_type_checked,
      date: this.date_filter,
      // custom:selectedDate,
      custom:this.datePipe.transform(this.selectedDate, "yyyy-MM-dd"),
      did_id: this.did_id

    };

    this.dashboardservices
      .getDashboardCallLog(body, this.userData.id, this.calllog_page)
      .subscribe(
        (response) => {
          this.call_report_data = response;
          this.call_report_filterd_data = response;

          const callStatusArray_data = response.map(
            (record) => record.callStatus
          );
          // Remove duplicates using Set
          this.callStatusArray = Array.from(new Set(callStatusArray_data));

          const DestinationArray_data = response.map(
            (record) => record.destination_type
          );
          // Remove duplicates using Set
          this.DestinationArray = Array.from(new Set(DestinationArray_data));
        },
        (error) => {
          // Handle the error here
          console.error("Error:", error);
        }
      );
  }

  GetDIDList() {
    this.userData = this.loginservice.getUserdata(true);
    const mainUserId = this.userData.admin_acc_id;

    this.dashboardservices.getDidList(mainUserId).subscribe((response: any) => {
      if (response) {
        this.didList = response.data.map((item) => ({
          id: item.id,
          name: item.name.toString()
        }))
        console.log(this.didList);
      }
    });
  }
}
