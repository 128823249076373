import { Component, OnInit } from '@angular/core'
import { FollowUpService } from '../follow-up/follow-up.service'
import { LoginService } from 'src/app/login.service'
import { FormGroup, FormBuilder } from '@angular/forms'
import { NotificationService } from "src/app/notification/notification.service"
import Swal from 'sweetalert2'
import { ExcelService } from 'src/app/inbound-report/excel.service'
import * as moment from 'moment';
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-follow-up-report',
  templateUrl: './follow-up-report.component.html',
  styleUrls: ['./follow-up-report.component.css'],
  providers: [DatePipe],
})
export class FollowUpReportComponent implements OnInit {

  followUpReportData: any[] = []
  userData: any
  filterForm: FormGroup
  editPopUp: boolean = false

  follow_up_id: any
  followUpDate: string = ''
  followUpDescription: string = ''
  followUpStatus: string = 'pending'
  customer_id: any
  editRowIndex: number

  userID: any
  userType: any = ''
  userName: any = ''
  userNumber: any = ''

  defaultDateFrom: Date;
  defaultDateTo: Date;


  constructor(
    private followupservice: FollowUpService,
    private loginservice: LoginService,
    private formBuilder: FormBuilder,
    private notifyservice: NotificationService,
    private excelservice: ExcelService,
    private spinnerService: Ng4LoadingSpinnerService,
    private datePipe: DatePipe,

  ) { }

  ngOnInit() {
    const defaultDate = new Date();
    this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);

    this.userData = this.loginservice.getUserdata(true);

    if (this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser' || this.userData.usertype == 'virtualUser') {
      this.userID = this.userData.admin
      this.userType = this.userData.usertype
      this.userName = this.userData.name
      this.userNumber = this.userData.phone
    }
    else {
      this.userID = this.userData.id
    }

    this.loadFollowUpReportData()
    this.FilterForm()
    this.filterForm.get('from_date').valueChanges.subscribe((value) => {
      this.onFromDateChange(value);
    });

    this.filterForm.get('to_date').valueChanges.subscribe((value) => {
      this.onToDateChange(value);
    });
  }

  onFromDateChange(value: any): void {
    if (value && value !== "") {
      const formattedDate = moment(value).format('YYYY-MM-DD HH:mm:ss');
      this.defaultDateFrom = new Date(formattedDate);
      console.log('From Date Changed:', formattedDate);
    } else {
      const defaultDate = moment().startOf('day').toDate();
      this.defaultDateFrom = defaultDate;
      console.log('From Date Reset to Start of Day:', this.defaultDateFrom);
    }
  }

  onToDateChange(value: any): void {
    if (value && value !== "") {
      const formattedDate = moment(value).format('YYYY-MM-DD HH:mm:ss');
      this.defaultDateTo = new Date(formattedDate);
      console.log('To Date Changed:', formattedDate);
    } else {
      const defaultDate = moment().endOf('day').toDate();
      this.defaultDateTo = defaultDate;
      console.log('To Date Reset to End of Day:', this.defaultDateTo);
    }
  }


  FilterForm() {
    this.filterForm = this.formBuilder.group({
      user_id: [this.userID],
      date_type: ['follow up date'],
      from_date: [''],
      to_date: [''],
      search: [''],
      status: ['']
    })
  }

  loadFollowUpReportData() {
    this.followupservice.LoadFollowUpReport(this.userID, this.userType, this.userName, this.userNumber).subscribe(
      (data: any) => {
        if (data.length === 0) {
          this.followUpReportData = null
        }
        else {
          this.followUpReportData = data.sort((a, b) => {
            const dateA = new Date(a.follow_up_date)
            const dateB = new Date(b.follow_up_date)
            return dateA.getTime() - dateB.getTime()
          })
        }
      }
    )
  }

  // FilterReport(){
  //   const filterBody = this.filterForm.value
  //   if(this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser'  || this.userData.usertype == 'virtualUser'){
  //     filterBody.created_user = {
  //       number: this.userData.phone,
  //       name: this.userData.name,
  //       user_type: this.userData.usertype
  //     }
  //   }

  //   this.followupservice.FilterFollowUpReport(filterBody).subscribe(
  //     (data:any) => {
  //       this.followUpReportData = data.sort((a, b) => {
  //         const dateA = new Date(a.follow_up_date)
  //         const dateB = new Date(b.follow_up_date)
  //         return dateA.getTime() - dateB.getTime()
  //       })
  //     }
  //   )
  // }


  FilterReport() {
    const filterBody = this.filterForm.value;
    filterBody.from_date = this.datePipe.transform(filterBody.from_date, "yyyy-MM-dd HH:mm:ss");
    filterBody.to_date = this.datePipe.transform(filterBody.to_date, "yyyy-MM-dd HH:mm:ss");
    // Extract from_date and to_date from the form
    const from_date = filterBody.from_date ? new Date(filterBody.from_date) : null;
    const to_date = filterBody.to_date ? new Date(filterBody.to_date) : null;

    // Perform validation
    if (!from_date && to_date) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }
    if (from_date && !to_date) {
      const today = new Date()
      today.setHours(23, 59, 59, 0)
      filterBody.to_date = this.datePipe.transform(today, "yyyy-MM-dd HH:mm:ss");
    }

    if (from_date && to_date && from_date > to_date) {
      this.spinnerService.hide();
      alert("From date cannot be greater than To date.");
      return;
    }

    // Add created_user if applicable
    if (['agentUser', 'dialerUser', 'virtualUser'].includes(this.userData.usertype)) {
      filterBody.created_user = {
        number: this.userData.phone,
        name: this.userData.name,
        user_type: this.userData.usertype,
      };
    }

    // Call the API
    this.followupservice.FilterFollowUpReport(filterBody).subscribe(
      (data: any) => {
        this.followUpReportData = data.sort((a, b) => {
          const dateA = new Date(a.follow_up_date);
          const dateB = new Date(b.follow_up_date);
          return dateA.getTime() - dateB.getTime();
        });
      }
    );
  }


  clearFilters() {
    this.filterForm.get('date_type').setValue('follow up date')
    this.filterForm.get('from_date').setValue('')
    this.filterForm.get('to_date').setValue('')
    this.filterForm.get('search').setValue('')
    this.filterForm.get('status').setValue('')
    this.loadFollowUpReportData()
  }

  deleteFollowup(follow_up_id: any) {
    Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this follow up detail?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then(result => {
      if (result.value) {
        this.followupservice.DeleteFollowUp(follow_up_id).subscribe(
          (res: any) => {
            if (res.status === '1') {
              this.notifyservice.sendNotification("Deleted", "Follow up details deleted successfully", "is-danger")
              this.loadFollowUpReportData()
            }
          }
        )
      }
    })
  }

  openEditPopup(followUp: any, index: number) {
    this.editRowIndex = index

    this.follow_up_id = followUp.id
    this.followUpDate = followUp.follow_up_date
    this.followUpDescription = followUp.description
    this.followUpStatus = followUp.status

    this.editPopUp = true
  }

  clearFormFields() {
    this.followUpDate = ''
    this.followUpDescription = ''
    this.followUpStatus = 'pending'
  }

  closeEditPopup() {
    this.editPopUp = false
    this.clearFormFields()
  }

  updateFollowUp(customer_id: any) {
    const followUpData: any = {
      customer_id: customer_id,
      follow_up_date: this.followUpDate,
      description: this.followUpDescription,
      status: this.followUpStatus
    }

    if (this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser' || this.userData.usertype == 'virtualUser') {
      followUpData.created_user = {
        number: this.userData.phone,
        name: this.userData.name,
        user_type: this.userData.usertype
      }
    }

    this.followupservice.EditFollowUp(this.follow_up_id, followUpData).subscribe(
      (res: any) => {
        if (res.status === '1') {
          this.followUpReportData[this.editRowIndex] = res.follow_up_data
          this.notifyservice.sendNotification("Updated", "Follow up details updated successfully", "is-info")
          this.editPopUp = false
          this.clearFormFields()
        }
      }
    )
  }

  downloadAsExcel() {
    const FollowUpData = this.followUpReportData.map((item, index) => ({
      'SL. NO': index + 1,
      'Customer name': item.customer.name,
      'Customer number': item.customer.number,
      'Follow up date': this.formatDate(item.follow_up_date),
      'Status': item.status,
      'Description': item.description,
      'Created date': this.formatDate(item.created_date),
    }))
    this.excelservice.exportAsExcelFile(FollowUpData, "Follow up details")
  }

  formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    const formattedDate = new Date(dateString).toLocaleString('en-US', options);
    const [date, time] = formattedDate.split(', ');
    const [month, day, year] = date.split('/');
    const formattedDateResult = `${day}/${month}/${year}, ${time}`;

    return formattedDateResult;
  }

  updateFollowUpInReport(follow_up_id: any, index: any) {
    this.followupservice.LoadFollowUpReport(this.userID, this.userType, this.userName, this.userNumber).subscribe(
      (data: any) => {
        const updatedFollowUp = data.find(follow_up => follow_up.id === follow_up_id);
        this.followUpReportData[index] = updatedFollowUp
      }
    )
  }

  ContactUpdatedEvent(customerId: number, index: any) {
    if (customerId === -1) {
      this.followUpReportData.splice(index, 1);
    }
  }

}
