import { Component, OnInit } from '@angular/core';
import { KylasService } from './kylas.services';
import { FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { log } from 'util';

@Component({
  selector: 'app-kylas-integration',
  templateUrl: './kylas-integration.component.html',
  styleUrls: ['./kylas-integration.component.css']
})
export class KylasIntegrationComponent implements OnInit {

  userProfile:any;
  crmConfigValid: boolean = true;
  crmConfigErrors: any[] = [];
  kylasForm: FormGroup;
  vsipEnabled: boolean = false;
  stickOnDIDEnabled: boolean = false;
  agentListArray: any[] = [];

  constructor(
    private kylasService: KylasService,
    private fb: FormBuilder
  ) {

    this.kylasForm = this.fb.group({
      agentName: ["", Validators.required],
      agentNumber: ["", [Validators.required, Validators.pattern(/^\d{10}$/)]],
      kylasUserId: ["",Validators.required],
      vsipEnabled: [false],
      vsipNumber: [""],
      stickOnDID: [""],
      DIDNumber: [""]
    })
   }

  ngOnInit() {
    this.userProfile = JSON.parse(localStorage.getItem("userdata"))  
    this.getCRMConfig();    
    this.initializeAgentList();
  }



  initializeAgentList(){
    const qParams = [
      { key: "scope", value: "agent_list"},
      { key: "user_id", value: this.userProfile.id}
    ]
    this.kylasService.getCRMAgents(qParams).subscribe((res:any)=>{
      if(res["status"] === "1"){
        this.agentListArray = res["data"]
      }
    })
  }

  getCRMConfig(){
    this.kylasService.getCRMConfig(this.userProfile.id).subscribe((res:any)=>{
      if(res.status === "1"){
        const data = res.data
        if(data.format !== "kylas"){
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Format is not configured");
        }
        if(data.url === null){
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Kylas url is not configured");
        }

        if (data.autocall !== "true" && data.autocall !== "false"){
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Autocall is not configured");
        }

        if (data.autocall_did === null) {
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Autocall DID is not configured");
        }

        if (data.autocall_channel_id === null) {
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Autocall channel id is not configured");
        }

        if(data.kylas_api_key === null){
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Kylas API Key is not configured");
        }

        if(data.kylas_user_id === null){
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Kylas user id's is not configured");
        }
      }

      else if(res["status"] == "0" && res["error"] == "Config not found."){
        this.crmConfigValid = false;
        this.crmConfigErrors.push("CRM configuration is not found.");
      }
     
    });
  }

  onVsipChange(){
    this.vsipEnabled = this.kylasForm.value.vsipEnabled
    const vsipIdControl = this.kylasForm.get("vsipNumber")
    if (this.vsipEnabled){
      vsipIdControl.setValidators([Validators.required]);      
    }else{
      vsipIdControl.clearValidators();     
      this.kylasForm.patchValue({vsipNumber: ""})
    }

    vsipIdControl.updateValueAndValidity();
  }


  onStickOnDIDChange() {
    this.stickOnDIDEnabled = this.kylasForm.value.stickOnDID
    const stickOnDIDControl = this.kylasForm.get("DIDNumber")
    if (this.stickOnDIDEnabled) {
      stickOnDIDControl.setValidators([Validators.required, Validators.pattern(/^\d{10}$/)]);
    } else {
      stickOnDIDControl.clearValidators();
      this.kylasForm.patchValue({ DIDNumber: "" });
    }
  }

  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);

      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  onSubmit(){
    this.markFormGroupAsTouched(this.kylasForm);
    if(this.kylasForm.valid){
      const data = {
        user : this.userProfile.id,
        name : this.kylasForm.value.agentName,
        agentNumber: this.kylasForm.value.agentNumber,
        kylas_user_id: this.kylasForm.value.kylasUserId,
        vsip_number: this.kylasForm.value.vsipNumber,
        vsipEnabled: this.kylasForm.value.vsipEnabled,
        stickOnDIDEnabled: this.kylasForm.value.stickOnDID,
        stick_on_did: this.kylasForm.value.DIDNumber
      }
      this.kylasService.addCRMAgents(data).subscribe((res:any)=>{
        if(res.status === "1"){
          Swal.fire("Success", "Agent added successfully.", "success");
          this.kylasForm.reset();
          this.vsipEnabled = false;
          this.stickOnDIDEnabled = false;
          this.initializeAgentList();
          
        }
        else{
          Swal.fire("Something went wrong!", res.error, "error");
        }
      })
      
    }
  }

  onDeleteAgent(agentId:string){
    Swal.fire({
      title: "Are you sure ?",
      text: "The agent will be deleted.",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete agent.",
      cancelButtonText: "No, Dont delete.",            
    }).then((resp)=>{
      if(!resp.dismiss){
        this.kylasService.deleteAgent(agentId).subscribe((res:any)=>{
          if(res["status"]=== "1"){
            Swal.fire("Success", res["message"], "success")
            this.initializeAgentList();
          }
        })        
      }
    })
  }

}
