import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { bitrixagents } from './bitrix.service';
import Swal from 'sweetalert2';
import { FormGroup, FormArray, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-bitrix',
  templateUrl: './bitrix.component.html',
  styleUrls: ['./bitrix.component.css']
})
export class BitrixComponent implements OnInit {
  bitrixForm: FormGroup;
  userProfile: any;
  bitrixagentlist: any[] = [];
  userType: any;
  grp: any;
  userData: any;
  UserProfileId: any = null;
  userSelected: boolean = false;  
  UserProfileIdSelected: boolean = false;
  permissions: any;
  permis = false;
  enabled: boolean = false;
  bitrixID: any;
  bitrix_user_id: any;
  agent_name: any;
  agent_email: any;
  agent_number: any;
  vsip_enabled: any;
  vsip_id: any;
  user: any;
  agentEmail: any;
  agentName: any;
  agentNumber: any;
  bitrixUserId: any;
  public AgentForm: FormGroup;
  userName: any;
  // id: number;

  public id: any = 0;

  agentData: any;
  bitrixCRM: any;
  existedAgent: any;
  submitted: boolean;
  bitrixId: any;
  isSuperAdmin: any;

  constructor(
    private bitrixService: bitrixagents,
    private loginservice: LoginService,
    private activeroute: ActivatedRoute,
    private router: Router,


  ) { 
    
  }

  ngOnInit() {
    this.userProfile = JSON.parse(localStorage.getItem("userdata"))
    this.userType = localStorage.getItem('userType')
    this.userData = this.loginservice.getUserdata(true);
    this.UserProfileId = this.userProfile ? this.userProfile.id : null;
    this.id = +this.activeroute.snapshot.paramMap.get('id');
    this.AgentForm = new FormGroup({
      agentName: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9]+$')

      ]),
      agentEmail: new FormControl('', [
      ]),
      agentNumber: new FormControl('', [
        Validators.required, this.numberValid({ pattern: /^\+?[0-9(),.-]{10}$/, msg: 'Mobile Number is not Valid' })
   
      ]),
      bitrixUserId: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+$') 
      ]),
      bitrixId: new FormControl(''),
      enabled: new FormControl('', [
        // Validators.pattern('^(true|false)$') 
      ]),
    });


    this.UserProfileId = this.userData.id;
    this.userName = this.userData.name
    this.id = +this.activeroute.snapshot.paramMap.get('id');


    this.bitrixService.getAgentUsers(this.UserProfileId).subscribe(
      (res: any) => {
        console.log("res data>>>>", res["data"])
        this.agentData = res["data"];
        this.isSuperAdmin = res["superadmin"];
      }
    )
    this.bitrixService.getbitrixCRM(this.UserProfileId).subscribe(
      (res: any) => {
        console.log("res data>>>>", res["data"])
        this.bitrixCRM = res["data"];
        this.isSuperAdmin = res["superadmin"];
      }
    )


    }



  onUserSelect(event: any) {
    this.UserProfileIdSelected = !!event;  
  }

  numberValid(config: any) {

    return (control: FormControl) => {
      let urlRegEx: RegExp = config.pattern;
      if (control.value && !String(control.value).match(urlRegEx)) {
        return {
          invalidnumberMsg: config.msg
        };
      }
    };
  }

  nameValid(config: any) {

    return (control: FormControl) => {
      let urlRegEx: RegExp = config.pattern;
      if (control.value && !String(control.value).match(urlRegEx)) {
        return {
          invalidnameMsg: config.msg
        };
      }
    };

  }
  onSubmit() {
    this.submitted = true;
    if (this.AgentForm.valid) {
      var name = this.AgentForm.value.name
      var num = this.AgentForm.value.number
      var chatId = this.AgentForm.value.chatId
      var email = this.AgentForm.value.email
      var user = this.userName
      var password = this.AgentForm.value.password
      var autocall = this.AgentForm.value.autocall
      var timecondition = this.AgentForm.value.timecondition

      this.bitrixService.ExistAgent(user, name, num).subscribe(
        (res: any) => {
          this.existedAgent = res;
          const data = {
            "agent_name": this.AgentForm.value.agentName,
            "agent_email": this.AgentForm.value.agentEmail,
            user: this.UserProfileId,
            "agent_number": this.AgentForm.value.agentNumber,
            "bitrixId": this.AgentForm.value.bitrixId,
            "bitrix_user_id": this.AgentForm.value.bitrixUserId,
            "enabled": this.AgentForm.value.enabled,
          
          };
          if (this.existedAgent['status'] == 0) {
            this.bitrixService.addBitrixagents(data).subscribe(
              (res: any) => {
                if (res.status === "1") {
                  Swal.fire("Success", "Agent added successfully");
                  this.AgentForm.reset();

                  this.bitrixService.getAgentUsers(this.UserProfileId).subscribe(
                    (res: any) => {
                      this.agentData = res["data"];
                      this.isSuperAdmin = res["superadmin"];
                      this.reloadCurrentRoute();
                    }
                  )
                } else {
                  Swal.fire("Something went wrong");
                }
              },
              (error: any) => {
                if (error.status === 400 && error.error && error.error.errors) {
                  const errors = error.error.errors;
                  let errorMessage = '';

                  if (errors.name && errors.name[0] === "bitrix agents with this name already exists.") {
                    errorMessage += "An agent with this name already exists.\n";
                  }
                  if (errors.agent_number && errors.agent_number[0] === "bitrix agents with this agent number already exists.") {
                    errorMessage += "An agent with this agent number already exists.\n";
                  }
                  Swal.fire("Duplicate Entry", errorMessage.trim(), "warning");
                } else {
                  Swal.fire("Error", "Something went wrong while adding the agent.", "error");
                }
              }
            );
          }
          if (this.existedAgent['status'] == "1") {
            Swal.fire('Oops..!', 'Please change the Agent Name, Already Exist', 'warning')
          }
          if (this.existedAgent['status'] == "2") {
            Swal.fire('Oops..!', 'Please change the Agent Number, Already Exist', 'warning')
          }
        });
    } else { 
      console.log("Form is invalid!");

      Object.keys(this.AgentForm.controls).forEach(field => {
        const control = this.AgentForm.get(field);

        if (control && control.invalid) {
          console.log(`${field} is invalid:`, control.errors); 
        }
      });
    }

  }

  onEdit(id) {
    // console.log("on Edit!!!this.AgentForm.valid!!!!!!!!!", this.AgentForm.valid)
    this.submitted = true;
    if (this.AgentForm.valid) {
      console.log("bitrixId::", this.bitrixId)
      this.bitrixService.EditAgentUsers(this.agentName, this.agentNumber, id, this.agentEmail, this.bitrixUserId, this.enabled ).subscribe(
        (res: any) => {
          console.log("in EditAgentUsers ")
          if (res.status == 0) {
            Swal.fire('Error', res.message, 'warning')
          }
          else {
            Swal.fire('Updated', res.message, 'success')
            this.AgentForm.reset();
            this.submitted = false;
            // this.AgentForm.controls['name'].enable();
            this.bitrixService.getAgentUsers(this.UserProfileId).subscribe(
              (res: any) => {
                console.log("updated data>>>>>>>>>>>>>", res["data"])
                this.agentData = res["data"];
                this.isSuperAdmin = res["superadmin"];
                this.reloadCurrentRoute();
              }
            )

          }

        });
    }
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  EnabledConditionChange(event: any) {
    this.enabled = event.target.checked;
  }



  OndeleteAgent(id) {
    Swal.fire({
      title: 'Are you sure ?',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        console.log("success")
        this.user = this.loginservice.getUserdata(true);
        this.bitrixService.fetchAgentorDelete(id, "delete").subscribe(
          (res: any) => {
            Swal.fire('Deleted', 'Agent Successfully Deleted', 'success')
            this.bitrixService.getAgentUsers(this.UserProfileId).subscribe(
              (res: any) => {
                this.agentData = res["data"];
                this.isSuperAdmin = res["superadmin"];
              }
            )


          },
          (error: any) => {


            const errorMessage = error.error && error.error.error
              ? error.error.error.replace(/^\[|\]$/g, '')
                .replace(/'/g, '')
                .trim()
              : 'Please try again later.';

            Swal.fire('Error', errorMessage, 'error');

          }
        );
      }
    })
  }

 
  onCancelEdit() {
    this.reloadCurrentRoute();
  }
  onAgentEnableChange(event: any) {
    this.enabled = event.target.checked;
  }



  domain: string = ""; 
  clientId: string = "app.676d249f092c51.05642657"; //  Bitrix App Client ID
  
  
  authorizeWithBitrix() {
    if (!this.domain) {
      alert("Please enter your bitrix domain name."); 
      return;
    }

    // const baseUrl = `https://${this.domain}.bitrix24.in`; 
    const authUrl = `${this.domain}/oauth/authorize/?client_id=${this.clientId}&state=${this.UserProfileId}`;

    window.open(authUrl, '_blank'); 
  }



  fetchAgent(id) {
    this.bitrixService.fetchAgentorDelete(id, "fetch").subscribe(
      (res: any) => {
        this.id = res["data"]["id"];
        this.agentEmail = res["data"]["agent_email"];
        this.agentName = res["data"]["agent_name"];
        this.agentNumber = res["data"]["agent_number"];
        this.bitrixUserId = res["data"]["bitrix_user_id"];
        this.bitrixId = res["data"]["BitrixID_id"];
        this.enabled = res["data"]["enabled"];
        this.isSuperAdmin = res["superadmin"];
        
      });
  }  
  
  
  
  
  
  
  
  
  
}
