import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InboundRouteService } from '../inbound-route.service';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';


declare var $: any;

@Component({
  selector: 'app-caller-registration',
  templateUrl: './caller-registration.component.html',
  styleUrls: ['./caller-registration.component.css']
})
export class CallerRegistrationComponent implements OnInit, AfterViewInit {

  userData: any;
  permissions: any;
  permis: boolean = false;
  templateForm: FormGroup;
  templateList: any[] = [];
  templateListDisplay: any[] = [];
  templateId: string | null = null;
  selectedTemplate: boolean = false;
  selectedTemplateName: string = "";
  selectedTemplateId: string | null = null;
  selectedTemplateData: any;
  searchText: string | null = null;
  dataTable: any;
  callerFIle: File = null;
  callerFileValid: boolean = true;
  callerFileName: string = "Choose a file…";
  callerList: any[] = [];
  propHeight: number = 0;
  registrationInProgress: boolean = false;


  constructor(
    private loginService: LoginService,
    private fb: FormBuilder,
    private inboundRouteService: InboundRouteService
  ) { 
    this.templateForm = this.fb.group({
      template_name: ["", Validators.required],
      user: [""],
      active: [true]
    })
  }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(true)
    this.permissions = this.userData.permissions;
    this.checkPermission();
    this.initializeTemplates();
  }

  ngAfterViewInit(): void {
    this.initializeDatatable()
  }

  checkPermission() {
    for (let element of this.permissions) {
      if (element.codename == "cadmin_e_permission_caller_registration") {
        this.permis = true;
      }
    }
  }

  onTemplateSubmit() {
    this.markFormGroupAsTouched(this.templateForm);

    if (this.templateForm.valid) {
      const data = {
        id: this.templateId,
        template_name: this.templateForm.value.template_name,
        user: this.userData.id,
        active: this.templateForm.value.active
      }

      if (this.templateId) {
        this.inboundRouteService.updateCallerTemplate(data).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Template updated successully.", "success")
            this.initializeTemplates();
            this.templateId = null;
            this.templateForm.reset();
            this.templateForm.patchValue({active: true})
          } else if (res.status === "0" && String(res.error).includes("template_name")) {
            Swal.fire("Something went wrong!", "Template name already exists.", "error")
          } else {
            Swal.fire("Something went wrong!", "Template updation failed.", "error")
          }
       })
      } else {
        this.inboundRouteService.addCallerTemplate(data).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Template added successfully.", "success")
            this.initializeTemplates();
          } else if (res.status === "0" && String(res.error).includes("template_name")) {
            Swal.fire("Something went wrong!", "Template name already exists.", "error")
          } else {
            Swal.fire("Something went wrong!", "Failed to add template", "error")
          }
        })
      }
      

    }   
  }

  initializeTemplates() {
    this.inboundRouteService.listCallerTemplate(this.userData.id).subscribe((res: any) => {
      if (res.status === "1") {
        this.templateList = res.data;
        this.templateListDisplay = res.data;
        if (this.selectedTemplateId) {
          this.registrationInProgress = this.templateList.find(item => item.id == this.selectedTemplateId).in_process
          if (this.registrationInProgress === false) {
            this.updateDataTable(this.selectedTemplateId)
          }
        }
      }
    })
  }

  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);

      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  onEditTemplate(id: string) {
    this.templateId = id;
    const template = this.templateList.find(item => item.id == id);
    this.templateForm.patchValue({
      template_name: template.template_name,
      active: template.active
    })
    
  }

  onCancelTemplateUpdate() {
    this.templateId = null;
    this.templateForm.reset();
    this.templateForm.patchValue({active: true})
  }

  onDeleteTemplate(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning', 
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.inboundRouteService.destroyCallerTemplate(id).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Template deleted successfully.", "success")
            this.initializeTemplates();
            if (this.selectedTemplateId === id) {
              this.dataTable.clear();
              this.dataTable.draw();
            }
          }
        })
      }
    });
  }

  onSelectTemplate(id: string) {
    this.dataTable.clear();
    this.dataTable.draw();
    this.selectedTemplate = true;
    this.selectedTemplateId = id;
    this.selectedTemplateData = this.templateList.find(item => item.id == id);
    this.selectedTemplateName = this.selectedTemplateData.template_name;  
    this.initializeTemplates();
    this.updateDataTable(id);   
       
    
  }

  onTemplateSearch(event) {
    const inputElement = event.target as HTMLInputElement;
    const searchText = inputElement.value;
    if (searchText) {
      this.templateListDisplay = this.templateList.filter(item => 
        item.template_name.toLowerCase().startsWith(searchText.toLowerCase())
      );
    } else {
      this.templateListDisplay = this.templateList;
    }
  }

  initializeDatatable(){
    this.dataTable = $('#callerTable').DataTable({
      responsive: true,
      scrollX: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: ['excel', 'pdf', 'csv'],
      columns: [
        { data: "slNo", title: "Sl No"},
        { data: 'caller_name', title: 'Name' },
        { data: 'caller_number', title: 'Phone number' },
        { data: 'l1_name', title: "L1 Name" },
        { data: 'l1_number', title: "L1 Number" },
        { data: 'l2_name', title: "L2 Name" },
        { data: 'l2_number', title: "L2 Number" },
        { data: 'l3_name', title: "L3 Name" },
        { data: 'l3_number', title: "L3 Number" },
        { data: 'l4_name', title: "L4 Name" },
        { data: 'l4_number', title: "L4 Number"},
        { data: 'active', title: 'Status' },
        { data: 'delete', title: "Action"}
      ]
    });
  }

  updateDataTable(template_id: string) {
    this.inboundRouteService.getCallerList(template_id).subscribe((res: any) => {
      if (res.status === "1") {

        this.callerList = res.data.map((item, index) => ({
          ...item,
          slNo: index + 1, 
          active: this.getActiveSwitchHtml(item),
          delete: this.getDeleteHtml(item)
        }));

        this.dataTable.clear();
        this.dataTable.rows.add(this.callerList);
        this.dataTable.draw();

         $('#callerTable tbody').off('change', 'input');

        $('#callerTable tbody').on('change', 'input', (event:any) => {
        const row = this.dataTable.row($(event.target).closest('tr')).data();
        if (row) {
           this.onToggleOnOff(row.id, event);
          }
        });

        $('#callerTable tbody').on('click', `#delete`, (event:any) => {
        const row = this.dataTable.row($(event.target).closest('tr')).data();
        if (row) {
           this.onDeleteCaller(row.id);
          }
        });
      
      } else {
        this.callerList = [];
      }
    })
    
    const table_height = document.querySelector("#caller_list")
    

  }

  onFileSelect(file: FileList) {

    if (file.item(0).name.split(".")[1] === "xlsx") {   
      this.callerFIle = file.item(0);
      this.callerFileValid = true;
      this.callerFileName = file.item(0).name
    }else{
      this.callerFileValid = false;      
    }
    
    const fileInput = document.querySelector('.file-input') as HTMLInputElement;
    fileInput.value = '';
    
  }

  onFileUpload() {
    if (this.callerFIle) {
      this.inboundRouteService.callerFileUpload(this.callerFIle, this.selectedTemplateId).subscribe((res: any) => {
        if (res.status === "1") {
          Swal.fire("Success", "Data uploaded successfully.")
          this.initializeTemplates();
          this.updateDataTable(this.selectedTemplateId);
          this.callerFIle = null;
          this.callerFileName = "Choose a file…";
        } else {
          Swal.fire("Something went wrong!", res.error, "error")
          this.callerFIle = null;
          this.callerFileName = "Choose a file…";
          const fileInput = document.querySelector('.file-input') as HTMLInputElement;
          fileInput.value = '';
        }      
      })
    }
  }

  getCallerList(id: string) {

  }

  getActiveSwitchHtml(item: any): string {
    
    return `
      <input
        id= "input_${item.id}"
        class='mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[""] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[""] checked:bg-blue-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-blue-500 checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[""] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[""] checked:focus:border-blue-500 checked:focus:bg-blue-500 checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-blue-500 dark:checked:after:bg-blue-500 dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]'
        type="checkbox"
        role="switch"
        ${item.active? "checked": ""}
      />`;
  }

  getDeleteHtml(item: any): string {
    return `<i class="fa fa-trash" id="delete" style="color: red;"></i>`;
  }

  onToggleOnOff(caller_id: string, event:any) {
    const data = {
      id: caller_id,
      active: event.target.checked
    }
    this.inboundRouteService.updateCallerRegistration(data).subscribe((res: any) => {
      if (res.status === "1") {
        Swal.fire("Success", "Caller status updated successfully.", "success");
      }else{
        Swal.fire("Something went wrong!", "Failed to update caller status.", "error");
      }
    })
  }

  onDeleteCaller(caller_id: string) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      type: 'warning', 
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.inboundRouteService.destroyCallerRegistration(caller_id).subscribe((res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Caller deleted successfully.", "success");
            this.updateDataTable(this.selectedTemplateId);
          }
        })
      }
    });
  }

  onDownloadFormat() {
    this.inboundRouteService.getcallerRegistrationFormat().subscribe((res: HttpResponse<Blob>) => {
      if (res.body) {
        saveAs(res.body, "Caller Registration.xlsx");
      }
      else {
        Swal.fire("Oops !", "Something went wrong.", "error");
      }
    })
  }

  onRefresh() {
    this.initializeTemplates();  
  }

}
