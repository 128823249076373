import { Injectable } from "@angular/core";
import { SharedService } from "src/global";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class VoiceBoatService{

    constructor(
        private sharedService: SharedService,
        private http: HttpClient
    ){}

    BASE_URL = this.sharedService.getGlobalVar();
    header = new HttpHeaders().set("Content-Type", "application/json")

    addTemplate(data:any) {
        return this.http.post(this.BASE_URL + "router/voiceboat-template/", data, {headers: this.header})
    }

    getTemplate(id: string) {
        return this.http.get(this.BASE_URL + `router/voiceboat-template/${id}/`, {headers: this.header})
    }

    getTemplateList(qParams: any[]) {
        let params = new HttpParams()
        qParams.forEach(param => {
            params = params.append(param.key, param.value)
        })
        return this.http.get(this.BASE_URL + `router/voiceboat-template/`, { headers: this.header, params: params })
    }

    updateTemplate(data: any) {
        return this.http.put(this.BASE_URL + `router/voiceboat-template/${data.id}/`, data, { headers: this.header })
    }

    deleteTemplate(id: string) {
        return this.http.delete(this.BASE_URL + `router/voiceboat-template/${id}/`, { headers: this.header })
    }

    getProviderList() {
        return this.http.get(this.BASE_URL + 'router/voiceboat-provider', {headers: this.header})
    }
}