import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../login.service';
import { BlockedNumbersService } from './blocked.number.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-blocked-number',
  templateUrl: './blocked-number.component.html',
  styleUrls: ['./blocked-number.component.css']
})
export class BlockedNumberComponent implements OnInit {

  showModal = false;
  userProfile: any;
  userData: any;
  didList: any[] = [];
  blocklistForm: FormGroup;
  blockedList: any[] = [];
  selectedFile: File | undefined;
  showUploadInput = false;
  isEdit: boolean = false;
  selectedItem: any = {}
  selectedFileName: string = '';

  callTypeList = [
    { id: "Inbound", itemName: 'Inbound' },
    { id: "Outbound", itemName: 'Outbound' },
    { id: "vsip_outbound", itemName: 'Vsip Outbound' },
    { id: "Dialer", itemName: 'Dialer' }

  ];

  dropdownSettings = {
    singleSelection: false,
    text: "Select DID",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    enableSearchFilter: true,
    classes: "custom-multi-select",
  };

  dropdownSettingsCallType = {
    singleSelection: false,
    text: "Select Call Type",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    enableSearchFilter: true,
    classes: "custom-multi-select",
  };

  constructor(private blockedNumberService: BlockedNumbersService, private loginservice: LoginService, private fb: FormBuilder,) {
    this.blocklistForm = this.fb.group({
      Name: ["", Validators.required],
      Number: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(10)
        ]
      ],
      DID: [[], Validators.required],
      callType: [[], Validators.required]
    })
  }


  ngOnInit() {
    this.onGetDids();
    this.onGetBlokedlist();
  }

  toggleModal() {
    this.showModal = !this.showModal;
    this.blocklistForm.reset();
    this.isEdit = false;

  }

  toggleUploadInput() {
    this.showUploadInput = !this.showUploadInput;
  }

  onGetDids() {
    this.userProfile = JSON.parse(localStorage.getItem("userdata"))
    this.userData = this.loginservice.getUserdata(true);
    const loggedInUserId = this.userData.id;

    this.blockedNumberService.getDids(loggedInUserId).subscribe((response: any) => {
      if (response) {
        this.didList = response.data.map((item) => ({
          id: item.name,
          itemName: item.name.toString()
        }))
      }
    });
  }

  onGetBlokedlist() {
    this.userProfile = JSON.parse(localStorage.getItem("userdata"))
    this.userData = this.loginservice.getUserdata(true);
    const loggedInUserId = this.userData.id;

    this.blockedNumberService.getBlockedlist(loggedInUserId).subscribe((response: any) => {
      this.blockedList = response.data;
    });
  }

  onSubmitForm() {
    if (this.isEdit) {
      this.onUpdateBlockList();
    } else {
      this.onAddToBlockList();
    }
  }

  onAddToBlockList() {
    this.userProfile = JSON.parse(localStorage.getItem("userdata"))
    this.userData = this.loginservice.getUserdata(true);
    const loggedInUserId = this.userData.id;

    if (this.blocklistForm.valid) {
      const addToBlocklist_data = {
        name: this.blocklistForm.value.Name,
        number: this.blocklistForm.value.Number,
        dids: this.blocklistForm.value.DID.map(item => item.itemName),
        call_type: this.blocklistForm.value.callType.map(item => item.id),
        user_id: loggedInUserId
      }
      this.blockedNumberService.addToBlocklist(addToBlocklist_data).subscribe((res: any) => {
        if (res.success === "created") {
          Swal.fire("Success", "Successfully Added To Blocklist", 'success')
          this.showModal = false;
          this.blocklistForm.reset();
          this.onGetBlokedlist();
        } else {
          Swal.fire('Error', res.error, 'error');
          this.showModal = false;
        }
      });
    } else {
      this.showValidationErrors();
      this.showModal = false;
    }
  }

  onDeleteItem(itemId: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this ?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.blockedNumberService.deleteItem(itemId).subscribe((res: any) => {
          if (res.status === 0 && res.delete === 'success') {
            Swal.fire('Deleted!', 'Successfully Deleted.', 'success');
            this.onGetBlokedlist();
          }else if (res.status === 1) {
            Swal.fire('Error!', res.error, 'error');
          } else {
            Swal.fire('Failed!', 'Deletion Failed. Please try again.', 'error');
          }
        });
      }
    });
  }

  onEditList(item: any): void {
    this.showModal = true;
    this.isEdit = true;
    this.selectedItem = item;
    this.blocklistForm.patchValue({
      Name: item.name,
      Number: item.number,
      DID: item.did.map((item) => ({ id: item.toString(), itemName: item.toString() })),
      callType: item.call_type.map((item) => ({ id: item.toString(), itemName: item.toString() }))
    });
  }

  onUpdateBlockList() {
    this.userProfile = JSON.parse(localStorage.getItem("userdata"))
    this.userData = this.loginservice.getUserdata(true);
    const loggedInUserId = this.userData.id;

    if (this.blocklistForm.valid) {
      const did_list = this.blocklistForm.value.DID.map(item => item.itemName)
      const updateBlocklist_data = {
        id: this.selectedItem.id,
        name: this.blocklistForm.value.Name,
        number: this.blocklistForm.value.Number,
        user_id: loggedInUserId,
        dids: did_list.map(value => parseInt(value)),
        call_type: this.blocklistForm.value.callType.map(item => item.id)
      }

      this.blockedNumberService.addToBlocklist(updateBlocklist_data).subscribe((res: any) => {
        if (res.success === "updated") {
          Swal.fire("Success", "Successfully Updated", 'success')
          this.showModal = false;
          this.blocklistForm.reset();
          this.onGetBlokedlist();
        } else {
          Swal.fire('Error', res.error, 'error');
          // this.blocklistForm.reset();
          this.showModal = false;
        }
      });
    } else {
      this.showValidationErrors();
      this.showModal = false;
    }
  }

  private showValidationErrors() {
    Object.keys(this.blocklistForm.controls).forEach(field => {
      const control = this.blocklistForm.get(field);
      if (control && control.invalid) {
        control.markAsTouched({ onlySelf: true });
      }
    });
    Swal.fire('Error', 'Please Fill All Required Fields Correctly', 'error');
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedFileName = file.name;
      this.selectedFile = file;
    } else {
      this.selectedFileName = '';
    }
  }

  onBulkUpload() {
    if (!this.selectedFile) {
      Swal.fire("Error", "Please Select A File", "error");
      return;
    }

    this.userProfile = JSON.parse(localStorage.getItem("userdata"))
    this.userData = this.loginservice.getUserdata(true);
    const loggedInUserId = this.userData.id;

    this.blockedNumberService.bulkUpload(this.selectedFile, loggedInUserId).subscribe((res: any) => {
      if (res.status === "0" && res.message === 'Data uploaded successfully') {
        Swal.fire('Success!', 'File Uploaded Successfully.', 'success');
        this.onGetBlokedlist();
      } else {
        if (res.error && res.error.length > 0) {
          const errorMessage = res.error[0];
          Swal.fire("Failed", errorMessage, "error");
        } else {
          Swal.fire("Failed", "File Uploading Failed", "error")
        }
      }
    }, (error) => {
      Swal.fire("Error", "An Error Occurred While Uploading The File", "error");
      console.error("Error Occurred During File Upload:", error);
    }
    );
  }

  downloadSampleExcel(): void {
    const SampleExcelUrl = '../../assets/BlackList.xlsx';
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', SampleExcelUrl);
    link.setAttribute('download', 'Blocked Numbers Bulk Upload Sample.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onAgentSelect(event) {

  }

  OnAgentDeSelect(event) {

  }

  onAgentSelectAll() {

  }

  onAgentDeSelectAll() {

  }

}
