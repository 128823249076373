import { saveAs } from 'file-saver';
import { Component, OnInit, Output } from '@angular/core';
import { ReportService } from '../inbound-report/report.service'
import { FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment'
import { API_URL, VOICE_URL } from 'src/global';
import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas';
import { ExcelService } from '../inbound-report/excel.service';
import { DatePipe } from '@angular/common';
import { LoginService } from '../login.service';
import { NotificationService } from '../notification/notification.service';
import Swal from 'sweetalert2';
import 'jspdf-autotable';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { templateJitUrl } from '@angular/compiler';
import { callStatusData } from '../callstatus';
import { ReportSettingsService } from '../report-settings/report-settings.service';
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";



@Component({
  selector: 'app-virtualsip-report',
  templateUrl: './virtualsip-report.component.html',
  styleUrls: ['./virtualsip-report.component.css'],
  providers: [DatePipe]
})

export class VirtualsipReportComponent implements OnInit {

  callStatusData:any;
  VirtualsipReportData:any = new Array();
  wordarray:any =new Array();
  current_page: number=1;
  total_page:any;
  last: number;
  fromdate : string='';
  todate: string='';
  goPage: number;
  searchText: string='';
  is_pbx: boolean=false;
  userData:any;
  callStatus:string='';
  myDate = new Date();
  today= new Date(this.myDate.setDate(this.myDate.getDate()));
  today_informat = this.datePipe.transform(this.today, 'yyyy-MM-dd'); 
  ExcelPdfReportData:any = new Array();
  voice_url = VOICE_URL
  permissions:any;
  permis = false;
  isShownprogressbar=false;
  progress: number=0;
  public id:any;
  userType:any;
  mobile_number:any;
  userProfileId :any;
  phone : any;

  addresspopup:Boolean=false;
  mobile_num:string="";
  cont_name:string="";
  cont_mail:string="";
  cont_id:string="";
  cont_error:string="";
  contact:any;
  contact_initial:any;

  reportForm:FormGroup;

  commentopen: boolean = false;
  customsmsopen: boolean = false;
  comment:any
  call_id:any;
  callDirection: string = '';
  tagList: any = new Array()
  tag_filter: any = ""
  showTagDiv: boolean = false
  vsip_user_name: string=""

  defaultDateFrom: Date;
  defaultDateTo: Date;

  constructor(
    private reportservice: ReportService,
    private excelService:ExcelService,
    private datePipe:DatePipe,
    private loginservice:LoginService,
    private notifyService: NotificationService,
    private reportsettingservice: ReportSettingsService,
    private spinnerService: Ng4LoadingSpinnerService

  ) { }

  ngOnInit() {

    const defaultDate = new Date();
    this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);

    this.callStatusData=callStatusData

    this.userData = this.loginservice.getUserdata(true);
    this.permissions = this.userData.permissions
    this.userType = localStorage.getItem('userType')

    console.log("======callStatusData=====",this.callStatusData)
    this.getCallTags()
    this.FetchVirtualsipReportData(1, false);
  }
  

  onFromDateChange(newValue: any) {
    if (newValue && newValue !== "") {
      this.defaultDateFrom = new Date(this.datePipe.transform(newValue, "yyyy-MM-dd HH:mm:ss"));
    } else {
      const defaultDate = new Date();
      this.defaultDateFrom = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0);
    }
  }
  onToDateChange(newValue: any) {
    if (newValue && newValue !== "") {
      this.defaultDateTo = new Date(this.datePipe.transform(newValue, "yyyy-MM-dd HH:mm:ss"));
    } else {
      const defaultDate = new Date();
      this.defaultDateTo = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 23, 59, 59);
    }
  }
  
  FetchVirtualsipReportData(pageNo, validate = true){

    if (validate) {
      if (!this.fromdate && this.todate) {
        this.spinnerService.hide();
        alert("From date is required.");
        return;
      }

      if (this.fromdate && this.todate) {
        const fromDate = new Date(this.fromdate);
        const toDate = new Date(this.todate);

        // Validate that `fromdate` is not greater than `todate`
        if (fromDate > toDate) {
          this.spinnerService.hide();
          alert("From date cannot be greater than To date.");
          return;
        }
      }
    }
    if (this.userType=='virtualUser') {
      this.vsip_user_name = this.userData.phone 
   
     }


    var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');

    this.reportservice.getVirtualsipReport(this.userData.id, from, to ,this.searchText, this.callStatus,this.callDirection,pageNo,"",this.userType, this.tag_filter, this.vsip_user_name).subscribe(
      (res:any) => {
        this.VirtualsipReportData = res["results"]
        this.total_page = res['total_pages']
        this.current_page = res['current_page']
        this.last = res['total_pages']
      }
    )
    

  }



  searchBox(){
    
    this.FetchVirtualsipReportData(1, true)
  }

  getcallrecord(vffile,date,call_id,file_type="callrecord"){
    if (!this.fromdate && this.todate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.fromdate && this.todate) {
      const fromDate = new Date(this.fromdate);
      const toDate = new Date(this.todate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }
    var from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to = this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');

    this.reportservice.getcallrecord(vffile,date,call_id,file_type).subscribe(
      (res:any) => {
        this.reportservice.getVirtualsipReport(this.userData.id, from, to, this.searchText, this.callStatus, this.callDirection,this.current_page,"", this.userType, this.tag_filter, this.vsip_user_name).subscribe(
          (res: any) => {
            this.VirtualsipReportData = res["results"]
            this.total_page = res["total_pages"]
            this.current_page = res['current_page']
            this.last = res['total_pages']
            this.notifyService.sendNotification('Downloaded', 'Download Voice successfully', 'is-success')
          }
        )
      }
    )

  }

  exportAsXLSX():void {
    if (!this.fromdate && this.todate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.fromdate && this.todate) {
      const fromDate = new Date(this.fromdate);
      const toDate = new Date(this.todate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }
    var text;
    var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');

    this.reportservice.getVirtualsipReport(this.userData.id, from, to,this.searchText,this.callStatus,this.callDirection,this.current_page,"yes",this.userType, this.tag_filter, this.vsip_user_name).subscribe(
      (res:any) => {
          this.ExcelPdfReportData = res["results"]
    for (let element of this.ExcelPdfReportData) {
      var objz = {'DID':element['DID'],'Date':element['legAstartTime'],'Customer':element['callerid'],'Customer Name':element['customer'] ? element['customer']['name'] || '' : '','Country code':element['country_code'],
      'Virtual user_id':element['answeredAgent'],'Agent Name':element['agentName'],
      'Destination Name':element['queueName'],'Duration':element['duration'],'Connected Duration':element['connectedDuration'],'callDirection':element['callDirection'],
     'Comment':element['comment'], 'Tag':element['tag'],'Status':element['callStatus'], 'Call Status Description':this.callStatusData[element['callstatus_code']|| element['callStatus']]}

          this.wordarray.push(objz)
        }
   if (this.fromdate, this.todate){
      text = "Virtual Report from: "+from+" To: "+to+""
    }
    else{
      text = "Virtual Report of "+ this.today_informat
    }
    this.excelService.exportAsExcelFile(this.wordarray, text);
    this.wordarray=[];

  })
}

  downloads() {
    if (!this.fromdate && this.todate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.fromdate && this.todate) {
      const fromDate = new Date(this.fromdate);
      const toDate = new Date(this.todate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }
    var countSlNo = 1
    var newarray = []
    var head = [['SlNo','DID', 'Date', 'Customer','Customer name', 'Country code','Virtual user_id', 'Agent Name', 'Destination Name','Duration','Connected Duration','callDirection','Comment', 'Tag', 'Status', 'Call Status Description']]
    var from = this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to = this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');
    this.reportservice.getVirtualsipReport(this.userData.id, from, to, this.searchText, this.callStatus, this.callDirection,this.current_page, "yes", this.userType, this.tag_filter, this.vsip_user_name).subscribe(
      (res:any) => {
        this.ExcelPdfReportData = res["results"]
        for (let element of this.ExcelPdfReportData) {
          newarray.push([
            countSlNo,
            element['DID'],
            element['legAstartTime'],
            element['callerid'],
            element['customer'] ? element['customer']['name'] || '' : '',,
            element['country_code'],
            element['answeredAgent'],
            element['agentName'],
            element['queueName'],
            element['duration'],
            element['connectedDuration'],
            element['callDirection'],
            element['comment'],
            element['tag'],
            element['callStatus'],
            this.callStatusData[element['callstatus_code'] || element['callStatus']]

          ])
          countSlNo = countSlNo + 1
        }
        var pdf = new jsPDF();
        pdf.text('Virtualsip Report', 11, 8);
        pdf.setFontSize(3);
        pdf.setTextColor(100);
        (pdf as any).autoTable({
          head: head,
          body: newarray,
          theme: 'grid',
          styles : {fontSize: 4},
          tableWidth: 'auto',
          columnStyles: { 0 : { halign: 'center', fillColor: [0,255,0]}},
          margin: { top:10 },
        })
        pdf.output('dataurlnewwindow')
        pdf.save('Virtualsipreport.pdf');
      }
    )
  }

  fetchNumber(i) {
    if(i == 'prev'){
      if (this.current_page > 1){
        this.FetchVirtualsipReportData(this.current_page-1, true);
      }
    }
  
    else if (i == 'next'){
      if (this.current_page < this.last){
        this.FetchVirtualsipReportData(this.current_page+1, true);
      }
     
    }
  
    else if(i == 'first'){
      this.FetchVirtualsipReportData(1, true);
    }
  
    else if(i == 'last'){
      this.FetchVirtualsipReportData(this.last, true);
    }	
    else{
  
      if (parseInt(i)=== parseInt(i, 10) && i > 0 && i <=  this.last){
        this.FetchVirtualsipReportData(i, true);
      }
      else{
        Swal.fire('Oops..!', 'Enter Valid Number', 'warning');
      }
  
    }
  
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }


  DownloadallVoice(){
    if (!this.fromdate && this.todate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.fromdate && this.todate) {
      const fromDate = new Date(this.fromdate);
      const toDate = new Date(this.todate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }
    var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');
    this.reportservice.getVirtualsipReport(this.userData.id, from, to,this.searchText,this.callStatus,this.callDirection,this.current_page,"voice_download_yes",this.userType, this.tag_filter, this.vsip_user_name).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
      case HttpEventType.Sent:  
        this.isShownprogressbar=true;
        console.log('Request has been made!');
        break;
      case HttpEventType.ResponseHeader:
        console.log('Response header has been received!');
        break;
      case HttpEventType.DownloadProgress:
        this.progress = Math.round(event.loaded / event.total * 100);
        console.log(`Uploaded! ${this.progress}%`);
        break;
      case HttpEventType.Response:
        console.log('User successfully created!', event.body);
        var a = document.createElement('a');
        var blob = new Blob([ event.body], {'type':"application/zip"});
        a.href = URL.createObjectURL(blob);
        if (from && to){
          a.download = from+'_'+to+".zip";
          }
        else{
          a.download = "Virtual"+this.today_informat+".zip";
        }
        a.click();      
        setTimeout(() => {
          this.progress = 0;
          this.isShownprogressbar=false;
        }, 1500);
    }
  })
  
  }

  async onNavigate(){
    var url_array = []
    for (let element in this.VirtualsipReportData){
      var x = this.VirtualsipReportData[element]
      console.log(this.voice_url)
      if(x['callStatus'] == "ANSWERED"){
        if(x['callRecordVF'] !== ""){
          var voice_date=this.datePipe.transform(x['legAstartTime'], 'yyyy-MM-dd');
          let url="http:"+this.voice_url+voice_date+"/"+x['callRecordVF']+"";

          console.log(url)
          url_array.push(url)
          var link = document.createElement("a");
          link.download = "a";
          link.href = url;
          link.download=url;
          link.click();
          await this.delay(3000);
        } 
     }
    }

    console.log("=================url_array=================", url_array)
    Swal.fire('Success', 'Downloaded Successfully', 'success')
  }


  LoadPage(){
    if (!this.fromdate && this.todate) {
      this.spinnerService.hide();
      alert("From date is required.");
      return;
    }

    if (this.fromdate && this.todate) {
      const fromDate = new Date(this.fromdate);
      const toDate = new Date(this.todate);

      // Validate that `fromdate` is not greater than `todate`
      if (fromDate > toDate) {
        this.spinnerService.hide();
        alert("From date cannot be greater than To date.");
        return;
      }
    }
    var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd HH:mm:ss');
    var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd HH:mm:ss');
    this.reportservice.getVirtualsipReport(this.userData.id, from, to,this.searchText,this.callStatus,this.callDirection,this.current_page,"",this.userType, this.tag_filter, this.vsip_user_name).subscribe(
      (res:any) => {
        this.VirtualsipReportData = res["results"]
        this.total_page =  res['total_pages']
        this.current_page = res['current_page']
        this.last = res['total_pages']
      })
  }

  closeContact() {
    this.addresspopup = false;
    this.contact = this.contact_initial;
  }

  openContact(mobile, id) {
    this.addresspopup = true;
    this.contact.number = mobile;
    if (id) {
      this.reportservice
        .getcontact(id, this.userData.id, this.userType)
        .subscribe((res: any) => {
          if (res.id) {
            this.contact = res;
          } else {
            this.notifyService.sendNotification(
              "",
              "Error in Getting conatct",
              "is-danger"
            );
          }
        });
    }
  }


  CloseComment(){
    console.log("=============TRYING TO CLOSE 2====")
    this.commentopen = false
    this.customsmsopen = false
    this.call_id=""
    this.comment =""
  }

  updateReportPage() {
    this.FetchVirtualsipReportData(this.current_page, true)
  }

  addCallRecordTag(call_record_id: any, index: any) {
    this.reportsettingservice.getReportTagChild(call_record_id).subscribe((res: any) => {
      const updatedTags = res.tag.split(',').map(tag => tag.trim());
      this.VirtualsipReportData[index].tag = updatedTags.join(', ');
    })
  }

  deleteCallRecordTag(call_record_id: any, tag: any, index: any) {
    const body = {
      tag: tag
    }

    this.reportsettingservice.deleteReportTagChild(call_record_id, body).subscribe((res: any) => {
      this.VirtualsipReportData[index].tag = res.tags.tag
    })
  }

  getCallTags() {
    let user_id: any
    if (this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser'  || this.userData.usertype == 'virtualUser') {
      user_id = this.userData.admin;
    } else {
      user_id = this.userData.id;
    }

    this.reportsettingservice.getReportTagMain(user_id).subscribe((res: any) => {
      this.tagList = res.report_tags
    })
  }


}
