import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SendDataIntegrationService } from './send-data-integration.service';
import { LoginService } from '../login.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-send-data-integration',
  templateUrl: './send-data-integration.component.html',
  styleUrls: ['./send-data-integration.component.css']
})
export class SendDataIntegrationComponent implements OnInit {
  public userData: any;

  form: FormGroup;
  formats: string[] = ['Ewire', 'kylas', 'Zenys', 'meritto',  'leadsquared', 'getlead' , 'salesforce']; 
  fields: any[] = [];
  UserProfileId: number = 0;
  user: any;
  userProfileData: any = {};    
  fieldValues: { [key: string]: any } = {}; 

  dataList: any[] = [];
  selectedFormat: any;
  editingData: any; 



  isEditMode = false;
  editItemId: number | null = null;

  selectedData: any; 
  editForm: FormGroup;

  isEditing = false;
  editFields: string[] = [];
  selectedId: number | null = null; 
  format: string;      

  grp: any;
  currentEditId: number | null = null;  



  constructor(
    private fb: FormBuilder,
    private sendDataService: SendDataIntegrationService,
    private loginservice: LoginService,

  ) { 
      // format: ['', Validators.required],
      this.form = this.fb.group({
        userProfile: [''], 
        format: ['', Validators.required], 
        ...this.fields.reduce((controls, field) => {
          controls[field] = ['']; 
          return controls;
        }, {})
      })
    
    this.editForm = this.fb.group({
      format: ['', Validators.required],
    });

  }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(false);

    this.UserProfileId = this.userData.id

    this.grp = this.userData.group


    this.loadData()

  }

  




  onFormatChange(selectedFormat: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.fields = [];
      Object.keys(this.form.controls).forEach(control => {
        if (control !== 'format') {
          this.form.removeControl(control);
        }
      });

      this.sendDataService.getFormatFields(selectedFormat).subscribe((response: any) => {
        if (response && response.fields && typeof response.fields === 'object') {
          this.fields = Object.keys(response.fields);

          this.fields.forEach(field => {
            const initialValue = this.isEditing ? '' : response.fields[field] || '';
            this.form.addControl(field, new FormControl(initialValue, Validators.required));
          });
        } else {
          console.error('Unexpected response format:', response);
        }
        resolve(); 
      });
    });
  }


  customerEvent(event: any): void {
    this.userProfileData = event;
    console.log('User profile data:', this.userProfileData);
  }

  




  onSubmit(): void {
    let userProfileId = this.userProfileData; 

    if (this.grp !== 'superadmin') {
      userProfileId = this.userData.id
    }

    const formData = {
      userProfile: this.userProfileData, 
      ...this.form.value, 
    };

    console.log('Form Data:', formData);

    this.sendDataService.saveData(formData).subscribe(response => {
      console.log('Data saved successfully:', response);
      this.form.reset(); 
      this.loadData()

      Swal.fire({
        title: 'Success!',
        text: 'Data saved successfully.',
        confirmButtonText: 'OK'
      });
    }, error => {
      Swal.fire({
        title: 'Error!',
        text: 'There was a problem saving the data.',
        confirmButtonText: 'Try Again'
      });
      console.error('Error saving data:', error);
    });
  }



  resetForm(): void {
    this.form.reset();
    this.isEditMode = false;
    this.editItemId = null;
    this.UserProfileId = null;
  }


  loadData(): void {
    this.sendDataService.getDataList().subscribe(
      (res: any) => {
        if (res.status === "1") {
          this.dataList = res.data
        }
        else {
          console.log("failed to fetch")
        }
      }
      
    );
  }


  






  onEdit(id: number): void {
    this.currentEditId = id;  

    this.sendDataService.getDataById(id).subscribe((data: any) => {
      this.form.patchValue(data);

      this.selectedFormat = data.format;
      this.isEditing = true;

      this.onFormatChange(this.selectedFormat).then(() => {
        const customData = data.custom_data ? JSON.parse(data.custom_data) : data;

        this.fields.forEach(field => {
          const value = customData[field] !== undefined ? customData[field] : '';
          this.form.controls[field].setValue(value);
        });
      });
    });
  }

  onUpdate(): void {
    if (!this.currentEditId) return;

    let userProfileId = this.userProfileData;


    if (this.grp !== 'superadmin') {
      userProfileId = this.userData.id
    }

    const formData = {
      userProfile: this.userProfileData,
      ...this.form.value,
    };

    this.sendDataService.updateData(this.currentEditId, formData).subscribe(
      response => {
        console.log('Data updated successfully:', response);
        this.form.reset();
        this.isEditing = false;
        this.currentEditId = null;

        Swal.fire({
          title: 'Success!',
          text: 'Data updated successfully.',
          confirmButtonText: 'OK'
        });
      },
      error => {
        console.error('Error updating data:', error);
        Swal.fire({
          title: 'Error!',
          text: 'There was a problem updating the data.',
          confirmButtonText: 'Try Again'
        });

      }
    );
  }





  onDelete(id: number): void {
    console.log("ID to delete:", id); 

    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this item?",
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) { 
        this.sendDataService.deleteDataById(id).subscribe({
          next: (response) => {
            Swal.fire('Deleted!', 'Your item has been deleted.', 'success');

            this.dataList = this.dataList.filter(item => item.id !== id);
          },
          error: (error) => {
            console.error("Delete error:", error); 
            Swal.fire('Error!', 'There was a problem deleting the item.', 'error');
          }
        });
      } else {
        Swal.fire('Cancelled', 'Your item is safe :)', 'info');
      }
    });
  }



}
