import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder,FormGroup, FormControl, Validators } from '@angular/forms';
import { InboundRouteService } from '../inbound-route.service';
import { LoginService } from '../../login.service';
import { QueuemanagementService } from 'src/app/queuemanagement.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { IvrService } from 'src/app/ivr/ivr.service';
import { ExtensionService } from 'src/app/extension/extension.service';
import { VoiceRecordService } from 'src/app/voice-record/voice-record.service';
import { VoicemailService } from 'src/app/voicemail/voicemail.service';
import { CallTransferService } from 'src/app/call-transfer/call-transfer.service';
import { VoiceBoatService } from '../voiceboat-template/voiceboat.service';


@Component({
  selector: '[app-inbound-route-detail]',
  templateUrl: './inbound-route-detail.component.html',
  styleUrls: ['./inbound-route-detail.component.css']
})
export class InboundRouteDetailComponent implements OnInit {
  public userData: any;
  description: string = '';
  keyword: string = 'name';
  didList: any;
  DIDNO: number = 0;
  DIDInitial: any;
  channelId: any;
  musicOnHold: string = '';
  destinationType: string = '0'
  destiny: boolean = false;
  destinyFO: boolean = true;
  destinationList: any;
  destination: string = '';
  destinationInitail: string = '';
  destinationInitailFO: string = '';
  timeConditionList: any;
  timeCondition: any;
  initialTimeCondition: any;
  matchPatternData: any;
  matchPatterCheckList: any = new Array();
  inboundRouteData: any;
  getInboundRouteData: any;
  routeTimeCondtionData: any;
  routeMatchPatterData: any;
  getRouteMatchpattern: any = new Array();
  getRouteTimeCondition: any;
  routeTimeId: number = 0
  mpStatus: any;
  id: number = 0
  MusicOnHoldLabel: string = 'Music on Hold'
  VoiceAnnouncementLabel: string = 'Voice Announcement'
  destinyLabel: string = ''
  destiny_Label: string = ''
  voicelist: any;
  musicOnHoldInitial: any;
  timeSetId: number = 0
  isAdmin: string = 'false'
  UserProfileId: number = 0;
  customerInitial: any;
  customerList: any = new Array();
  isEdit: boolean = true;
  timeConditionSelect: string;
  dest: boolean;
  destType: string = '0';
  destinationOne: string;
  destinyLabel0ne: string;
  destinyLabelOne: string;
  destinationListOne: any[];
  destinationListFO: any[];
  OutOfdestinationOne: any;
  patterMatchList: any;
  callerIdType: string = '3';
  callType: boolean = false;
  callerDIDNumber: string = '';
  btnActive: boolean;
  callerDIDInitial: any;
  extBtn: boolean;
  type: any;
  CallType: any;
  destination_api_url:string="";
  allow_call_transfer: boolean = false
  call_transfer_template: any
  callTransferList: any[]
  blackListForm: FormGroup;
  blackList:any[];
  blackListSumbmitted:Boolean = false;
  permissions:any; 
  public cadmin_w_callTransfer: string
  callerIDList:any[];
  enableExternalResponse: boolean = false;
  enableCallerRestriction: boolean = false;
  visibelCallerRestiction: boolean = true;
  selectedCallerTemplate: string | null = null;
  callerTemplateList: any[] = [];
  enableAgentRestiction: boolean = false;
  visibleAgentRestriction: boolean = false;
  dtmf_mode: any;
  showBlackListForm:boolean = false;
  destinationTypeFO: string = ''
  destinationFO: string = '';
  apiTemplate: string | null = null;
  visibleApiTemplate: boolean = false;
  apiTemplateList: any[] = [];
  visibleVoiceBotTemplate: boolean = false;
  voicebotTemplate: string | null = null;
  voicebotTemplateList: any[] = [];
  feedbackVoice: string | null = null;
  feedbackVoiceInitial: any;
  feedbackVoiceLabel: string = 'Feedback Voice'
  enableFeedback: boolean = false;

  constructor(
    private loginservice: LoginService,
    private inboundservice: InboundRouteService,
    private queueservice: QueuemanagementService,
    private route: Router,
    private activeroute: ActivatedRoute,
    private ivrservice: IvrService,
    private extensionservice: ExtensionService,
    private voicerecodrservice: VoiceRecordService,
    private formBuilder: FormBuilder,
    private voicemailservice: VoicemailService,
    private calltransferservice: CallTransferService,
    private vbService: VoiceBoatService
  ) { 

    this.blackListForm = this.formBuilder.group({
      blackListName: ['',[Validators.required]],
      blackListNumber: ['',[Validators.required, Validators.pattern(/^\d{10}$/)]]
    })


  }
    

  ngOnInit() {
    this.isAdmin = localStorage.getItem('isAdmin')
    this.userData = this.loginservice.getUserdata(false);
    this.permissions = this.userData.permissions
    if (this.isAdmin == 'false') {
      this.UserProfileId = this.userData.id
    } else {
      let userID = JSON.parse(localStorage.getItem('customer'))
      if (userID != null) {
        this.UserProfileId = userID.id
      }
    }

		for (const perm of this.permissions){
      if (perm.codename=='cadmin_w_callTransfer')
			{
				this.cadmin_w_callTransfer="true"
			}
    }

    this.dtmf_mode = '1'; 

    this.onFocusedDID("e"," ","all")
    this.getCallerTemplates();
    this.id = +this.activeroute.snapshot.paramMap.get('id');
    this.inboundservice.getMatchPatterns(this.UserProfileId).subscribe(
      (data: any) => {
        this.matchPatternData = data
        if (this.id != 0) {
          this.inboundservice.getRouteMatchPatterns(this.id).subscribe(
            (data: any) => {
              this.getRouteMatchpattern = data
              for (let mp of this.matchPatternData) {
                for (let getMp of this.getRouteMatchpattern) {
                  if (mp['id'] == getMp['matchpatterns']) {
                    mp['checked'] = true
                    break;
                  } else {
                    mp['checked'] = false
                  }
                }
              }

            });
          this.inboundservice.getInboundRoute(this.id).subscribe(
            (res: any) => {
              this.enableCallerRestriction = res.caller_restriction
              this.enableAgentRestiction = res.agent_restriction
              this.selectedCallerTemplate = res.caller_template
              this.apiTemplate = res.api_template
              this.voicebotTemplate = res.voicebot_template
              this.enableExternalResponse = res.sendResponseToexternalAPI === "true"   
              this.blackList = res.blacklist
              this.UserProfileId = res.user
              this.isEdit = false
              this.loginservice.getCustomerList().subscribe(
                (data: any) => {
                  this.customerList = data
                  for (let customerData of this.customerList) {
                    if (customerData.id == res.user) {
                      this.customerInitial = customerData.name
                    }
                  }
                }
              )
              this.description = res.description
              this.DIDNO = res.didNumber
              this.destination_api_url=res.destination_api_url
              this.GetCallerIdPerDID(this.DIDNO)
              

              this.inboundservice.getDIDList(this.UserProfileId).subscribe(
                (data: any) => {
                  this.didList = data
                  for (let did of this.didList) {
                    if (did.name == this.DIDNO) {
                      this.DIDInitial = did.name
                    }
                  }
                }
              )
              this.channelId = res.channelId
              this.musicOnHold = res.musiconhold
              this.feedbackVoice = res.feedback_voice
              this.enableFeedback = res.feedback_enabled
              if (this.enableFeedback) {
                this.feedbackVoice = res.feedback_voice
              }
              if (this.destinationType != '3') {
                this.destinationInitail = res.Destination
              }
              if (this.destinationTypeFO != '3') {
                this.destinationInitailFO = res.destinationFO
              }
              this.voicerecodrservice.getVoiceRecordList(this.UserProfileId, 0).subscribe(
                (data: any) => {
                  this.voicelist = data
                  for (let voiceData of this.voicelist) {
                    if (voiceData.fileUrl == this.musicOnHold) {
                      this.musicOnHoldInitial = voiceData.name
                    }
                    if (voiceData.fileUrl == this.feedbackVoice) {
                      this.feedbackVoiceInitial = voiceData.name
                    }
                    if (this.destinationType == '3') {
                      console.log("---destinationType 3---")
                      if (voiceData.fileUrl == this.destination) {
                        console.log("---voiceData.fileUrl == this.destination---")
                        this.destinationInitail = voiceData.name
                        this.OutOfdestinationOne = voiceData.name
                        // this.destinationInitailFO = voiceData.name
                      }
                    }
                    if (this.destinationTypeFO == '3') {
                      if (voiceData.fileUrl == this.destinationFO) {
                        this.destinationInitailFO = voiceData.name
                      }
                    }
                  }
                }
              )

              this.destinationType = res.destinationType;
              this.destinationTypeFO = res.destinationTypeFO;
              this.destType = res.outOfTimedestinationType;

              this.callerIdType = res.callerType
              this.callerDIDNumber = res.callerid


              if (this.callerIdType == '3' || this.callerIdType == '2') {
                this.callType = true;
                this.callerDIDInitial = res.callerid
              }

              this.onDestinyType();
              this.destination = res.Destination
              this.destinationFO = res.destinationFO
              this.timeSetId = res.timeConditionSet
              this.OutOfdestinationOne = res.outOfTimeDestinationName;
              this.destinationOne=res.outOfTimeDestinationName
              this.allow_call_transfer = res.allow_call_transfer
              this.call_transfer_template = res.call_transfer_template
              if (res.dtmf_mode){
              this.dtmf_mode = res.dtmf_mode;
              }

              if(res.allow_call_transfer = true)
                this.calltransferservice.getCallTransferTemplate(this.UserProfileId).subscribe(
                  (data:any) => {
                    this.callTransferList = data
                    for (let callTransfer of this.callTransferList) {
                      if (callTransfer.id == this.call_transfer_template) {
                        this.call_transfer_template = callTransfer.name
                      }
                    }  
                  }
              )
            }
          )
        }
      }
    )
  }
  toggleBlackListForm() {
    this.showBlackListForm = !this.showBlackListForm;
  }

  customerEvent(data) {
    this.UserProfileId = data
    this.inboundservice.getMatchPatterns(this.UserProfileId).subscribe(
      (data: any) => {
        this.matchPatternData = data
      }
    )
    if (this.UserProfileId == 0) {
      this.clearDatas();
      console.log('cleared')
    } else {
      this.inboundservice.getDIDList(this.UserProfileId).subscribe(
        (data: any) => {
          this.didList = data
        }
      )
    }
  }

  clearDatas() {
    this.DIDNO = 0
    this.description = ''
    this.channelId = ''
    this.musicOnHold = '',
      this.destinationType = '0'
    this.destinationTypeFO = '0'
    this.destination = ''
  }

  // DID AutoCompleted Functions

  selectDID(item) {
    this.DIDNO = item.id
    console.log("================DID NO====", item.name)
    this.GetCallerIdPerDID(item.name )
  }



GetCallerIdPerDID(did){
  this.inboundservice.getCallerIdList(did).subscribe(
    (data: any) => {
      console.log("==========CALLER IDS=====", data['caller_ids'])
      this.callerIDList = data['caller_ids']
      console.log('search did list', this.didList)
    }
  )

}



  selectcallerDID(item) {
    this.callerDIDNumber = item.id
  }

  onChangeSearchDID(val: string, channel: string) {
    this.inboundservice.searchInboundDIDList(val, this.UserProfileId, this.destination).subscribe(
      (data: any) => {
        this.didList = data
        console.log('search did list', this.didList)
      }
    )
  }

  onFocusedDID(e, channel: string, type) {
    if (channel == "samechannel") {
      this.inboundservice.getInboundDIDList(this.UserProfileId, this.destination, type).subscribe(
        (data: any) => {
          this.didList = data
          console.log('did focused list', this.didList)
        }
      )
    }
    else {
      this.inboundservice.getInboundDIDList(this.UserProfileId, "notQueue", type).subscribe(
        (data: any) => {
          this.didList = data
          console.log('did focused list', this.didList)
        }
      )
    }

  }

  onClearDID() {
    this.DIDNO = 0
    this.onFocusedDID("e"," ","all")
  }

  // Music on Hold File Upload Function

  onMusicPath(data) {
    console.log('data', data)
    this.musicOnHold = data
  }

  // Desitnation Type Select Function

  onDestinyType() {
    if (this.destinationType == '1') {
      this.destiny = true;
      this.destination = ''
      this.destinyLabel = 'Select IVR';
      this.btnActive = true;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;
      this.visibleApiTemplate = false;
      this.apiTemplate = null;
      this.visibleVoiceBotTemplate = false;
      this.voicebotTemplate = null;

    } else if (this.destinationType == '2') {
      this.destiny = true;
      this.destination = ''
      this.destinyLabel = 'Select Department'
      this.btnActive = true;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;
      this.visibleApiTemplate = false;
      this.apiTemplate = null;
      this.visibleVoiceBotTemplate = false;
      this.voicebotTemplate = null;

    } else if (this.destinationType == '3') {
      this.destiny = false;
      this.destination = 'Voice File'
      this.btnActive = false;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;
      this.visibleApiTemplate = false;
      this.apiTemplate = null;
      this.visibleVoiceBotTemplate = false;
      this.voicebotTemplate = null;
      

    } else if (this.destinationType == '4') {
      this.destiny = true;
      this.destination = ''
      this.destinyLabel = 'Select Time Condition';
      this.timeConditionSelect = "timeConditionSelect";
      this.btnActive = true;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;
      this.visibleApiTemplate = false;
      this.apiTemplate = null;
      this.visibleVoiceBotTemplate = false;
      this.voicebotTemplate = null;

    } else if (this.destinationType == '5') {
      this.destiny = true;
      this.destination = ''
      this.destinyLabel = 'Select Extension';
      this.btnActive = true;
      this.visibelCallerRestiction = false;
      this.visibleAgentRestriction = true;      
      this.enableCallerRestriction = false;
      this.visibleApiTemplate = false;
      this.apiTemplate = null;
      this.visibleVoiceBotTemplate = false;
      this.voicebotTemplate = null;      

    } else if (this.destinationType == '6') {
      this.destiny = true;
      this.destination = 'API'
      this.destinyLabel = 'Enter API Url';
      this.btnActive = true;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;
      this.visibleApiTemplate = true;
      this.getAPITemplates();
      this.visibleVoiceBotTemplate = false;
      this.voicebotTemplate = null;

    }else if (this.destinationType == '7') {
      this.destiny = true;
      this.destination = 'AutoCall-CallBack'
      this.btnActive = true;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;
      this.visibleApiTemplate = false;
      this.apiTemplate = null;
      this.visibleVoiceBotTemplate = false;
      this.voicebotTemplate = null;
    }
    else if (this.destinationType == '10') {
      this.destiny = true;
      this.destinyLabel = 'Select Voicemail Template'
      this.destination = 'Voicemail'
      this.btnActive = true;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;
      this.visibleApiTemplate = false;
      this.apiTemplate = null;
      this.visibleVoiceBotTemplate = false;
      this.voicebotTemplate = null;

    } else if (this.destinationType == "13") {
      this.destiny = false;
      this.destination = 'VOICEBOT'
      this.destinyLabel = '';
      this.btnActive = true;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;
      this.visibleApiTemplate = false;
      this.visibleVoiceBotTemplate = true;
      this.getVoicebotTemplates();
    }
    else {
      this.destiny = false;
      this.destination = ''
      this.btnActive = false;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;
      this.visibleApiTemplate = false;
      this.apiTemplate = null;
      this.visibleVoiceBotTemplate = false;
      this.voicebotTemplate = null;
    }
  }
  // Desitnation Type Fail Over Select Function

  onDestinyTypeFO() {
    if (this.destinationTypeFO == '1') {
      this.destinyFO = true;
      this.destinationFO = ''
      this.destiny_Label = 'Select IVR';
      this.btnActive = true;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;

    } else if (this.destinationTypeFO == '2') {
      this.destinyFO = true;
      this.destinationFO = ''
      this.destiny_Label = 'Select Department'
      this.btnActive = true;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;

    } else if (this.destinationTypeFO == '3') {
      this.destinyFO = false;
      this.destinationFO = 'Voice File'
      this.btnActive = false;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;
      

    } else if (this.destinationTypeFO == '10') {
      this.destinyFO = true;
      this.destiny_Label = 'Select voicemail template'
      this.destinationFO= 'Voicemail'
      this.btnActive = true;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;
    }
    else {
      this.destinyFO = false;
      this.destinationFO = ''
      this.btnActive = false;
      this.visibelCallerRestiction = true;
      this.visibleAgentRestriction = false;
    }
  }

  onDestType() {
    if (this.destType == '1') {
      console.log("hii");
      this.dest = true;
      this.destinationOne = ''
      this.destinyLabelOne = 'Select IVR';
      this.btnActive = false;

    } else if (this.destType == '2') {
      console.log("hpooo");
      this.dest = true;
      this.destinationOne = ''
      this.destinyLabelOne = 'Select Department'
      this.btnActive = true;
      // this.extBtn = false;


    } else if (this.destType == '3') {
      this.dest = false;
      this.destinationOne = 'Voice File'
      this.btnActive = false;
      // this.extBtn = true;


    } else if (this.destType == '5') {
      this.dest = true;
      this.destinationOne = ''
      this.destinyLabelOne = 'Select Extension';
      this.btnActive = true;
    } else if (this.destType == '6') {
      this.dest = true;
      this.destinationOne = ''
      this.destinyLabelOne = 'hhdhh';
      this.btnActive = true;
    }
    else {
      this.dest = false;
      this.destinationOne = ''
      this.btnActive = false;

    }
  }

  // Queue Autocompleted Functions

  selectQueue(item) {
    this.destination = item.name
    if (this.destinationType == '4') {
      this.timeSetId = item.id
    }
  }

  onChangeSearchQueue(val: string) {
    if (this.destinationType == '1') {
      this.ivrservice.searchIVR(val, this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationList = data
          console.log('search ivr list', this.destinationList)
        }
      )
    } else if (this.destinationType == '2') {
      this.queueservice.searchQueue(val, this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationList = data
          console.log('search queue list', this.destinationList)
        }
      )
    } else if (this.destinationType == '4') {
      this.inboundservice.searchTimeConditionSet(val, this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationList = data
          console.log('search time condition list', this.destinationList)
        }
      )
    } else if (this.destinationType == '5') {
      this.extensionservice.searchExtension(val, this.UserProfileId).subscribe(
        (data: any) => {
          for (let e of data) {
            if (e != undefined) {
              this.destinationList.push(e.didNumber)
            }
          }
          console.log('search extension list', this.destinationList)
        }
      )
    }
    else if(this.destinationType == '10'){
      this.voicemailservice.getVoicemailTemplate(this.userData.id).subscribe(
        (data:any) => {
          this.destinationList = data
          console.log('search Vociemail list', this.destinationList)
        }
      )
    }
  }

  onFocusedQueue(e) {
    if (this.destinationType == '1') {
      this.ivrservice.GetIVRList(this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationList = []
          this.destinationList = data
          console.log('search ivr list', this.destinationList)
        }
      )
    } else if (this.destinationType == '2') {
      this.queueservice.getQueues(this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationList = []
          this.destinationList = data
          console.log('queue focused list', this.destinationList)
        }
      )
    } else if (this.destinationType == '4') {
      this.inboundservice.getTimeConditionSetList(this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationList = data
          console.log('focuse time condition list', this.destinationList)
        }
      )
    } else if (this.destinationType == '5') {
      this.extensionservice.searchInitialExtension(this.UserProfileId).subscribe(
        (data: any) => {
          if (data) {
            this.destinationList = []
            for (let i = 0; i < data.length; i++) {
              if (data[i].didNumber !== undefined) {
                this.destinationList.push(data[i].didNumber)
              }
            }
            console.log('search extension list', this.destinationList)
          }
        }
      )
    }
    else if(this.destinationType == '10'){
      this.voicemailservice.getVoicemailTemplate(this.userData.id).subscribe(
        (data:any) => {
          this.destinationList = []
          this.destinationList = data
        }
      )
    }
  }
  // FO
  selectQueueFO(item) {
    console.log("selectQueueFO==========;", item.name)
    this.destinationFO = item.name
    if (this.destinationTypeFO == '4') {
      this.timeSetId = item.id
    }
  }
  onChangeSearchQueueFO(val: string) {
    if (this.destinationTypeFO == '1') {
      this.ivrservice.searchIVR(val, this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationListFO = data
          console.log('search ivr list', this.destinationListFO)
        }
      )
    } else if (this.destinationTypeFO == '2') {
      this.queueservice.searchQueue(val, this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationListFO = data
          console.log('search queue list', this.destinationListFO)
        }
      )
    } else if (this.destinationTypeFO == '4') {
      this.inboundservice.searchTimeConditionSet(val, this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationListFO = data
          console.log('search time condition list', this.destinationListFO)
        }
      )
    } else if (this.destinationTypeFO == '5') {
      this.extensionservice.searchExtension(val, this.UserProfileId).subscribe(
        (data: any) => {
          for (let e of data) {
            if (e != undefined) {
              this.destinationListFO.push(e.didNumber)
            }
          }
          console.log('search extension list', this.destinationListFO)
        }
      )
    }
    else if(this.destinationTypeFO == '10'){
      this.voicemailservice.getVoicemailTemplate(this.userData.id).subscribe(
        (data:any) => {
          this.destinationListFO = data
          console.log('search Vociemail list', this.destinationListFO)
        }
      )
    }
  }

  onFocusedQueueFO(e) {
    if (this.destinationTypeFO == '1') {
      this.ivrservice.GetIVRList(this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationListFO = []
          this.destinationListFO = data
          console.log('search ivr list', this.destinationListFO)
        }
      )
    } else if (this.destinationTypeFO == '2') {
      this.queueservice.getQueues(this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationListFO = []
          this.destinationListFO = data
          console.log('queue focused list', this.destinationListFO)
        }
      )
        } else if (this.destinationTypeFO == '4') {
      this.inboundservice.getTimeConditionSetList(this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationListFO = data
          console.log('focuse time condition list', this.destinationListFO)
        }
      )
    } else if (this.destinationTypeFO == '5') {
      this.extensionservice.searchInitialExtension(this.UserProfileId).subscribe(
        (data: any) => {
          if (data) {
            this.destinationListFO = []
            for (let i = 0; i < data.length; i++) {
              if (data[i].didNumber !== undefined) {
                this.destinationListFO.push(data[i].didNumber)
              }
            }
            console.log('search extension list', this.destinationListFO)
          }
        }
      )
    }
    else if(this.destinationTypeFO == '10'){
      this.voicemailservice.getVoicemailTemplate(this.userData.id).subscribe(
        (data:any) => {
          this.destinationListFO = []
          this.destinationListFO = data
        }
      )
    }
  }



  selectQueueOne(item) {
    this.destinationOne = item.name
    if (this.destType == '4') {
      this.timeSetId = item.id
    }
  }


  onChangeSearchQueueOne(val: string) {
    if (this.destType == '1') {
      this.ivrservice.searchIVR(val, this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationListOne = data
          console.log('search ivr list', this.destinationListOne)
        }
      )
    } else if (this.destType == '2') {
      this.queueservice.searchQueue(val, this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationListOne = data
          console.log('search queue listtttttt', this.destinationListOne)
        }
      )
    } else if (this.destType == '4') {
      this.inboundservice.searchTimeConditionSet(val, this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationListOne = data
          console.log('search time condition list', this.destinationListOne)
        }
      )
    } else if (this.destType == '5') {
      this.extensionservice.searchExtension(val, this.UserProfileId).subscribe(
        (data: any) => {
          for (let e of data) {
            if (e != undefined) {
              this.destinationListOne.push(e.didNumber)
            }
          }
          console.log('search extension list', this.destinationListOne)
        }
      )
    }
  }

  onFocusedQueueOne(e) {
    console.log(this.destType);
    if (this.destType == '1') {
      this.ivrservice.GetIVRList(this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationListOne = []
          this.destinationListOne = data
          console.log('search ivr list', this.destinationListOne)
        }
      )
    } else if (this.destType == '2') {
      this.queueservice.getQueues(this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationListOne = []
          this.destinationListOne = data
          console.log('queue focused listttttt', this.destinationListOne)
        }
      )
    } else if (this.destType == '4') {
      this.inboundservice.getTimeConditionSetList(this.UserProfileId).subscribe(
        (data: any) => {
          this.destinationListOne = data
          console.log('focuse time condition list', this.destinationListOne)
        }
      )
    } else if (this.destType == '5') {
      this.extensionservice.searchInitialExtension(this.UserProfileId).subscribe(
        (data: any) => {
          if (data) {
            this.destinationListOne = []
            for (let i = 0; i < data.length; i++) {
              if (data[i].didNumber !== undefined) {
                this.destinationListOne.push(data[i].didNumber)
              }
            }
            console.log('search extension list', this.destinationListOne)
          }
        }
      )
    }
  }


  patternMatch(e) {
    this.userData = this.loginservice.getUserdata(false);
    console.log(this.userData.id);
    this.inboundservice.getMatchPattern(this.userData.id).subscribe(
      (res: any) => {
        console.log('return', res)

      });
  }

  onClearDestination() {
    this.destination = ''
  }

  // Voice Announcement File Upload Function

  onVoicePath(data) {
    console.log('data', data)
    this.destination = data
  }
  onVoicePathFO(data) {
    console.log('data', data)
    this.destinationFO = data
  }

  onVoicePathOne(data) {

    this.destinationOne = data
    console.log('data13', this.destinationOne)
  }

  onVoicePathFeedback(data) {

    this.feedbackVoice = data
    console.log('data14', this.feedbackVoice)
  }


  // Select Match Pattern Function

  onChangeMatchPattern(e, item) {
    if (this.id != 0) {
      if (e.srcElement.checked) {
        this.matchPatterCheckList.push(item);
      } else {
        if (this.matchPatterCheckList.indexOf(item) != -1) {
          this.matchPatterCheckList.splice(this.matchPatterCheckList.indexOf(item), 1);
        } else {
          console.log('sss', item, this.getRouteMatchpattern)
          for (let getMp of this.getRouteMatchpattern) {
            if (getMp['matchpatterns'] == item)
              this.inboundservice.deleteRouteMatchPattern(getMp['id']).subscribe(
                (res: any) => {
                  console.log('data', res)
                }
              );
          }
        }
      }
    } else {
      if (e.srcElement.checked) {
        this.matchPatterCheckList.push(item);
      }
      else {
        this.matchPatterCheckList.splice(this.matchPatterCheckList.indexOf(item), 1);
      }
    }
  }
  

  onInbountBtn() {
    if (this.enableFeedback && !this.feedbackVoice) {
      Swal.fire('Oops..!', 'feedback voice is not selected !', 'warning');
      return;
    }
    if (this.isAdmin == 'true' && this.UserProfileId == 0) {
      Swal.fire('Oops..!', 'Valid customer Select', 'warning');
    } else if (this.DIDNO == 0 ||this.destinationType == '0' ||this.description==""||(this.destinationType != '0' && this.destination == '') ) {
      Swal.fire('Oops..!', 'Please Fill All The Mandatory Fields', 'warning');
    } else if (this.enableCallerRestriction == true && this.selectedCallerTemplate == null) {
      Swal.fire('Oops..!', 'Please select caller template', 'warning');
    }

    else {
      if (this.destinationOne == undefined || this.destinationOne == "") {
        this.destinationOne = "";
      }
      else if (this.destinationOne == "Voice File") {
        this.destinationOne = this.destinationOne
      }

      if (this.destType == undefined || this.destType == "0") {
        this.destType = "";
      }

      if (this.callerIdType == "0" || this.callerIdType == undefined) {
        this.callerIdType = "1";
      }

      if (this.destinationType == '5') {
        if(this.dtmf_mode == '2'){
          this.enableAgentRestiction = true
        }
      }


      if (this.callerIdType == "3") {

        this.inboundRouteData = {
          'route_type': 'inbound',
          'description': this.description,
          'didNumber': this.DIDNO,
          'channelId': this.channelId,
          'musiconhold': this.musicOnHold,
          'destinationType': this.destinationType,
          'Destination': this.destination,
          'destinationTypeFO': this.destinationTypeFO,
          'destinationFO': this.destinationFO,
          'user': this.UserProfileId,
          'outOfTimedestinationType': this.destType,
          'outOfTimeDestinationName': this.destinationOne,
          'patternMatchList': this.patterMatchList,
          'callerid': this.callerDIDNumber,
          'callerType': this.callerIdType,
          'sendResponseToexternalAPI': String(this.enableExternalResponse),
          'caller_restriction': this.enableCallerRestriction,
          'caller_template': this.selectedCallerTemplate,
          'agent_restriction': this.enableAgentRestiction,
          'feedback_enabled' : this.enableFeedback,
        }
        if (this.enableFeedback) {
          this.inboundRouteData.feedback_voice = this.feedbackVoice;

        } else {
          this.inboundRouteData.feedback_voice = null
        }
        if (this.apiTemplate != "null") {
          this.inboundRouteData.api_template = this.apiTemplate;
        } else {
          this.inboundRouteData.api_template = null;
        }

        if (this.voicebotTemplate != "null") {
          this.inboundRouteData.voicebot_template = this.voicebotTemplate;
        } else {
          this.inboundRouteData.voicebot_template = null;
        }


        if (this.destinationType == '4') {
          this.inboundRouteData.timeConditionSet = this.timeSetId
        }


        if (this.destinationType == '5') {
          this.inboundRouteData.dtmf_mode = this.dtmf_mode
        }

        this.inboundservice.addInboundRoute(this.inboundRouteData).subscribe(
          (res: any) => {
            if (res && res['id']) {
              this.isEdit = false;
              if (this.matchPatterCheckList.length > 0) {
                for (let mp of this.matchPatterCheckList) {
                  this.routeMatchPatterData = {
                    'route': res['id'],
                    'matchpatterns': mp
                  }
                  this.inboundservice.addRouteMatchPattern(this.routeMatchPatterData).subscribe(
                    (response: any) => {
                      console.log('routeTimeCondition ', response)
                    }
                  )
                }
              }
              Swal.fire('Created', 'Inbound Route Successfully Created', 'success')
              this.route.navigateByUrl('/route/inboundroute')
            }
            else if(res.error){
              Swal.fire('Error', res.error, 'error');
            }
          }
        )
        //  });
      } 
      else {

        if (this.destinationType == '5') {
          if(this.dtmf_mode == '2'){
            this.enableAgentRestiction = true
          }
        }


        this.inboundRouteData = {
          'route_type': 'inbound',
          'description': this.description,
          'didNumber': this.DIDNO,
          'channelId': this.channelId,
          'musiconhold': this.musicOnHold,
          'destinationType': this.destinationType,
          'Destination': this.destination,

          'destinationTypeFO': this.destinationTypeFO,
          'destinationFO': this.destinationFO,

          'user': this.UserProfileId,
          'outOfTimedestinationType': this.destType,
          'outOfTimeDestinationName': this.destinationOne,
          'patternMatchList': this.patterMatchList,
          'callerid': this.callerDIDNumber,
          'callerType': this.callerIdType,
          'sendResponseToexternalAPI': String(this.enableExternalResponse),
          'caller_restriction': this.enableCallerRestriction,
          'caller_template': this.selectedCallerTemplate,
          'agent_restriction': this.enableAgentRestiction,
          'feedback_enabled' : this.enableFeedback,
          
        }
        if (this.enableFeedback) {
          this.inboundRouteData.feedback_voice =this.feedbackVoice;
        } else {
          this.inboundRouteData.feedback_voice = null
        }
        if (this.apiTemplate != "null") {
          this.inboundRouteData.api_template = this.apiTemplate;
        } else {
          this.inboundRouteData.api_template = null;
        }

        if (this.voicebotTemplate != "null") {
          this.inboundRouteData.voicebot_template = this.voicebotTemplate;
        } else {
          this.inboundRouteData.voicebot_template = null;
        }

        if (this.destinationType == '4') {
          this.inboundRouteData.timeConditionSet = this.timeSetId
        }
        
        if(this.destinationType == '1' || this.destinationType == '2' || this.destinationType == '4'){
          this.inboundRouteData.allow_call_transfer = this.allow_call_transfer
          if (this.allow_call_transfer == true){
            this.inboundRouteData.call_transfer_template = this.call_transfer_template.id
          }
        }

        if (this.destinationType == '5') {
          this.inboundRouteData.dtmf_mode = this.dtmf_mode
        }

        this.inboundservice.addInboundRoute(this.inboundRouteData).subscribe(
          (res: any) => {
            if (res && res['id']) {
              this.isEdit = false;
              if (this.matchPatterCheckList.length > 0) {
                for (let mp of this.matchPatterCheckList) {
                  this.routeMatchPatterData = {
                    'route': res['id'],
                    'matchpatterns': mp
                  }
                  this.inboundservice.addRouteMatchPattern(this.routeMatchPatterData).subscribe(
                    (response: any) => {
                      console.log('routeTimeCondition ', response)
                    }
                  )
                }
              }
              Swal.fire('Created', 'Inbound Route Successfully Created', 'success')
              this.route.navigateByUrl('/route/inboundroute')
            }
            else if(res.error){
              Swal.fire('Error', res.error, 'error');
            }
          }
        )
      }
    }

  }
  onUpdateInbountBtn() {
    console.log(this.feedbackVoice)
    if (this.enableFeedback && !this.feedbackVoice) {
      Swal.fire('Oops..!', 'feedback voice is not selected !', 'warning');
      return;
    }
    if (this.isAdmin == 'true' && this.UserProfileId == 0) {
      Swal.fire('Oops..!', 'Valid customer Select', 'warning');
    } 
    else if(this.description==""){
      Swal.fire('Oops..!', 'Enter Description', 'warning')

    }
    else if (this.DIDNO == 0) {
      Swal.fire('Oops..!', 'Select Valid DID Number', 'warning')
    } else if (this.destinationType == '0') {
      Swal.fire('Oops..!', 'Select Valid Destination Type', 'warning')
    } else if (this.destinationType != '0' && this.destination == '') {
      Swal.fire('Oops..!', 'Select Valid Destination', 'warning')
    } else if (this.callerIdType == '0' && this.destinationType == '2') {
      Swal.fire('Oops..!', 'Select Valid callerId Type', 'warning')
    } else if (this.enableCallerRestriction == true && this.selectedCallerTemplate == null) {
      Swal.fire('Oops..!', 'Please select caller template', 'warning')
    }
    else {
      if (this.DIDNO != this.DIDInitial) {
        this.DIDNO = this.DIDInitial["id"]
      }
      if (this.destinationType != "5") {
        this.enableAgentRestiction = false;
      }
      
      if (this.destinationType == '5') {
        if(this.dtmf_mode == '2'){
          this.enableAgentRestiction = true
        }
      }
      
      this.inboundRouteData = {
        'route_type': 'inbound',
        'description': this.description,
        'didNumber': this.DIDNO,
        'channelId': this.channelId,
        'musiconhold': this.musicOnHold,
        'destinationType': this.destinationType,
        'Destination': this.destination,
        'destinationTypeFO': this.destinationTypeFO,
        'destinationFO': this.destinationFO,
        'user': this.UserProfileId,
        'outOfTimedestinationType': this.destType,
        'outOfTimeDestinationName': this.destinationOne,
        'callerid':  this.callerDIDNumber,
        'callerType': this.callerIdType,
        'sendResponseToexternalAPI': String(this.enableExternalResponse),
        'caller_restriction': this.enableCallerRestriction,
        'caller_template': this.selectedCallerTemplate,
        'agent_restriction': this.enableAgentRestiction,
        'feedback_enabled' : this.enableFeedback,
      }
      if (this.enableFeedback) {
        this.inboundRouteData.feedback_voice =this.feedbackVoice;
      } else {
        this.inboundRouteData.feedback_voice = null
      }
      if (this.apiTemplate != "null") {
        this.inboundRouteData.api_template = this.apiTemplate;
      } else {
        this.inboundRouteData.api_template = null;
      }

      if (this.voicebotTemplate != "null") {
        this.inboundRouteData.voicebot_template = this.voicebotTemplate;
      } else {
        this.inboundRouteData.voicebot_template = null;
      }
      
      if (this.destinationType == '4') {
        this.inboundRouteData.timeConditionSet = this.timeSetId
      }

      if(this.destinationType == '1' || this.destinationType == '2' || this.destinationType == '4'){
        this.inboundRouteData.allow_call_transfer = this.allow_call_transfer
        if (this.allow_call_transfer == true){
          this.inboundRouteData.call_transfer_template = this.call_transfer_template.id
        }
        else{
          this.inboundRouteData.call_transfer_template = null
        }
      }

      if (this.destinationType == '5') {
        this.inboundRouteData.dtmf_mode = this.dtmf_mode
      }
      else{
        this.inboundRouteData.dtmf_mode = null;
      }

      this.inboundservice.updateInboundRoute(this.id, this.inboundRouteData).subscribe(
        (res: any) => {
          if (res && res['id']) {
            if (this.matchPatterCheckList.length > 0) {
              for (let mp of this.matchPatterCheckList) {
                this.routeMatchPatterData = {
                  'route': res['id'],
                  'matchpatterns': mp
                }
                this.inboundservice.addRouteMatchPattern(this.routeMatchPatterData).subscribe(
                  (response: any) => {
                    console.log('routeTimeCondition ', response)
                  }
                )
              }
            }
            Swal.fire('Updated', 'Inbound Route Successfully Updated', 'success')
            this.route.navigateByUrl('/route/inboundroute')
          }
        }
      )
    }
  }


  oncallerIdType() {
    if (this.callerIdType == '1') {
      this.callType = false;
      this.btnActive = true;
    } else if (this.callerIdType == '2') {
      this.callType = false;

    } else if (this.callerIdType == '3') {
      this.callType = true;
      this.btnActive = true;

    } else {
      this.callType = false;
    }
  }

  selectCallTransfer(item){
    this.call_transfer_template = item.name
  }

  onFocusedCallTransfer(e){
    this.calltransferservice.getCallTransferTemplate(this.UserProfileId).subscribe(
      (data:any) => {
        this.callTransferList = data
      }
    )
  }

  onAddBlackList(){
    this.blackListSumbmitted = true;
    if(this.blackListForm.valid){
      const name= this.blackListForm.get("blackListName").value
      const number= this.blackListForm.get("blackListNumber").value
      this.inboundservice.addBlackList(this.id,this.UserProfileId,name,number).
      subscribe((res: any)=>{
        if(res.status == "1"){
          const data = res.data
          this.blackList.push({ 
          "id": data.id,
          "name": data.name,
          "number": data.number,
          "created_on": data.created_on,
          "route": data.route,
          "user": data.user})       
          Swal.fire('Success', data.number + " Added to black list.", 'success')  
          this.blackListForm.reset();
          this.clearValidationMessages(this.blackListForm);
        }
        else if (res.status == "2"){
          const data = res.data
          Swal.fire('Duplication', data.number + " is already exists.", 'warning')  
        }
        
      })
    }    
    
  }

  onDeleteBlackList(id:number){
    this.inboundservice.removeBlackList(id).
    subscribe((res:any)=>{
      if(res.status == "1"){
        this.blackList = this.blackList.filter(item => item.id != id)
        Swal.fire('Success'," Deleted Sucessfully.", 'success')  
      }
      
    })
  }

  clearValidationMessages(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      control.setErrors(null);
    });
  }

  onEnableCallerRestriction(event: any) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.enableCallerRestriction = true;
      this.selectedCallerTemplate = "";

    } else {
      this.enableCallerRestriction = false;
      this.selectedCallerTemplate = null;
    }
  }

  getCallerTemplates() {
    this.inboundservice.listCallerTemplate(this.userData.id).subscribe((res:any)=>{
      if (res.status === "1") {
        this.callerTemplateList = res.data
      }
    })
  }

  onDtmfModeChange() {
    if (this.dtmf_mode === '2') {
      this.enableAgentRestiction = true;
    } else {
      this.enableAgentRestiction = false;
    }
  }

  getAPITemplates() {
    const qParams = [
      { key: "scope", value: "api_template" },
      { key: "user_id", value: this.userData.id }
    ]

    this.inboundservice.getAPITemplates(qParams).subscribe((res: any) => {
      if (res.status === "1") {
        this.apiTemplateList = res.data;
      }
    })
  }

  getVoicebotTemplates() {
    const qParams = [
      { key: "scope", value: "voicebot_template" },
      { key: "user_id", value: this.userData.id }
    ]
    this.vbService.getTemplateList(qParams).subscribe((res: any) => {
      if (res.status === "1") {
        this.voicebotTemplateList = res.data;
      }
    })
  }

  hasPermission(perm: string) {
    return this.loginservice.hasPermission(perm);
  }

}
