import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    private logs: string[] = [];
    logTosessionStorage(message) {
        const maxLogs = 100; // Set a limit on logs stored
        const logs = JSON.parse(sessionStorage.getItem('debugLogs')) || [];
        const timestamp = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
        logs.push({ timestamp, message });
        if (logs.length > maxLogs) {
            logs.shift(); // Remove the oldest log
        }
        sessionStorage.setItem('debugLogs', JSON.stringify(logs));
    }


    
}

