import { Component, OnInit, EventEmitter, OnDestroy } from "@angular/core";
import { LoginService } from "./login.service";
import Swal from "sweetalert2";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Subscription, timer } from "rxjs";
import { map, share, switchMap } from "rxjs/operators";
import { ImpersonateComponent } from "./impersonate/impersonate.component";
import { Injectable } from "@angular/core";
import { SharedService } from "src/global";
import { environment } from "src/environments/environment";
import { ResetPasswordService } from "./shared.service";
import { HttpClient } from "@angular/common/http";
import * as CryptoJS from "crypto-js";



import { AngularFireAuth } from "@angular/fire/auth";

import { FormBuilder, FormGroup } from "@angular/forms";

import "firebase/messaging";

import * as firebase from "firebase/app";
import "firebase/auth";

import { FirebaseService } from "./firebase.service";
import { AddPushNotificationService } from "./add-pushnotification/add-pushnotification.service";



import { Location } from '@angular/common';
import { UsermanagementService } from "./usermanagement.service";
import { TwoFactorAuthComponentService } from "./two-factor-auth/two-factor-auth.service";
import { InactivityService } from "./inactivity.service";
import { SidebarComponent } from "./sidebar/sidebar.component";

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "[app-root]",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "bonvoice";
  public login: boolean = false; // default value should be false ;setting true for dev
  private username: string;
  private password: string;
  keyword: string = "name";
  customerList: any = new Array();
  userData: any;
  CustomerName: string = "";
  isAdmin: string = "";
  href: string;
  myDate = new Date();
  userName: any;
  phoneNumber: any;
  user_id: any;
  agentBreakStatus: boolean = false;
  agentStatus: boolean = true;
  time = new Date();
  rxTime = new Date();
  intervalId;
  userType: string = "";
  showsetting: boolean = false;
  impersonate: boolean = false;
  showadmin: boolean = false;
  logout_data: any;
  forgotpassword: boolean = false;
  sendemail: boolean = false;
  imerperSonateUrl: any;
  resetUrl: any;
  showResetPassword: boolean = false;
  sidebar_toggle: boolean
  sidebar_state: boolean
  Url: any = window.location.href.split("/");

  fcm_token: string = "";
  messages: any[] = [];
  device_type: any;

  showPopup = false;
  noNotifications: boolean = false; // Add this variable


  isnotificationon: boolean = false;
  pushNotificationData: any[] = []; // Initialize as an empty array

  pushNotifications: any[] = [];

  notifications: any[] = [];

  showNotifications = false;
  unreadMessageCount = 0; // Assuming you have logic to calculate this

  userProfileForm: FormGroup;

  userid: any;
  deviceType: any;
  unreadCount: number = 0;
  showClearButton: boolean = true; // Flag to control the visibility of the Clear All button
  showMessages: boolean = false;
  activeIndex: number | null = null;
  secondary_mobile_number: any
  twofactorEnabled: boolean = false;
  twoFA_data: any;
  purpose: number;
  permissions: any;
  twoFactor_permission: boolean = false;
  grp: any;
  log_data: any
  showSidebar: boolean = false;
  isLoading: boolean = false;
  usergroup: any;
  isSuperAdmin: boolean = false
  showBellIcon: boolean = false;

  private messageSubscription: Subscription;
  private static isServiceWorkerMessageHandlerAttached = false;
  showModal = false;
  role: string;
  showLogoutAlert: boolean;
  constructor(
    private loginservice: LoginService,
    private activeroute: ActivatedRoute,
    private router: Router,
    public sharedService: SharedService,
    private resetPasswordService: ResetPasswordService,
    private _location: Location,
    private afAuth: AngularFireAuth,
    private http: HttpClient,
    private firebaseService: FirebaseService,
    private fb: FormBuilder,
    private addpushnotificationservice: AddPushNotificationService,

    private usermanagement: UsermanagementService,
    private twoFactorAuthService: TwoFactorAuthComponentService,
    private inactivityService: InactivityService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const sidebarVisibility = localStorage.getItem("sidebar_visibility");
        const userType = localStorage.getItem("userType")
        this.showSidebar = userType === "virtualUser" || sidebarVisibility === "true";
      }
    });
  }

  ngOnInit() {
    this.inactivityService.startMonitoring();
    // this.fetchFireBaseMessages();

    this.userData = this.loginservice.getUserdata(false);
    // if (this.userData.push_notification_data) {
    //   this.showBellIcon = true;
    // }


    // this.fetchNotifications(3)

    // this.fetchPushMessages()

    this.imerperSonateUrl = this.Url.slice(-1);

    if (this.Url == "impersonate") {
      this.impersonate = true;
    }

    this.resetUrl = this.Url.slice(3)[0];

    if (this.resetUrl == "reset-password") {
      const timestamp = this.Url.slice(-1)[0];
      const timestampMilliseconds = timestamp * 1000; // Convert to milliseconds
      const date = new Date(timestampMilliseconds);
      const currentTimestampDate = new Date();
      if (currentTimestampDate > date) {
        Swal.fire({
          title: "Link Expired",
          text: "Your reset password link is expired !",
        });
        this.showResetPassword = false;
        this.router.navigate(["/"]);
      } else {
        this.resetPasswordService.getShowResetPassword().subscribe((value) => {
          this.showResetPassword = value;
        });
      }
    }

    this.fetchFireBaseMessages();

    this.fetchNotifications(3)

    this.fetchPushMessages()


    // Using Basic Interval
    this.intervalId = setInterval(() => {
      this.time = new Date();
    }, 1000);

    this.loginservice.loginevent.subscribe((loginstatus: boolean) => {
      this.login = loginstatus;
    });

    this.loginservice.getLoginStatus();

    if (this.login) {
      this.userData = this.loginservice.getUserdata(true);
      if (this.userData.push_notification_data) {
        this.showBellIcon = true;
      }
      if (this.userData.usertype === 'virtualUser') {
        this.router.navigate(['/follow-up-report']);
      } else {
        this.handle2FALoginSuccess()       
        // this.router.navigate(["/home2"]);
      }
      // this.router.navigate(["/home2"]);
      this.href = this.router.url;
      this.userName = this.userData.name;
      this.secondary_mobile_number = localStorage.getItem("secondary_number")
      this.usermanagement.get_sidebar_toggle_setting(this.userData.id, this.userData.usertype).subscribe((res: any) => { this.sidebar_toggle = res.sidebar_value })
    }
    // 2FA Toggle visibility control in settings(profile)
    this.userData = this.loginservice.getUserdata(false);
    this.permissions = this.userData.permissions
    for (const perm of this.permissions) {
      if (perm.codename == "cadmin_v_2FA_visibility_control") {
        this.twoFactor_permission = true;
      }
    }
    this.initializeDashboardTwofa();


    this.intializeAgentBreakStatus();

  }

  ngAfterViewInit(): void {
    console.log("ngAfterViewInit!!!!!!!!!!!!!!!")
    this.fetchLogoutMessages();
    this.fetchFireBaseMessages();
    if (this.userData.push_notification_data) {
      this.showBellIcon = true;
    }

  }


  ngOnDestroy() {
    clearInterval(this.intervalId);
    this.unloadFirebaseNotifications();
    window.removeEventListener('focus', () => {
      this.fetchPushMessages();
    });
    this.inactivityService.stopMonitoring();

  }

  flushFcmToken() {
    const fcmToken = localStorage.getItem('fcm_token');
    const deviceType = '3';
    const userid = this.userData.id;
    const group = this.userData.group;
    const email = this.userData.email;
    const number = this.userData.phone;
    const name = this.userData.name;


    if (fcmToken) {
      const payload = {
        userid: userid,
        device_type: deviceType,
        group: group,
        email: email,
        phone: number,
        name: name,
        firebase_token: fcmToken
      };
      this.loginservice.flushtoken(payload).subscribe(
        () => {
          console.log('FCM token flushed successfully');
          localStorage.removeItem('fcm_token');
        },
        (error) => {
          console.error('Error flushing FCM token', error);
        }
      );
    }
  }



  OnLogOut() {
    const fcmToken = localStorage.getItem("fcm_token");
    const group = Array.isArray(this.userData.group)
      ? this.userData.group[0]
      : this.userData.group;

    const logoutData = {
      userid: this.userData.id,
      device_type: 3,
      group: group,
      email: this.userData.email,
      phone: this.userData.phone,
      name: this.userData.name,
      firebase_token: fcmToken
    };
    const refreshToken = {
      refresh: this.loginservice.getRefreshToken(),
    }
    this.loginservice
      .removeFirebaseToken(logoutData)
      .pipe(
        switchMap((response: any) => {
          if (response.status === "success") {
            console.log("Firebase token removed successfully", response.message);
          } else {
            console.error("Error removing Firebase token:", response.message);
          }

          return this.loginservice.blackListJWTToken(refreshToken);
        })
      )
      .subscribe(
        (blacklistResponse: any) => {
          console.log("Blacklisted JWT token successfully:");
          this.completeLogout();
        },
        (error) => {
          console.error("Error during logout process:", error);
          this.completeLogout();
        }
      );

  }


  completeLogout() {

    localStorage.removeItem("fcm_token");

    window.localStorage.clear();
    window.location.reload();

    this.logout_data = {
      username: this.userData.name,
      user_type: this.userType,
    };
    this.loginservice.doLogout(this.logout_data).subscribe((datas: any) => { this.router.navigate(['/']); });

    this.loginservice.clearToken()




  }





  onForgotPassword() {
    var data = { username: this.username };
    this.sendemail = true;
    this.forgotpassword = true;
    this.router.navigate(["/sendemail"]);
    // window.location.reload();
  }
  onBackToLogin() {
    this.sendemail = true;
    this.forgotpassword = false;
    this.showResetPassword = false;
  }

  isValidFcmToken(token: string): boolean {
    const tokenPattern = /^[a-zA-Z0-9\-_:.]+$/;

    return token.length >= 140 && token.length <= 200 && tokenPattern.test(token);
  }

  getFcmToken(): Promise<string | null> {
    localStorage.removeItem('fcm_token'); // Remove existing token before requesting new one


    return this.firebaseService.requestPermission().then((fcm_token) => {
      console.log('FCM Token:', fcm_token);  // Log the token

      if (!this.isValidFcmToken(fcm_token)) {
        console.warn('Invalid FCM Token');
        return null;
      }

      // Store the token in localStorage or perform any other necessary action
      localStorage.setItem('fcm_token', fcm_token);
      return fcm_token;
    }).catch((error) => {
      console.log('Error getting Firebase token', error);
      return null; // Return null if there's an error getting the token
    });
  }



  onLogin() {
    this.isLoading = true;

    this.getFcmToken().then((fcm_token) => {
      var data: {
        username: string;
        password: string;
        fcm_token?: string;
        device_type: number;
      } = {
        username: this.username,
        password: this.password,
        device_type: 3,
      };

      // Only set fcm_token if it is available
      if (fcm_token) {
        data.fcm_token = fcm_token;
      }

      this.loginservice.doLogin(data).subscribe((datas: any) => {

        if (datas["status"] === "1") {
          this.isLoading = false; 
          this.loginservice.setUserdata(datas["data"]);
          this.loginservice.setLoginStatus(1);
          // this.loginservice.get_auth_token(data);
          this.loginservice.get_access_token(data);
          this.login = true;
          this.userData = this.loginservice.getUserdata(true);
          localStorage.setItem("userType", datas.data.usertype ? datas.data.usertype : "admin");
          localStorage.setItem("mobile", datas.data.phone);
          localStorage.setItem("email", datas.data.email);
          if (this.userData.push_notification_data) {
            this.showBellIcon = true;
          }
          if (datas.data.usergroup === "superadmin") {
            localStorage.setItem("isAdmin", "true");
          } else {
            localStorage.setItem("isAdmin", "false");
          }
          this.isAdmin = localStorage.getItem("isAdmin");

          if (datas.data.usertype === "admin") {
            localStorage.setItem("group", "customer");
          } else if (datas.data.usertype === "agentUser") {
            localStorage.setItem("group", "agent");
          } else if (datas.data.usertype === "dialerUser") {
            localStorage.setItem("group", "subuser");
          } else if (datas.data.usertype === "virtualUser") {
            localStorage.setItem("group", "virtualuser");
          } else {
            localStorage.setItem("group", datas.data.group);
          }

          this.notifications = datas.data.push_notification_data;
          localStorage.setItem('notifications', JSON.stringify(this.notifications));

          localStorage.setItem("secondary_number", this.userData.secondary_mobile_number);
          this.secondary_mobile_number = localStorage.getItem("secondary_number")
          this.intializeAgentBreakStatus();
          this.fetchNotifications(3);
          this.fetchPushMessages();
          this.usermanagement.get_sidebar_toggle_setting(this.userData.id, this.userData.usertype).subscribe((res: any) => { this.sidebar_toggle = res.sidebar_value })
          // Redirect based on userType
          if (datas.data.usertype === 'virtualUser') {
            this.router.navigate(['/follow-up-report']);
          } else {
            this.handle2FALoginSuccess()
            // this.router.navigate(["/home2"]);
          }
        } else {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: datas["error"],
            footer: "Please try with correct username/password",
          });
          this.isLoading = false; 
        }
      });
    }).catch((error) => {
      // Handle the error from getFcmToken if needed, but still proceed with login
      var data: {
        username: string;
        password: string;
        device_type: number;
      } = {
        username: this.username,
        password: this.password,
        device_type: 3,
      };

      this.loginservice.doLogin(data).subscribe((datas: any) => {
        if (datas["status"] === "1") {
          this.isLoading = false; 
          this.loginservice.setUserdata(datas["data"]);
          this.loginservice.setLoginStatus(1);
          // this.loginservice.get_auth_token(data);
          this.loginservice.get_access_token(data);
          this.login = true;
          this.userData = this.loginservice.getUserdata(true);
          localStorage.setItem("userType", datas.data.usertype ? datas.data.usertype : "admin");
          localStorage.setItem("mobile", datas.data.phone);
          localStorage.setItem("email", datas.data.email);
          if (this.userData.push_notification_data) {
            this.showBellIcon = true;
          }
          if (datas.data.group === "superadmin") {
            localStorage.setItem("isAdmin", "true");
          } else {
            localStorage.setItem("isAdmin", "false");
          }
          this.isAdmin = localStorage.getItem("isAdmin");

          if (datas.data.usertype === "admin") {
            localStorage.setItem("group", "customer");
          } else if (datas.data.usertype === "agentUser") {
            localStorage.setItem("group", "agent");
          } else if (datas.data.usertype === "dialerUser") {
            localStorage.setItem("group", "subuser");
          } else if (datas.data.usertype === "virtualUser") {
            localStorage.setItem("group", "virtualuser");
          } else {
            localStorage.setItem("group", datas.data.group);
          }

          localStorage.setItem("secondary_number", this.userData.secondary_mobile_number);
          this.secondary_mobile_number = localStorage.getItem("secondary_number")

          this.notifications = datas.data.push_notification_data;
          localStorage.setItem('notifications', JSON.stringify(this.notifications));

          this.fetchNotifications(3);
          this.fetchPushMessages();
          // this.handle2FALoginSuccess()

          // Redirect based on userType
          if (datas.data.usertype === 'virtualUser') {
            this.router.navigate(['/follow-up-report']);
          } else {
            this.handle2FALoginSuccess()
            // this.router.navigate(["/home2"]);
          }

          // this.router.navigate(["/home2"]);
        } else {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: datas["error"],
            footer: "Please try with correct username/password",
          });
        }
      });
    });
  }

  handle2FALoginSuccess() {
    // Check for 2FA and navigate
    const userData = JSON.parse(localStorage.getItem("userdata") || '{}');
    const authData = userData;
    const twoFaData = authData.data_2FA
    const data = authData.auth_user_id
    localStorage.setItem("auth_user_id", authData.auth_user_id);
    localStorage.setItem("data2FA", JSON.stringify(twoFaData));
    this.userData = this.loginservice.getUserdata(false);
    this.permissions = this.userData.permissions;

    // control the visibility of 2FA toggle button in settings(profile)
    for (const perm of this.permissions) {
      if (perm.codename == "cadmin_v_2FA_visibility_control") {
        this.twoFactor_permission = true;
      }
    }
     
    this.userType = localStorage.getItem("userType")
    // Check for virtualUser
    if (this.userType === "virtualUser") {
      console.log("user type check 2fa",this.userType)
      this.showSidebar = true;
      this.router.navigate(["/follow-up-report"]);
      return;
    }

    const sidebarVisibility = localStorage.getItem("sidebar_visibility");
    try{

    
    if (twoFaData.is_2FA_enabled === true && sidebarVisibility !== "true") {
      this.twofactorEnabled = true;
      this.router.navigate(["/2FA"]);
      const otpObjectId = twoFaData.response_2FA_data.data.otp_object_id;
      this.twoFactorAuthService.setOtpObjectId(otpObjectId);
      localStorage.setItem("sidebar_visibility", "false"); // Hide sidebar
    }
    else {
      this.router.navigate(["/home2"]);
      this.twofactorEnabled = false;
      this.showSidebar = true;
      localStorage.setItem("sidebar_visibility", "true"); // Show sidebar
    }
    this.initializeDashboardTwofa()
  }catch{
    localStorage.setItem("sidebar_visibility", "true"); // Show sidebar
    this.router.navigate(["/home2"]);

  }
  }

  initializeDashboardTwofa(){
    // Updating state of 2FA Toggle
    // const userData = localStorage.getItem('userdata');
    const userData = JSON.parse(localStorage.getItem("userdata") || '{}');
    this.usergroup = userData.group || [];
    if(this.usergroup.includes('superadmin')){
      this.isSuperAdmin = true
    }
    if (userData && userData.data_2FA) {
      // const parsedData = JSON.parse(userData);
      const twoFaData = userData.data_2FA;
      if (twoFaData) {
        this.twoFactorAuthService.setToggleState(twoFaData.is_2FA_enabled === true);
      }
    }
    this.twoFactorAuthService.getToggleState().subscribe(state => {
      this.twofactorEnabled = state;
    });
  }

  onEnableTwoFAtoggle() {
    const userId = +(localStorage.getItem('auth_user_id') || 0);
    const sendOtp_data = {
      "action": "send_otp",
      "user_id": userId,
      "purpose": 2
    };
    this.twoFactorAuthService.setPurpose(sendOtp_data.purpose);

    this.usermanagement.enableTwoFAtoggle(sendOtp_data).subscribe((response: any) => {
      if (response.status === 1) {

        const purpose = response.data.purpose;
        localStorage.setItem("purpose", purpose.toString());
        // this.userData.data_2FA.response_2FA_data = response;
        // localStorage.setItem('userdata', JSON.stringify(this.userData));

        // Updating response_2FA_data
        const storedUserData = JSON.parse(localStorage.getItem('userdata') || '{}');
        storedUserData.data_2FA.response_2FA_data = response;
        localStorage.setItem('userdata', JSON.stringify(storedUserData));

        this.router.navigate(['/2FA']);
      } else {
         // Revert toggle state in case of failure
      const userData = JSON.parse(localStorage.getItem('userdata') || '{}');
      this.twofactorEnabled = userData.data_2FA.is_2FA_enabled === true;

        if (response.status === 2 || response.status === 0) {
          Swal.fire("Error", response.message, "error")
        }
      }
    });
  }


  onNotificationChange(notification) {
    if (!notification || typeof notification.id === 'undefined') {
      console.error('Notification object or id is missing:', notification);
      return;
    }

    notification.is_notification_on = notification.is_notification_on;
    console.log('Notification state changed:', notification);
    this.updateNotification(notification);
  }


  updateNotification(notification) {
    if (!notification || typeof notification.id === 'undefined') {
      console.error('Notification object or id is missing:', notification);
      return;
    }

    this.addpushnotificationservice.updateNotification(notification.id, notification.is_notification_on)
      .subscribe(
        response => {
          console.log('Update successful', response);
          this.fetchNotifications(3)
        },
        error => {
          console.error('Update failed', error);
        }
      );
  }

  getNotifications() {
    this.addpushnotificationservice.notificationdisplay(this.userData.id).subscribe(
      (data: any) => {

        this.notifications = data;


      }, error => {

        console.error('Error fetching notifications', error);
      });


  }

  fetchNotifications(device_type: number) {
    const userid = this.userData.id
    const group = localStorage.getItem('group')
    const email = this.userData.email
    const phone = this.userData.phone
    const name = this.userData.name

    this.addpushnotificationservice.getpushNotification(userid, device_type, group, email, phone, name).subscribe((response: any) => {
      if (response && response.status === 'success') {
        this.notifications = response.data.map(notification => ({
          id: notification.id,
          display_name: notification.display_name,
          is_notification_on: notification.is_notification_on
        }));
      } else {
        this.notifications = [];
      }
    }, error => {
      console.log('API error:', error);
    });
  }

  refreshNotifications(): void {
    this.getNotifications();
  }

  sendFirebaseToken(token) {
    return this.http.post("api/get_auth_token/.", { fcm_token: token });
  }



  backClicked() {
    this._location.back();
  }

  forwardClicked() {
    this._location.forward();
  }
  fetchLogoutMessages() {
    console.log("!!!!fetchLogoutMessages!!")
    const logoutAlert = sessionStorage.getItem('showLogoutAlert');
    console.log("logoutAlert>>>", logoutAlert)
    if (logoutAlert === 'true') {
      this.showLogoutAlert = true;
      console.log("sessionStorage this.showLogoutAlert TRUEEEE")
      sessionStorage.removeItem('showLogoutAlert'); // Clear the flag after showing the alert
    }
  }
  fetchFireBaseMessages() {
    if (!AppComponent.isServiceWorkerMessageHandlerAttached) {
      this.messageSubscription = this.firebaseService.currentMessage.subscribe(
        (messages) => {
          console.log("==============================fire messages", messages);
          this.messages = messages || [];
          this.unreadCount = this.messages.length; // Update unread count


          console.log("==============================fire count", this.unreadCount);

        }
      );

      const serviceWorkerMessageHandler = (event: MessageEvent) => {
        console.log("==============================fire event", event);
        const payload = event.data;

        this.fetchPushMessages();
      };

      navigator.serviceWorker.addEventListener('message', serviceWorkerMessageHandler);
      AppComponent.isServiceWorkerMessageHandlerAttached = true;

    }



  }

  private messagesAreEqual(msg1: any, msg2: any): boolean {
    return JSON.stringify(msg1) === JSON.stringify(msg2);
  }

  unloadFirebaseNotifications() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  fetchPushMessages(): void {
    const userid = this.userData.id;
    const device_type = '3';
    const group = this.userData.group;
    const email = this.userData.email;
    const phone = this.userData.phone;
    const name = this.userData.name;

    this.loginservice.getPushMessages(userid, device_type, group, email, phone, name).subscribe(
      (response: any) => {
        if (response.status === 'success') {
          this.messages = response.data;
          console.log("=====messages", this.messages)
          this.UnreadCountmessages()
        } else {
          console.error('Error fetching push messages:', response.message);
        }
      },
      (error) => {
        console.error('Error fetching push messages:', error);
      }
    );
  }

  togglePopup(): void {
    console.log("triggering ")
    this.showPopup = !this.showPopup;
    if (this.showPopup && this.messages.length === 0) {
      // Fetch messages when popup is shown for the first time
      this.fetchPushMessages();
    }
  }

  UnreadCountmessages(): void {
    console.log("inside count")
    this.unreadCount = this.messages.filter(message => !message.read_status).length;
    console.log("====count", this.unreadCount)
  }

  toggleMessage(message: any): void {
    message.isOpen = !message.isOpen;
  }

  markAsRead(message: any): void {
    console.log("inside markAsRead");
    this.loginservice.updateMessage(message.id, true).subscribe(
      (response: any) => {
        if (response.status === 'success') {
          const index = this.messages.indexOf(message);
          if (index > -1) {
            this.messages.splice(index, 1);
          }
          // message.read_status = true;
          this.UnreadCountmessages();
          // Check if related_data contains a customer_id
          if (message.related_data && message.related_data.customer_id && message.related_data.follow_up_id) {
            const customerId = message.related_data.customer_id;
            const followUpId = message.related_data.follow_up_id;
            // Redirect to the new URL with customer_id and followup_id
            this.router.navigate(['/addressbook', customerId, followUpId]);
          } else if (message.related_data && message.related_data.missed_call_report) {
            const missed_call_report = message.related_data.missed_call_report;
            // const missed_call_report = 1;
            this.router.navigate(['/missedCallReport/', missed_call_report]); // Redirect to missed call page
          } else {
            // Redirect to the old URL
            this.router.navigate(['/home2']);
          }
          this.showPopup = false; // Close the modal popup
        } else {
          console.log('Error updating message read status:', response.message);
        }
      },
      (error) => {
        console.error('Error updating message read status:', error);
      }
    );
  }


  clearAllMessages(): void {
    const ids = this.messages.map(message => message.id);
    this.loginservice.updateAllMessages(ids).subscribe(
      (response: any) => {
        if (response.status === 'success') {
          this.messages = [];

          this.UnreadCountmessages();
          this.router.navigate(['/home2']);
          this.showPopup = false;
        } else {
          console.log('Error updating all messages read status:', response.message);
        }
      },
      (error) => {
        console.error('Error updating all messages read status:', error);
      }
    );
  } callrecords_count

  toggleMessageReadStatus(message: any): void {
    this.loginservice.updateMessage(message.id, true).subscribe(
      (response: any) => {
        if (response.read_status === true) {
          message.read_status = true;
          this.UnreadCountmessages();
          this.closePopup();
        } else {
          console.error('Error updating message read status:', response.message);
        }
      },
      (error) => {
        console.error('Error updating message read status:', error);
      }
    );
  }

  closePopup(): void {
    this.showPopup = false;
  }



  onSidebarToggleChange() {
    this.userData = this.loginservice.getUserdata(true);
    this.usermanagement.set_sidebar_toggle_setting(this.userData.id, this.userData.usertype, this.sidebar_toggle).subscribe((res: any) => { this.sidebar_state = res.sidebar_value })
  }


  addMobileNumber(isDelete: boolean = false) {
    if (isDelete) {
      this.secondary_mobile_number = null;
    }

    if (!isDelete) {
      const isNumberOnly = /^\d+$/.test(this.secondary_mobile_number);

      if (!isNumberOnly || this.secondary_mobile_number.length < 10) {
        Swal.fire('Error', 'Mobile number must be at least 10 digits long and contain only numbers.')
        return;
      }
    }

    this.userData = this.loginservice.getUserdata(true);
    this.usermanagement.add_secondary_mobile(this.userData.id, this.secondary_mobile_number).subscribe(
      (res: any) => {
        this.secondary_mobile_number = res.number
        if (res.number) {
          localStorage.setItem("secondary_number", res.number);
          Swal.fire('Success', 'Secondary number added', 'success')
        }
        else {
          localStorage.removeItem("secondary_number");
          Swal.fire('Success', 'Secondary number deleted', 'success')
        }
      })
  }
  LogoutFromAllDevices() {
    Swal.fire({
      title: "Are you sure ?",
      text: "This will log you out from all devices.",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((resp) => {
      if (!resp.dismiss) {
        this.userData = this.loginservice.getUserdata(true);
        this.usermanagement
          .LogoutAllDevices(this.userData.id, this.userData.usertype)
          .subscribe((res: any) => {
            if (res.detail) {
              Swal.fire("Success", res.detail, "success");
            }
            this.OnLogOut()
          });
      }
    })
  }

  intializeAgentBreakStatus() {

    if (["agentUser", "virtualUser"].includes(this.userData.usertype)) {
      this.agentBreakStatus = this.userData.break_status;
      this.agentStatus = this.userData.active;
    }
  }

  onAgentBreak() {
    const data = {
      agent_id: this.userData.id,
      break_status: !this.agentBreakStatus,
      user_type: this.userData.usertype
    }

    this.usermanagement.updateAgentBreakTime(data).subscribe((res: any) => {
      if (res.status === "1") {
        if (this.agentBreakStatus === true) {
          Swal.fire("Success", "Break mode activated.", "success")
        } else {
          Swal.fire("Success", "Break mode deactivated.", "success")
        }

        this.userData.break_status = this.agentBreakStatus;
        localStorage.setItem('userdata', JSON.stringify(this.userData));

      } else {
        if (this.agentBreakStatus === true) {
          Swal.fire("Something went wrong!", "Failed to activate break mode", "error")
        } else {
          Swal.fire("Something went wrong!", "Failed to deactivate break mode", "error")
        }
      }
    })

  }

  openModal() {
    this.showModal = true;
  }
  closeModal() {
    this.showModal = false;
  }

}
